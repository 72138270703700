<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
            <div class="text-lg mb-4" v-if="this.mailDateToDisplay !== null">Podgląd maila z dnia <span class="font-semibold">{{this.mailDateToDisplay}}</span></div>
            <div v-if="this.mailData !== null" class="overflow-auto h-96 rounded-md shadow-md border-gray-100 border">
                <div v-html="this.mailData"></div>
            </div>
            <div class="mt-6 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Zamknij</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import moment from 'moment'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  props: ["modalData", "mailDate"],
  data() {
    return {
        mailData: null,
        mailDateToDisplay: null,
    }
  },
  methods: {
    watchForData(){
        if(this.modalData !== undefined && this.modalData !== null){
            this.mailData = this.modalData
            // this.invoicesData.invoices.reverse()
        }else{
            setTimeout(() => {
            this.watchForData()
        }, 100);
        }
    },
    watchForMailDate(){
        if(this.mailDate !== undefined && this.mailDate !== null){
            
            this.mailDateToDisplay = moment.unix(this.mailDate).format("LLL")
            // this.invoicesData.invoices.reverse()
        }else{
            setTimeout(() => {
            this.watchForMailDate()
        }, 100);
        }
    },
    log(log){
        console.log(log)
    },
    emitClose()
    {
        this.$emit("close");
    },
    close()
    {
        this.emitClose();
        this.open = false
    },
  },
  computed: {
  },
  created(){
    this.watchForData()
    this.watchForMailDate()
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  }
}
</script>

<style scoped>


</style>
