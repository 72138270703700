<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-50" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-black opacity-40" />

        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div :class="['transition-all duration-200 pointer-events-auto w-screen', !this.displayDiscounts ? 'max-w-md' : 'max-w-6xl']">
              <form class="flex h-full flex-col divide-y divide-slate-700 bg-slate-600 shadow-xl">
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-gradient-to-r from-slate-900 to-slate-800 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white"> Dodaj użytkownika</DialogTitle>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">Stwórz nowe konto systemowe.</p>
                    </div>
                  </div>
                  <div class="flex w-full shadow-sm border border-slate-800  divide-x divide-slate-800 bg-slate-500">
                      <div @click.prevent="changeTypeOfUser('user')" :class="{'w-1/2 flex justify-center transition ease-in-out duration-200 text-slate-100 select-none text-sm font-medium py-2': true, 'bg-slate-500 hover:bg-slate-600 cursor-pointer': this.newUser.worker, 'bg-slate-700': !this.newUser.worker }">Nowy klient</div>
                      <div @click.prevent="changeTypeOfUser('worker')" :class="{'w-1/2 flex justify-center transition ease-in-out duration-200 text-slate-100 select-none text-sm font-medium py-2': true, 'bg-slate-500 hover:bg-slate-600 cursor-pointer': !this.newUser.worker, 'bg-slate-700': this.newUser.worker }">Nowy pracownik</div>
                  </div>
                  <div class="flex flex-1 flex-col divide-y divide-slate-800 justify-between">
                    <div class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 text-slate-200 font-medium">Dane logowania </div>
                        <div class="select-none flex flex-col">
                            <label for="email" class="block text-sm font-medium text-slate-100">Email*</label>
                            <div class="mt-1">
                                <input type="email" maxlength="35"  name="email" v-model="newUser.contact.email" id="email" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. ty@gmail.com" />
                            </div>
                            <span class="text-xs select-none text-slate-300 mt-1">Brak możliwości późniejszej zmiany.</span>
                            <span class="text-xs select-none text-red-500" v-if="errors.email"> Sprawdź poprawność pola.</span>
                        </div>
                        <div class="mt-4 select-none">
                            <label for="pass" class="block text-sm font-medium text-slate-100">Hasło*</label>
                            <div class="mt-1">
                                <input type="password" v-model="password" maxlength="20" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="Hasło" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.password"> Pole musi zawierać minimum 6 znaków.</span>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Dane podstawowe użytkownika</div>
                        <div class="select-none">
                            <label for="email" class="block text-sm font-medium text-slate-100">Imię*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="20" v-model="newUser.name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. Jan" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.name"> Pole nie może być puste.</span>
                        </div>
                        <div class="mt-4 select-none">
                            <label for="pass" class="block text-sm font-medium text-slate-100">Nazwisko*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="20" v-model="newUser.surname" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. Kowalski" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.surname"> Pole nie może być puste.</span>
                            <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                        </div>
                        <div class="mt-4 select-none">
                            <label for="email" class="block text-sm font-medium text-slate-100">Telefon komórkowy*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="12" v-model="newUser.contact.phone" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="Numer telefonu z kierunkowym" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.phone"> Poprawny format to +XXXXXXXXXXX lub XXXXXXXXX.</span>
                        </div>
                    </div>
                    <div v-if="!this.newUser.worker" class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Dane firmy</div>
                        <div class="select-none">
                            <label for="company-name" class="block text-sm font-medium text-slate-100">Nazwa firmy*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="100" name="company-name" id="company-name" v-model="newUser.company.name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. WB Tank Sp. z o.o." />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.company.name"> Pole nie może być puste.</span>
                        </div>
                        <div class="mt-4 select-none">
                            <label for="pass" class="block text-sm font-medium text-slate-100">NIP*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="20" v-model="newUser.company.nip" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. 8421761290" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.company.nip"> Poprawny format to XXXXXXXXXX.</span>
                            <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                        </div>
                        <div class="mt-4 select-none">
                            <label for="company-address" class="block text-sm font-medium text-slate-100">Adres*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="40" name="company-address" id="company-address" v-model="newUser.company.address" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. Bytów 93" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.company.address"> Pole nie może być puste.</span>
                            <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                        </div>
                        <div class="mt-4 select-none">
                            <label for="post-code" class="block text-sm font-medium text-slate-100">Kod pocztowy*</label>
                            <div class="mt-1">
                                <input type="text" id="post-code" name="post-code" maxlength="20" v-model="newUser.company.postCode" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. 77-100" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.company.postCode"> Poprawny format to XX-XXX.</span>
                            <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                        </div>
                        <div class="mt-4 select-none">
                            <label for="city" class="block text-sm font-medium text-slate-100">Miejscowość*</label>
                            <div class="mt-1">
                                <input type="text" id="city" name="city" maxlength="40" v-model="newUser.company.city" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. Bytów" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.company.city"> Pole nie może być puste.</span>
                            <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                        </div>
                    </div>
                    <div v-if="!this.newUser.worker" class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Grupa kart</div>
                        <div class="select-none">
                            <label for="groupOfCard" class="block text-sm font-medium text-slate-100">Wybierz grupę kart*</label>
                            <div class="mt-1">
                                <select @change="setGroup(groupToSet)" v-model="groupToSet" placeholder="---" id="groupOfCard" name="groupOfCard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                  <option v-for="group in groupsOfCards" :key="group.groupName" :value="group">{{group.groupName}} ({{group.groupId}})</option>
                                </select>
                            </div>
                            <div class="flex flex-col mt-1 space-y-2">
                            <span class="text-xs select-none text-red-500" v-if="errors.groupOfCards"> Musisz wybrać grupę kart.</span>
                            <span class="text-xs select-none text-red-500" v-if="downloadError"> Nie udało się pobrać grup kart, spróbuj ponownie później lub zgłoś administratorowi systemu.</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="!this.newUser.worker" class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Rabaty</div>
                        <button @click.prevent="displayDiscounts = !displayDiscounts" class="rounded-md mb-4 border border-slate-300 bg-white py-2 px-4 text-sm font-medium text-slate-700 shadow-sm hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">{{displayDiscounts ? 'Schowaj' :'Pokaż'}}</button>
                        <div v-if="displayDiscounts" class="grid grid-cols-4">
                          <!-- Stacja wbtank -->
                          <div class="border pb-4 border-slate-400">
                            <div class="text-slate-100 font-medium text-base border-b border-slate-400 flex justify-center py-2">Stacja WBtank</div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselWbtank" class="block text-xs font-medium text-slate-200">Shell FuelSave Diesel<span class="text-red-500">*</span></label>
                              <div class="mt-1">
                                  <!-- <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.diesel.wbtank[0].amount" id="discountDieselWbtank" name="discountDieselWbtank" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">ORLEN +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="0">ORLEN 0%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">ORLEN -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        <span :class="['text-xs', newUser.discounts.diesel.wbtank[0].amount !== null && newUser.discounts.diesel.wbtank[0].amount !== false ? 'text-red-500' : 'text-slate-600']">
                                          {{newUser.discounts.diesel.wbtank[0].amount !== null && newUser.discounts.diesel.wbtank[0].amount !== false ? 'ORLEN' : 'SŁUP'}}
                                        </span>
                                      </span>
                                    </div>
                                  </div> -->
                                  <div class="flex">
                                    <div class="w-7/12 relative">
                                      <select v-model="newUser.discounts.diesel.wbtank[0].amount" id="discountDieselWbtank" name="discountDieselWbtank" class="block w-full rounded-l-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option v-for="discount in 15" :key="discount" :value="discount/100">+{{(discount/100).toFixed(2)}} PLN</option>
                                        <option :value="'5%'">+5%</option>
                                        <option :value="'4.5%'">+4.5%</option>
                                        <option :value="'4%'">+4%</option>
                                        <option :value="'3.5%'">+3.5%</option>
                                        <option :value="'3%'">+3%</option>
                                        <option :value="'2.5%'">+2.5%</option>
                                        <option :value="'2%'">+2%</option>
                                        <option :value="'1.5%'">+1.5%</option>
                                        <option :value="'1%'">+1%</option>
                                        <option :value="'0.5%'">+0.5%</option>
                                        <option :value="false">0%</option>
                                        <option :value="'-0.5%'">-0.5%</option>
                                        <option :value="'-1%'">-1%</option>
                                        <option :value="'-1.5%'">-1.5%</option>
                                        <option :value="'-2%'">-2%</option>
                                        <option :value="'-2.5%'">-2.5%</option>
                                        <option :value="'-3%'">-3%</option>
                                        <option :value="'-3.5%'">-3.5%</option>
                                        <option :value="'-4%'">-4%</option>
                                        <option :value="'-4.5%'">-4.5%</option>
                                        <option :value="'-5%'">-5%</option>
                                        <option v-for="discount in 40" :key="discount" :value="-(discount/100)">-{{(discount/100).toFixed(2)}} PLN</option>
                                      </select>
                                    </div>
                                    <div class="w-5/12">
                                      <select v-model="newUser.discounts.diesel.wbtank[0].orlenSpot" id="discountDieselWbtank" name="discountDieselWbtank" class="block w-full rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option :value="false">SŁUP</option>
                                        <option :value="true">ORLEN</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountdieselVPowerWbtank" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ Diesel<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.dieselVPower.wbtank[0].amount" id="discountdieselVPowerWbtank" name="discountdieselVPowerWbtank" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountAdBlueWbtank" class="block text-xs font-medium text-slate-200">AdBlue<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.adBlue.wbtank[0].amount" id="discountdABlueWbtank" name="discountAdBlueWbtank" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 391" :key="discount" :value="(discount+9)/100">SŁUP -{{((discount+9)/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountdPetrol95Wbtank" class="block text-xs font-medium text-slate-200">Shell FuelSave 95<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.petrol95.wbtank[0].amount" id="discountdPetrol95Wbtank" name="discountdPetrol95Wbtank" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountdPetrol95VPowerWbtank" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ 95 AL<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.petrol95VPower.wbtank[0].amount" id="discountdPetrol95VPowerWbtank" name="discountdPetrol95VPowerWbtank" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountdPetrol100RacingWbtank" class="block text-xs font-medium text-slate-200">Shell Racing 100<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.petrol100Racing.wbtank[0].amount" id="discountdPetrol100RacingWbtank" name="discountdPetrol100RacingWbtank" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountsLpgWbtank" class="block text-xs font-medium text-slate-200">LPG<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.lpg.wbtank[0].amount" id="discountsLpgWbtank" name="discountsLpgWbtank" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <!-- Stacja ekonomiczna -->
                          <div class="border pb-4 border-slate-400">
                            <div class="text-slate-100 font-medium text-base border-b border-slate-400 flex justify-center py-2">Stacja ekonomiczna</div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselEconomy" class="block text-xs font-medium text-slate-200">Shell FuelSave Diesel<span class="text-red-500">*</span></label>
                              <div class="mt-1">
                                  <!-- <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.diesel.economy[0].amount" id="discountDieselEconomy" name="discountDieselEconomy" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">ORLEN +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="0">ORLEN 0%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">ORLEN -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        <span :class="['text-xs', newUser.discounts.diesel.economy[0].amount !== null && newUser.discounts.diesel.economy[0].amount !== false ? 'text-red-500' : 'text-slate-600']">
                                          {{newUser.discounts.diesel.economy[0].amount !== null && newUser.discounts.diesel.economy[0].amount !== false ? 'ORLEN' : 'SŁUP'}}
                                        </span>
                                      </span>
                                    </div>
                                  </div> -->
                                  <div class="flex">
                                    <div class="w-7/12 relative">
                                      <select v-model="newUser.discounts.diesel.economy[0].amount" id="discountDieselEconomy" name="discountDieselEconomy" class="block w-full rounded-l-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option v-for="discount in 15" :key="discount" :value="discount/100">+{{(discount/100).toFixed(2)}} PLN</option>
                                        <option :value="'5%'">+5%</option>
                                        <option :value="'4.5%'">+4.5%</option>
                                        <option :value="'4%'">+4%</option>
                                        <option :value="'3.5%'">+3.5%</option>
                                        <option :value="'3%'">+3%</option>
                                        <option :value="'2.5%'">+2.5%</option>
                                        <option :value="'2%'">+2%</option>
                                        <option :value="'1.5%'">+1.5%</option>
                                        <option :value="'1%'">+1%</option>
                                        <option :value="'0.5%'">+0.5%</option>
                                        <option :value="false">0%</option>
                                        <option :value="'-0.5%'">-0.5%</option>
                                        <option :value="'-1%'">-1%</option>
                                        <option :value="'-1.5%'">-1.5%</option>
                                        <option :value="'-2%'">-2%</option>
                                        <option :value="'-2.5%'">-2.5%</option>
                                        <option :value="'-3%'">-3%</option>
                                        <option :value="'-3.5%'">-3.5%</option>
                                        <option :value="'-4%'">-4%</option>
                                        <option :value="'-4.5%'">-4.5%</option>
                                        <option :value="'-5%'">-5%</option>
                                        <option v-for="discount in 40" :key="discount" :value="-(discount/100)">-{{(discount/100).toFixed(2)}} PLN</option>
                                      </select>
                                    </div>
                                    <div class="w-5/12">
                                      <select v-model="newUser.discounts.diesel.economy[0].orlenSpot" id="discountDieselEconomy" name="discountDieselEconomy" class="block w-full rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option :value="false">SŁUP</option>
                                        <option :value="true">ORLEN</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselVPowerEconomy" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ Diesel<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.dieselVPower.economy[0].amount" id="discountDieselVPowerEconomy" name="discountDieselVPowerEconomy" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountAdBlueEconomy" class="block text-xs font-medium text-slate-200">AdBlue<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.adBlue.economy[0].amount" id="discountAdBlueEconomy" name="discountAdBlueEconomy" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 391" :key="discount" :value="(discount+9)/100">SŁUP -{{((discount+9)/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol95Economy" class="block text-xs font-medium text-slate-200">Shell FuelSave 95<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.petrol95.economy[0].amount" id="discountPetrol95Economy" name="discountPetrol95Economy" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol95VPowerEconomy" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ 95 AL<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.petrol95VPower.economy[0].amount" id="discountPetrol95VPowerEconomy" name="discountPetrol95VPowerEconomy" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol100RacingEconomy" class="block text-xs font-medium text-slate-200">Shell Racing 100<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.petrol100Racing.economy[0].amount" id="discountPetrol100RacingEconomy" name="discountPetrol100RacingEconomy" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountsLpgEconomy" class="block text-xs font-medium text-slate-200">LPG<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.lpg.economy[0].amount" id="discountsLpgEconomy" name="discountsLpgEconomy" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <!-- Stacja standardowa -->
                          <div class="border pb-4 border-slate-400">
                            <div class="text-slate-100 font-medium text-base border-b border-slate-400 flex justify-center py-2">Stacja standardowa</div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselStandard" class="block text-xs font-medium text-slate-200">Shell FuelSave Diesel<span class="text-red-500">*</span></label>
                              <div class="mt-1">
                                  <!-- <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.diesel.standard[0].amount" id="discountDieselStandard" name="discountDieselStandard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">ORLEN +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="0">ORLEN 0%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">ORLEN -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        <span :class="['text-xs', newUser.discounts.diesel.standard[0].amount !== null && newUser.discounts.diesel.standard[0].amount !== false ? 'text-red-500' : 'text-slate-600']">
                                          {{newUser.discounts.diesel.standard[0].amount !== null && newUser.discounts.diesel.standard[0].amount !== false ? 'ORLEN' : 'SŁUP'}}
                                        </span>
                                      </span>
                                    </div>
                                  </div> -->
                                  <div class="flex">
                                    <div class="w-7/12 relative">
                                      <select v-model="newUser.discounts.diesel.standard[0].amount" id="discountDieselStandard" name="discountDieselStandard" class="block w-full rounded-l-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option v-for="discount in 15" :key="discount" :value="discount/100">+{{(discount/100).toFixed(2)}} PLN</option>
                                        <option :value="'5%'">+5%</option>
                                        <option :value="'4.5%'">+4.5%</option>
                                        <option :value="'4%'">+4%</option>
                                        <option :value="'3.5%'">+3.5%</option>
                                        <option :value="'3%'">+3%</option>
                                        <option :value="'2.5%'">+2.5%</option>
                                        <option :value="'2%'">+2%</option>
                                        <option :value="'1.5%'">+1.5%</option>
                                        <option :value="'1%'">+1%</option>
                                        <option :value="'0.5%'">+0.5%</option>
                                        <option :value="false">0%</option>
                                        <option :value="'-0.5%'">-0.5%</option>
                                        <option :value="'-1%'">-1%</option>
                                        <option :value="'-1.5%'">-1.5%</option>
                                        <option :value="'-2%'">-2%</option>
                                        <option :value="'-2.5%'">-2.5%</option>
                                        <option :value="'-3%'">-3%</option>
                                        <option :value="'-3.5%'">-3.5%</option>
                                        <option :value="'-4%'">-4%</option>
                                        <option :value="'-4.5%'">-4.5%</option>
                                        <option :value="'-5%'">-5%</option>
                                        <option v-for="discount in 40" :key="discount" :value="-(discount/100)">-{{(discount/100).toFixed(2)}} PLN</option>
                                      </select>
                                    </div>
                                    <div class="w-5/12">
                                      <select v-model="newUser.discounts.diesel.standard[0].orlenSpot" id="discountDieselStandard" name="discountDieselStandard" class="block w-full rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option :value="false">SŁUP</option>
                                        <option :value="true">ORLEN</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselVPowerStandard" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ Diesel<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.dieselVPower.standard[0].amount" id="discountDieselVPowerStandard" name="discountDieselVPowerStandard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountAdBlueStandard" class="block text-xs font-medium text-slate-200">AdBlue<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.adBlue.standard[0].amount" id="discountAdBlueStandard" name="discountAdBlueStandard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 391" :key="discount" :value="(discount+9)/100">SŁUP -{{((discount+9)/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol95Standard" class="block text-xs font-medium text-slate-200">Shell FuelSave 95<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.petrol95.standard[0].amount" id="discountPetrol95Standard" name="discountPetrol95Standard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol95VPowerStandard" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ 95 AL<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.petrol95VPower.standard[0].amount" id="discountPetrol95VPowerStandard" name="discountPetrol95VPowerStandard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol100RacingStandard" class="block text-xs font-medium text-slate-200">Shell Racing 100<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.petrol100Racing.standard[0].amount" id="discountPetrol100RacingStandard" name="discountPetrol100RacingStandard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountsLpgStandard" class="block text-xs font-medium text-slate-200">LPG<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.lpg.standard[0].amount" id="discountsLpgStandard" name="discountsLpgStandard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <!-- Stacja przy autostardzie -->
                          <div class="border pb-4 border-slate-400">
                            <div class="text-slate-100 font-medium text-base border-b border-slate-400 flex justify-center py-2">Stacja przy autostradzie</div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselHighway" class="block text-xs font-medium text-slate-200">Shell FuelSave Diesel<span class="text-red-500">*</span></label>
                              <div class="mt-1">
                                  <!-- <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.diesel.highway[0].amount" id="discountDieselHighway" name="discountDieselHighway" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">ORLEN +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="0">ORLEN 0%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">ORLEN -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        <span :class="['text-xs', newUser.discounts.diesel.highway[0].amount !== null && newUser.discounts.diesel.highway[0].amount !== false ? 'text-red-500' : 'text-slate-600']">
                                          {{newUser.discounts.diesel.highway[0].amount !== null && newUser.discounts.diesel.highway[0].amount !== false ? 'ORLEN' : 'SŁUP'}}
                                        </span>
                                      </span>
                                    </div>
                                  </div> -->
                                  <div class="flex">
                                    <div class="w-7/12 relative">
                                      <select v-model="newUser.discounts.diesel.highway[0].amount" id="discountDieselHighway" name="discountDieselHighway" class="block w-full rounded-l-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option v-for="discount in 15" :key="discount" :value="discount/100">+{{(discount/100).toFixed(2)}} PLN</option>
                                        <option :value="'5%'">+5%</option>
                                        <option :value="'4.5%'">+4.5%</option>
                                        <option :value="'4%'">+4%</option>
                                        <option :value="'3.5%'">+3.5%</option>
                                        <option :value="'3%'">+3%</option>
                                        <option :value="'2.5%'">+2.5%</option>
                                        <option :value="'2%'">+2%</option>
                                        <option :value="'1.5%'">+1.5%</option>
                                        <option :value="'1%'">+1%</option>
                                        <option :value="'0.5%'">+0.5%</option>
                                        <option :value="false">0%</option>
                                        <option :value="'-0.5%'">-0.5%</option>
                                        <option :value="'-1%'">-1%</option>
                                        <option :value="'-1.5%'">-1.5%</option>
                                        <option :value="'-2%'">-2%</option>
                                        <option :value="'-2.5%'">-2.5%</option>
                                        <option :value="'-3%'">-3%</option>
                                        <option :value="'-3.5%'">-3.5%</option>
                                        <option :value="'-4%'">-4%</option>
                                        <option :value="'-4.5%'">-4.5%</option>
                                        <option :value="'-5%'">-5%</option>
                                        <option v-for="discount in 40" :key="discount" :value="-(discount/100)">-{{(discount/100).toFixed(2)}} PLN</option>
                                      </select>
                                    </div>
                                    <div class="w-5/12">
                                      <select v-model="newUser.discounts.diesel.highway[0].orlenSpot" id="discountDieselHighway" name="discountDieselHighway" class="block w-full rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option :value="false">SŁUP</option>
                                        <option :value="true">ORLEN</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselVPowerHighway" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ Diesel<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.dieselVPower.highway[0].amount" id="discountDieselVPowerHighway" name="discountDieselVPowerHighway" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountAdBlueHighway" class="block text-xs font-medium text-slate-200">AdBlue<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.adBlue.highway[0].amount" id="discountAdBlueHighway" name="discountAdBlueHighway" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 391" :key="discount" :value="(discount+9)/100">SŁUP -{{((discount+9)/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol95Highway" class="block text-xs font-medium text-slate-200">Shell FuelSave 95<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.petrol95.highway[0].amount" id="discountPetrol95Highway" name="discountPetrol95Highway" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol95VPowerHighway" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ 95 AL<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.petrol95VPower.highway[0].amount" id="discountPetrol95VPowerHighway" name="discountPetrol95VPowerHighway" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol100RacingHighway" class="block text-xs font-medium text-slate-200">Shell Racing 100<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.petrol100Racing.highway[0].amount" id="discountPetrol100RacingHighway" name="discountPetrol100RacingHighway" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountsLpgHighway" class="block text-xs font-medium text-slate-200">LPG<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="newUser.discounts.lpg.highway[0].amount" id="discountsLpgHighway" name="discountsLpgHighway" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div v-if="!this.newUser.worker" class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Czas płatności faktury</div>
                        <div class="select-none">
                            <label for="invoicePaymentTime" class="block text-sm font-medium text-slate-100">Wybierz czas płatności faktury*</label>
                            <div class="mt-1">
                                <select v-model="newUser.invoicePaymentTime" placeholder="---" id="invoicePaymentTime" name="invoicePaymentTime" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                  <option v-for="days in 40" :key="days" :value="days">{{days}} dni</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-if="!this.newUser.worker" class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Wysyłka faktur</div>
                        <div class="select-none">
                            <label for="invoiceEmail" class="block text-sm font-medium text-slate-100">Adres e-mail do wysyłki faktur</label>
                            <div class="mt-1">
                                <input type="text" id="invoiceEmail" name="invoiceEmail" maxlength="35" v-model="newUser.contact.invoiceEmail" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. faktury@gmail.com" />
                            </div>
                            <span class="block text-xs text-slate-100 mt-1"><span class="text-red-500">*</span>Pole nieobowiązkowe, uzupełnij w przypadku innego adresu.</span>
                            <span class="text-xs select-none text-red-500" v-if="errors.invoiceEmail"> Sprawdź poprawność pola, bądź zostawe je puste.</span>
                            <span class="text-xs select-none text-red-500" v-if="errors.invoiceEmailRepeat"> Adres musi być inny od podanego adresu logowania.</span>
                        </div>
                    </div>
                    <div v-if="!this.newUser.worker" class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Tryb fakturowania</div>
                        <div class="select-none">
                            <label for="invoicePaymentTime" class="block text-sm font-medium text-slate-100">Wybierz tryb fakturowania*</label>
                            <div class="mt-1">
                                <select v-model="newUser.activeInvoices" placeholder="---" id="invoicePaymentTime" name="invoicePaymentTime" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                  <option :value="false">TESTOWE</option>
                                  <option :value="true">AKTYWNE</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-if="!this.newUser.worker" class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Ilość faktur w miesiącu</div>
                        <div class="select-none">
                            <label for="amountOfInvoicesInMonth" class="block text-sm font-medium text-slate-100">Wybierz ilość*</label>
                            <div class="mt-1">
                                <select v-model="newUser.amountOfInvoicesInMonth" placeholder="---" id="amountOfInvoicesInMonth" name="amountOfInvoicesInMonth" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                  <option :value="1">1</option>
                                  <option :value="2">2</option>
                                  <option :value="4">4</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-if="!this.newUser.worker" class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Umowa</div>
                        <div class="select-none">
                            <label for="pickedDateOfAgreement" class="block text-sm font-medium text-slate-100">Wybierz datę zakończenia umowy</label>
                            <div class="mt-1">
                                <input type="date" @change="pickDateOfAgreement($event)" v-model="newUser.agreement.pickedDateOfAgreement" ref="pickedDateOfAgreement"  name="pickedDateOfAgreement" id="pickedDateOfAgreement" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" />
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-shrink-0 justify-end px-4 py-4 items-center">
                  <span class="text-xs text-slate-100" v-if="loading">Zapisywanie..</span>
                  <button @click.prevent="emitClose" v-if="!loading" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Zamknij</button>
                  <button ref="validateButton" @click.prevent="validateUser" v-if="!loading" class="ml-2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Dodaj</button>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
    import { QuestionMarkCircleIcon } from '@heroicons/vue/solid';
    import axios from 'axios';
    import {db} from "@/firebase/gfbconf.js";
    import moment from 'moment'
    export default {
        name: "ClientsModal",
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            QuestionMarkCircleIcon,
        },
        data: function () {
            return {
              displayDiscounts: false,
              loading: false,
              groupToSet: null,
              errors: {
                email: false,
                password: false,
                name: false,
                surname: false,
                phone: false,
                groupOfCards: false,
                company: {
                    name: false,
                    nip: false,
                    address: false,
                    postCode: false,
                    city: false,
                  },
                invoiceEmail: false,
                invoiceEmailRepeat: false
              },
              open: false,
              password: "",
              newUser: {
                  active: true,
                  worker: false,
                  name: "",
                  surname: "",
                  contact: {
                      email: "",
                      phone: "",
                      invoiceEmail: ""
                  },
                  company:{
                    name: "",
                    nip: "",
                    address: "",
                    postCode: "",
                    city: "",
                  },
                  groupOfCards: "",
                  groupOfCardsId: null,
                  id: null,
                  permissions: {
                      admin: false,
                      user: true
                  },
                  discounts:{
                    diesel:{
                      wbtank: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true,
                          orlenSpot: false
                        }
                      ],
                      economy: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true,
                          orlenSpot: false
                        }
                      ],
                      standard: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true,
                          orlenSpot: false
                        }
                      ],
                      highway: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true,
                          orlenSpot: false
                        }
                      ]
                    },
                    adBlue:{
                      wbtank: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      economy: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      standard: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      highway: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ]
                    },
                    dieselVPower:{
                      wbtank: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      economy: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      standard: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      highway: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ]
                    },
                    petrol95:{
                      wbtank: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      economy: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      standard: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      highway: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ]
                    },
                    petrol95VPower:{
                      wbtank: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      economy: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      standard: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      highway: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ]
                    },
                    petrol100Racing:{
                      wbtank: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      economy: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      standard: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      highway: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ]
                    },
                    lpg:{
                      wbtank: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      economy: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      standard: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      highway: [
                        {
                          amount: false,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ]
                    },
                  },
                  invoices: [],
                  notifications: [],
                  files: [],
                  invoicePaymentTime: 14,
                  activeInvoices: false,
                  amountOfInvoicesInMonth: 4,
                  agreement: {
                    pickedDateOfAgreementUnix: null,
                    pickedDateOfAgreement: null
                  }
              },
              downloadError: false,
              groupsOfCards: [],
            }
        },
        methods: {
          log(log){
            console.log(log)
          },
          pickDateOfAgreement(event){
                let mDate = moment(event.target.value)
                if(!mDate.isValid())
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Popraw datę!",
                        subheader: `Wykryto, że data jest niepoprawna.`,
                        success: false
                    });
                    this.newUser.agreement.pickedDateOfAgreement = null;
                    this.newUser.agreement.pickedDateOfAgreementUnix = null
                }
                else
                {
                    this.newUser.agreement.pickedDateOfAgreementUnix = mDate.unix()
                    // this.pickStatus()
                }
            },
          setGroup(group){
            this.newUser.groupOfCards = group.groupName
            this.newUser.groupOfCardsId = group.groupId
          },
          changeTypeOfUser(type){
            if(type === 'user'){
              this.newUser.worker = false
              this.newUser.permissions.user = true
              this.newUser.permissions.admin = false
            }
            if(type === 'worker'){
              this.newUser.worker = true
              this.newUser.permissions.user = false
              this.newUser.permissions.admin = true
              this.newUser.company = {
                name: "",
                nip: "",
                address: "",
                postCode: "",
                city: "",
              }
              this.newUser.groupOfCards = ""
            }
          },
          callNotificationOnError(translatedCode)
          {
            this.emitClose();
            this.$store.commit('setNotification',{
              show: true,
              head: "Akcja zakończona niepowodzeniem!",
              subheader: translatedCode,
              success: false
            });
          },
          checkAutheError(code)
          {
            switch (code) {
              case 'auth/email-already-in-use':
                this.callNotificationOnError('Email jest już w użyciu przez innego użytkownika.');
                break;
              case 'auth/email-already-exists':
                this.callNotificationOnError('Email jest już w użyciu przez innego użytkownika.');
                break;
              case 'auth/invalid-email':
                this.callNotificationOnError('E-mail jest niepoprawny.');
                break;
              case 'auth/operation-not-allowed':
                this.callNotificationOnError('Operacja zakazana przez system autoryzacji.');
                break;
              case 'auth/weak-password':
                this.callNotificationOnError('Hasło jest zbyt słabe. Sugerowane hasło powinno być dłuższe niż 6 znaków oraz zawierać znaki specjalne.');
                break;
              default:
                console.log(code);
                break;
            }   
          },
          resetErrors()
          {
            // Reset all errors
            this.downloadError = false;
            this.errors.email = false;
            this.errors.password = false;
            this.errors.name =  false;
            this.errors.surname = false;
            this.errors.phone = false;
            this.errors.groupOfCards = false;
            this.errors.company = {
                    name: false,
                    nip: false,
                    address: false,
                    postCode: false,
                    city: false,
                  };
            this.errors.invoiceEmail = false;
            this.errors.invoiceEmailRepeat = false;
          },
          async addUser()
          {
            this.loading = true;
            if(!this.newUser.permissions.admin && !this.newUser.permissions.user){
              this.newUser.permissions.user = true
              this.newUser.permissions.admin = false
            }
            try{
              this.newUser.contact.email = this.newUser.contact.email.toLowerCase();
              const checkForUsrInDb = await db.collection("UsersData").where("contact.email", "==", this.newUser.contact.email).get();
              const checkForWrkrInDb = await db.collection("WorkerData").where("contact.email", "==", this.newUser.contact.email).get();
              if(checkForUsrInDb.empty && checkForWrkrInDb.empty)
              {
                const result = await axios.post(`${this.$store.state.apiLink}/newUser`, {user: this.newUser, password: this.password});
                if(result.data.success)
                {
                  this.emitClose();
                  this.$store.commit('setNotification',{
                    show: true,
                    head: "Akcja zakończona sukcesem!",
                    subheader: "Użytkownik został utworzony.",
                    success: true
                  });
                  this.loading = false;
                }
                else
                {
                  this.checkAutheError(result.data.log);
                  this.loading = false;
                }
                
              }
              else
              {
                this.callNotificationOnError('Email jest już w użyciu przez innego użytkownika.');
                this.loading = false;
              }
              // const usr = await auth.createUserWithEmailAndPassword(this.newUser.contact.email, this.password);
              
            }
            catch(error)
            {
              console.log(error);
              this.loading = false;
            }
            
          },
          userValidator()
          {
            let errorsAmount = 0;
            this.resetErrors();
            if(this.newUser.contact.email === 0)
            {
              errorsAmount++;
              this.errors.email = true;
            }
            if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.newUser.contact.email.toLowerCase()) === false)
            {
              errorsAmount++;
              this.errors.email = true;
            }
            if(this.password.length < 6)
            {
              errorsAmount++;
              this.errors.password = true;
            }
            if(this.newUser.name.length === 0)
            {
              errorsAmount++;
              this.errors.name = true;
            }
            if(this.newUser.surname.length === 0)
            {
              errorsAmount++;
              this.errors.surname = true;
            }
            if(/^\+?[1-9][0-9]{7,14}$/.test(this.newUser.contact.phone) === false)
            {
              errorsAmount++;
              this.errors.phone = true;
            }

            if(!this.newUser.worker){
              if(this.newUser.groupOfCards.length === 0)
              {
                errorsAmount++;
                this.errors.groupOfCards = true;
              }
              if(this.newUser.company.name.length === 0)
              {
                errorsAmount++;
                this.errors.company.name = true;
              }
              if(/^[0-9]{10}$/.test(this.newUser.company.nip) === false)
              {
                errorsAmount++;
                this.errors.company.nip = true;
              }
              if(this.newUser.company.address.length === 0)
              {
                errorsAmount++;
                this.errors.company.address = true;
              }
              if(/^[0-9]{2}-[0-9]{3}/.test(this.newUser.company.postCode) === false)
              {
                errorsAmount++;
                this.errors.company.postCode = true;
              }
              if(this.newUser.company.city.length === 0)
              {
                errorsAmount++;
                this.errors.company.city = true;
              }
              if(this.newUser.contact.invoiceEmail.length !== 0)
              {
                if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.newUser.contact.invoiceEmail.toLowerCase()) === false)
                {
                  errorsAmount++;
                  this.errors.invoiceEmail = true;
                }
                if(this.newUser.contact.invoiceEmail === this.newUser.contact.email){
                  errorsAmount++;
                  this.errors.invoiceEmailRepeat = true;
                }
              }
            }

            return errorsAmount;

          },
          async validateUser()
          {
            const errors = this.userValidator();
            if(errors > 0) return;
            this.addUser();
          },
          emitClose()
          {
            this.resetErrors();
            this.$emit("closeClientModal");
            this.clearData();
          },
          clearData()
          {
              this.password = "";
              this.newUser = {
                  active: true,
                  worker: false,
                  name: "",
                  surname: "",
                  contact: {
                      email: "",
                      phone: ""
                  },
                  company:{
                    name: "",
                    nip: "",
                    address: "",
                    postCode: "",
                    city: "",
                  },
                  groupOfCards: "",
                  groupOfCardsId: null,
                  id: null,
                  permissions: {
                      admin: false,
                      user: true
                  },
                  discounts:{
                    diesel:{
                      wbtank: [
                        {
                          amount: null,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      economy: [
                        {
                          amount: null,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      standard: [
                        {
                          amount: null,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      highway: [
                        {
                          amount: null,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ]
                    },
                    adBlue:{
                      wbtank: [
                        {
                          amount: 0,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      economy: [
                        {
                          amount: 0,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      standard: [
                        {
                          amount: 0,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ],
                      highway: [
                        {
                          amount: 0,
                          dateFrom: null,
                          dateTo: null,
                          active: true
                        }
                      ]
                    }
                  },
                  invoices: [],
                  activeInvoices: false
              }         
          },
          async downloadGroupsOfCards(){
            const res = await axios.post(`${this.$store.state.apiLink}/getGroupsOfCards`, {
            })
            if(res.data.success){
                this.groupsOfCards = res.data.groups
                this.downloadError = false
            }else{
                this.downloadError = true
            }
          },
        },
        computed:{

        },
        created(){
          this.downloadGroupsOfCards()
        }
    };
</script>