<template>
    <div>
        <div class="flex justify-between">
            <nav class="hidden sm:flex h-8" aria-label="Breadcrumb">
                <ol role="list" class="flex space-x-4 rounded-md bg-slate-500 px-6 shadow">
                    <li class="flex">
                        <div class="flex items-center">
                        <router-link to="/hub" class="transition ease-in-out duration-300 text-slate-100 hover:text-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span class="sr-only">Home</span>
                        </router-link>
                        </div>
                    </li>
                    <li class="flex">
                        <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <div class="ml-4 text-xs font-medium text-slate-100 opacity-60 cursor-default select-none" aria-current="page">Faktury</div>
                        </div>
                    </li>
                </ol>
                <!-- <div @click.prevent="getInvoicesTest()" class="ml-4 cursor-pointer flex items-center justify-center px-4 bg-red-200">TEST FAKTUR</div> -->
                <!-- <div @click.prevent="testInvoice()" class="ml-4 cursor-pointer flex items-center justify-center px-4 bg-red-200">Wygenerowanie wszystkich faktur</div> -->
            </nav>
            <div class="flex items-center sm:hidden">
                <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 text-sm font-medium text-slate-300 hover:text-slate-400" aria-current="page">&larr; Cofnij</router-link>
            </div>
            <div class="flex">
                <Transition name="fade">
                <div v-if="showFilters" class="mr-8 flex">
                    <div class="relative rounded-md  focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600 mr-5">
                        <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-slate-600 px-1 text-xs font-medium text-slate-300 rounded-t-md">Status</label>
                        <select @change="updateFilters()" v-model="paidStatus" class="block w-full pl-3 pr-10 py-2 text-sm text-slate-200 bg-slate-600 border-slate-300 focus:outline-none focus:ring-blue-300 focus:border-blue-300 rounded-md">
                            <option value="all">Wszystkie</option>
                            <option value="paid">Zapłacone</option>
                            <option value="notPaid">Nie zapłacone</option>
                        </select>
                    </div>
                    <div class="relative rounded-md  focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                        <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-slate-600 px-1 text-xs font-medium text-slate-300 rounded-t-md">Firma</label>
                        <select @change="updateFilters()" v-model="filterCompanyId" class="block w-full pl-3 pr-10 py-2 text-sm text-slate-200 bg-slate-600 border-slate-300 focus:outline-none focus:ring-blue-300 focus:border-blue-300 rounded-md">
                            <option value="all">Wszystkie</option>
                            <option v-for="company in companies" :key="company.id" :value="company.id">{{company.company.name}}</option>
                        </select>
                    </div>
                </div>
                </Transition>
                <div @click="toggleFilters()" class="mr-4 cursor-pointer flex justify-center items-center flex-col select-none">
                    <FilterIcon class="h-4 w-4 text-slate-200 flex-shrink-0" aria-hidden="true" />
                    <span class="text-xs font-medium text-slate-300">{{showFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
                </div>
            </div>
        </div>
    <InvoicePaymentModal v-if="modalVisible" @closeModal="modalVisible = false" :modalData="modalData" @accept="updatePaidAmount"/>
    <div class="mt-4 flex flex-col">
        <div class="overflow-x-auto">
            <div class="inline-block min-w-full py-2 align-middle">
                <div class="overflow-hidden min-h-64 shadow border border-slate-700 rounded-lg">
                    <table class="min-w-full divide-y divide-slate-700">
                        <thead class="bg-slate-700">
                            <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold whitespace-nowrap text-slate-100 sm:pl-6">Nazwa faktury</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Firma</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Okres</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Pierwsze pobranie</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Zapłacono</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Pobierz</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                    <div class="flex items-center gap-5">
                                        <Menu as="div" class="relative inline-block text-left">
                                            <div>
                                                <MenuButton class="transition ease-in-out duration-300 inline-flex w-full justify-center gap-x-1.5 font-medium rounded-md bg-slate-600 px-2 py-1 text-sm text-slate-100 shadow-sm ring-1 ring-inset ring-slate-800 hover:bg-slate-500">
                                                    Menu
                                                    <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </MenuButton>
                                            </div>
                                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                            <MenuItems class="absolute right-0 z-10 mt-2 w-62 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div class="py-1">
                                                <MenuItem v-slot="{ active }">
                                                    <div @click.prevent="goToCreateInvoice()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']">Stwórz fakturę/korektę</div>
                                                </MenuItem>
                                                <MenuItem v-slot="{ active }">
                                                    <div @click.prevent="openAcceptInvoiceModal()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']">Zaakceptuj wszystkie faktury</div>
                                                </MenuItem>
                                                <MenuItem v-slot="{ active }">
                                                    <div @click.prevent="openCsvExportModal()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']">Eksportowanie faktur</div>
                                                </MenuItem>
                                                <MenuItem v-slot="{ active }">
                                                    <div @click.prevent="openInvoiceStatementModal()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']">Zestawienia faktur</div>
                                                </MenuItem>
                                                <MenuItem v-slot="{ active }">
                                                    <div @click.prevent="openProductStatementModal()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']">Zestawienia produktowe</div>
                                                </MenuItem>
                                                <MenuItem v-slot="{ active }">
                                                    <div @click.prevent="testInvoice()" :class="[active && !invoicesGenerating && !lockGenerateInvoice ? 'bg-gray-100 text-gray-900' : 'text-gray-700', lockGenerateInvoice || invoicesGenerating ? 'opacity-50 pointer-events-none' : '' , 'block px-4 py-2 text-sm cursor-pointer']">{{invoicesGenerating ? 'Trwa generowanie faktur...' : lockGenerateInvoice ? 'Wygenerowano faktury' : 'Generowanie faktur'}}</div>
                                                </MenuItem>
                                                <MenuItem v-slot="{ active }">
                                                    <div @click.prevent="removeTestInvoice()" :class="[active && !testInvoicesDeleting ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']">{{testInvoicesDeleting ? 'Trwa usuwanie...' : 'Usuwanie testowych faktur'}}</div>
                                                </MenuItem>
                                                <!-- <MenuItem v-slot="{ active }">
                                                    <div @click="selectUnacceptedInvoices()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']">
                                                       {{!checkUnacceptedInvoices ? 'Usuń niezaakceptowane faktury' : 'Wyłącz muliselect xd'}}
                                                    </div>
                                                </MenuItem> -->
                                                </div>
                                            </MenuItems>
                                            </transition>
                                        </Menu>
                                        <!-- <button type="button" @click.prevent="testInvoice2()" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">Test faktur</button> -->
                                        <!-- <button type="button" @click.prevent="removeTestInvoice()" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">U. test. f.</button> -->
                                        <div v-if="checkUnacceptedInvoices" class="flex justify-end items-center w-5 h-5">
                                            <input id="active-select" aria-describedby="active-select" name="active-select" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-gray-400 focus:ring-gray-600" />
                                        </div>
                                    </div>
                                </th>

                            </tr>
                        </thead>
                        <tbody class="divide-y divide-slate-600 bg-slate-500">
                            <tr v-for="invoice in invoicesList" :key="invoice.id">
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-semibold text-slate-100 sm:pl-6 gap-1">
                                    <div class="flex items-center">
                                        <div>{{ invoice.invoiceName }}</div> 
                                        <ExclamationCircleIcon v-if="invoice.paid && (invoice.deposits == null || checkDeposits(invoice))" class="h-5 w-5 mx-1 flex-shrink-0 text-red-500" aria-hidden="true"/>
                                    </div>
                                </td>
                                <td v-tooltip="`${invoice.payerName}`" class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-semibold">{{invoice.payerName.length > 0 ? invoice.payerName.length < 20 ? invoice.payerName : invoice.payerName.substring(0,20) + '...' : '--'}}</td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                    <span class="flex">{{ invoice.period.slice(0, 10) }} 
                                        <ArrowNarrowRightIcon v-if="invoice.period !== 'Brak'" class="h-3.5 w-3.5 mx-1 flex-shrink-0" aria-hidden="true" /> 
                                        {{ invoice.period.slice(13, 24) }}
                                    </span>
                                </td>
                                <td v-if="!invoice.testInvoice" class="whitespace-nowrap px-3 py-4 text-xs" :class="[invoice.firstDownload  ? 'text-yellow-300 font-semibold' : 'text-slate-300 font-bold']">{{ invoice.firstDownload || "Brak" }}</td>
                                <td v-if="invoice.testInvoice" class="whitespace-nowrap px-3 py-4 text-xs"><div class="text-slate-300 opacity-20 font-normal">Nie dotyczy</div></td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs font-bold">
                                    <div v-if="!invoice.paid" class="flex flex-col gap-3">
                                        <button @click="modalData=invoice, modalVisible = true" class="transition ease-in-out duration-300 bg-slate-600 hover:bg-slate-700 text-white font-semibold py-2 px-4 rounded">Zarządzaj</button>
                                        <!-- <button v-if="!invoice.testInvoice" @click="modalData=invoice, modalVisible = true" class="transition ease-in-out duration-300 bg-slate-600 hover:bg-slate-700 text-white font-semibold py-2 px-4 rounded">Zarządzaj</button> -->
                                        <!-- <div v-else class="text-slate-300 opacity-20 font-normal">Nie dotyczy</div> -->
                                    </div>
                                    <div class="flex gap-1 items-center text-green-400" v-else>
                                        Całość
                                        <PencilAltIcon v-tooltip="`Edytuj zapłaconą kwotę.`" @click="modalData=invoice, modalVisible = true" class="hover:text-gray-300 transition-all duration-300 cursor-pointer w-5 h-5" />
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                    <div class="flex">
                                        <a target="_blank" :href="invoice.pdf.link" class="flex justify-center">
                                            <DocumentTextIcon :class="['text-slate-400 hover:text-slate-300', 'mr-3 flex-shrink-0 h-6 w-6', 'transition ease-in-out duration-300 hover:text-blue-300 cursor-pointer' ]"></DocumentTextIcon>
                                        </a>
                                        <a v-if="invoice.overviewPdf.link.length > 0" target="_blank" :href="invoice.overviewPdf.link" class="flex justify-center">
                                            <DocumentReportIcon :class="['text-slate-400 hover:text-slate-300', 'mr-3 flex-shrink-0 h-6 w-6', 'transition ease-in-out duration-300 hover:text-blue-300 cursor-pointer' ]"></DocumentReportIcon></a>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                    <span v-if="invoice.acceptanceLoading">Akceptowanie...</span>
                                    <div class="flex items-center gap-2" v-else>
                                        <TransitionGroup name="fade">
                                        <button @click="acceptInvoice(invoice)" v-if="!invoice.active && !invoice.testInvoice" class="transition ease-in-out duration-300 bg-slate-600 hover:bg-slate-700 text-white font-semibold py-2 px-4 rounded">Akceptuj</button> 
                                        <button v-tooltip="'Usuń fakturę.'" @click.prevent="openDeleteInvoiceModal(invoice)" v-if="!invoice.active && !invoice.testInvoice && !checkUnacceptedInvoices" class="group group-hover:text-red-400 transition ease-in-out duration-300 bg-slate-600 hover:bg-slate-700 text-white font-semibold py-1.5 px-2 rounded">
                                            <TrashIcon class="w-5 h-5 text-gray-200 group-hover:text-red-400 transition-all duration-300" />
                                        </button> 
                                        <button v-tooltip="'Wybierz fakturę.'" v-if="!invoice.active && !invoice.testInvoice && checkUnacceptedInvoices" class="group group-hover:text-red-400 transition ease-in-out duration-300 bg-slate-600 hover:bg-slate-700 text-white font-semibold py-1.5 px-2 rounded">
                                            <div @click="selectUnacceptedInvoice(invoice)" class="flex w-5 h-5 items-center justify-center">
                                                <input v-model="invoice.activeSelected" id="active-select" aria-describedby="active-select" name="active-select" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-gray-400 focus:ring-gray-600" />
                                            </div>
                                        </button> 
                                        </TransitionGroup> 
                                        <div v-if="invoice.testInvoice" class="text-blue-300 font-normal opacity-70">Faktura testowa</div>
                                        <span v-if="invoice.active && !invoice.testInvoice" class="text-green-400 font-bold">Zaakceptowana</span> 
                                        <!-- <span @click.prevent="sendEmail(invoice)" class="cursor-pointer">TEST EMAIL</span> -->
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <EmptyState v-if="invoicesList.length === 0"></EmptyState>
                </div>
                <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false">
                        <button type="button" @click.prevent="loadMoreUsers" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Załaduj więcej..</button>
                </div>
            </div>
        </div>
    </div>
    <ProductStatementModal v-if="productStatementModal" @close="closeProductStatementModal"/>
    <InvoiceStatementModal v-if="invoiceStatementModal" @close="closeInvoiceStatementModal"/>
    <CsvExportModal v-if="csvExportModal" @close="closeCsvExportModal"/>
    <transition name="fade">
      <div v-if="this.invoicesGenerating">
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gradient-to-r from-slate-600 via-slate-700 to-slate-600 opacity-85 flex flex-col items-center justify-center">
            <h2 class="text-center text-slate-200 text-sm font-normal">Trwa generowanie faktur</h2>
            <p class="w-1/3 text-center mb-2.5 text-xs text-red-400 opacity-60">Nie wyłączaj tej strony do momentu zapełnienia paska progresu.</p>
            <div class="w-1/4 bg-gray-200 rounded-full h-2.5 relative">
                <div v-tooltip="'Umowy własne'" :class="'z-20'" class="bg-blue-500 h-2.5 rounded-full transition-all duration-1000 absolute" :style="`width: ${invoiceGenerateProgress}%`"></div>
            </div>
            <p class="w-1/3 mt-1 text-center text-xs text-slate-400 opacity-60">{{invoiceGenerateProgress.toFixed(2)}}%</p>
            <p class="w-1/3 text-center text-xxs font-medium mt-4 text-red-500 opacity-60">W przypadku wyłączenia tej podstrony, bądź utraty połączenia z internetem generowanie faktur zostanie przerwane i nie będzie możliwości ponownego wygenerowania faktur.</p>
            <p class="w-1/3 text-center text-xxs font-medium text-red-500 opacity-60">W takim przypadku zgłoś się do administratora systemu.</p>
        </div>
      </div>
    </transition>
</div>
<acceptInvoceModal v-if="this.acceptInvoiceModal" @accept="acceptAllUnacceptedInvoices()" :modalData="this.modalDataUnacceptedInvoices" @closeModal="this.closeAcceptInvoiceModal" :iconColor="`green`" :buttonColor="`green`"></acceptInvoceModal>
<deleteInvoiceModal v-if="this.deleteInvoiceModal" @accept="deleteInvoice(this.modalDataDeleteSingleInvoice.invoice)" :modalData="modalDataDeleteSingleInvoice" @closeModal="closeDeleteInvoiceModal()" :iconColor="`indigo`" :buttonColor="`indigo`" />
</template>

<script>
import EmptyState from '../components/EmptyState.vue'
import { HomeIcon, DownloadIcon, ArrowNarrowRightIcon, ChevronDownIcon, TrashIcon, ExclamationCircleIcon } from '@heroicons/vue/solid'
import { FilterIcon,DocumentTextIcon,DocumentReportIcon, PencilAltIcon } from '@heroicons/vue/outline'
import {db} from "@/firebase/gfbconf.js";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import InvoicePaymentModal from '../components/InvoicePaymentModal.vue'
import InvoiceStatementModal from '../components/InvoiceStatementModal.vue'
import ProductStatementModal from '../components/ProductStatementModal.vue'
import CsvExportModal from '../components/CsvExportModal.vue'
import acceptInvoceModal from '@/components/AlertModal.vue';
import deleteInvoiceModal from '@/components/AlertModal.vue';
import axios from 'axios';
import moment from 'moment'

export default {
    name: "invoices",
    data(){
        return{
            checkUnacceptedInvoices: false,
            invoicesToDelete: [],
            deleteInvoiceModal: false,
            invoiceGenerateProgress: 0,
            invoicesGenerating: false,
            testInvoicesDeleting: false,
            modalVisible: false,
            invoicesList: [],
            dbListener: null,
            query: null,
            queryLoading: false,
            queryLimit: 20,
            queryLimitReached: false,
            modalData: null,
            companies: [],
            showFilters: false,
            paidStatus: "all",
            filterCompanyId: "all",
            invoiceStatementModal: false,
            productStatementModal: false,
            csvExportModal: false,
            lockGenerateInvoice: false,
            acceptInvoiceModal: false,
            invoicesToAccept: [],
            modalDataUnacceptedInvoices: {
                headerText: "Akceptowanie wszystkich faktur",
                bodyText: `Czy na pewno chcesz zaakceptować [?] faktur?`,
                acceptButtonText: "Zaakceptuj"
            },
            modalDataDeleteSingleInvoice: {
                headerText: "Usuwanie faktury",
                bodyText: `Czy na pewno chcesz usunąć wybraną fakturę?`,
                acceptButtonText: "Usuń",
                invoice: null
            },
        }
    },
    components: {
        deleteInvoiceModal, 
        HomeIcon, PencilAltIcon, TrashIcon,
        DownloadIcon,
        ArrowNarrowRightIcon,
        ChevronDownIcon,
        FilterIcon,
        EmptyState,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        InvoicePaymentModal,
        InvoiceStatementModal,
        ProductStatementModal,
        CsvExportModal,
        DocumentTextIcon,
        DocumentReportIcon,
        acceptInvoceModal,
        ExclamationCircleIcon
    },
    methods: {
        async getInvoicesTest() {
            const invoices = await db.collection('Invoices').where('created.year' , '==', 2024).get()
            for(let i = 0; i < invoices.docs.length; i++) {
                let invoiceData = invoices.docs[i].data()

                let monthFromPeriod = invoiceData.period.slice(3, 5)
                let yearFromPeriod = invoiceData.period.slice(6, 10)

                db.collection('Invoices').doc(invoices.docs[i].id).update({
                    'invoiceDeliveryDateDetails.month': moment(monthFromPeriod, 'MM').format('MMMM'),
                    'invoiceDeliveryDateDetails.year': parseInt(yearFromPeriod)
                })
            }
        },
        async getShellTransactions() {
            const auth = "Basic clVGTGhsU0dsZEQ1M2Z4cUM2MUFHTkNtd3dBSGxEam06Y0FMUjlGTmJRelpHdTBOOQ=="
            const apiKey = "rUFLhlSGldD53fxqC61AGNCmwwAHlDjm"
            const payer = "PL60043902"
            const contentType = "application/json"


            const liveUrl = "https://api.shell.com/fleetmanagement/v1/transaction/pricedtransactions"
            const getUsers = await db.collection('UsersData').where('activeInvoices', '==', true).get()
            const usersData = []
            for(let i = 0; i < getUsers.docs.length; i++) {
                let userData = getUsers.docs[i].data()
                userData.id = getUsers.docs[i].id
                usersData.push(userData)
            }

            const invoices = await db.collection('Invoices').where('created.year' , '==', 2024).get()
            for(let i = 0; i < usersData.length; i++) {
                let userData = usersData[i]
                const body = {
                    "ColCoCode": 37,
                    "PayerNumber": payer,
                    "InvoiceStatus": "A",
                    "Fromdate":  "20240601",
                    "ToDate": "20240630",
                    "CardGroupId": userData.groupOfCardsId,
                    "IncludeFees": true,
                    "SortOrder": "2",
                    "PageSize": -1
                }
                const res = await axios.post(liveUrl, body, {
                    headers: {
                        'Authorization': auth,
                        'apikey': apiKey,
                        'Content-Type': contentType
                    }
                }); 
                for(let y = 0; y < invoices.docs.length; y++) {
                    let invoiceData = invoices.docs[y].data()
                    if(userData.id === invoiceData.payerId) {
                        for(let z = 0; z < invoiceData.transactions.length; z++) {
                            let transaction = invoiceData.transactions[z]
                            if(res.data != null && res.data.Transactions != null) {
                                for(let x = 0; x < res.data.Transactions.length; x++) {
                                    let shellTransaction = res.data.Transactions[x]
                                    if(transaction.uniqueTransactionId === shellTransaction.TrnIdentifier) {
                                        transaction.UnitPriceInInvoiceCurrency = shellTransaction.UnitPriceInInvoiceCurrency
                                        transaction.SiteCode = shellTransaction.SiteCode
                                        transaction.SiteGroupName = shellTransaction.SiteGroupName
                                    }
                                }
                            }
                        }
                        if(moment(invoiceData.invoiceDeliveryDate, "DD.MM.YYYY").format('MMMM') === 'June') {
                            console.log(invoiceData.invoiceName);
                            db.collection('Invoices').doc(invoices.docs[y].id).update({
                                transactions: invoiceData.transactions,
                                'invoiceDeliveryDateDetails.month': moment(invoiceData.invoiceDeliveryDate, "DD.MM.YYYY").format('MMMM'),
                                'invoiceDeliveryDateDetails.year': moment(invoiceData.invoiceDeliveryDate, "DD.MM.YYYY").year()
                            })
                        }
                    }
                }
                console.log("User"+i+'/'+usersData.length);
            }
        },
        goToCreateInvoice(){
            this.$router.push('/dashboard/createInvoice')
        },
        checkDeposits(invoice) {
            let totalAmount = 0;
            for(let i = 0; i < invoice.deposits.length; i++) {
                totalAmount += parseFloat(invoice.deposits[i].amount.toFixed(2));
            }
            return parseFloat(totalAmount.toFixed(2)) != invoice.paidAmount;
        },
        async deleteInvoice(invoice)
        {
            try {
                let str = invoice.invoiceName;
                if (str.startsWith('FA'))
                {
                    let num = invoice.invoiceName.indexOf("/");
                    let invoiceNum = parseInt(str.slice(2, num))

                    let lastNum = invoice.invoiceName.lastIndexOf("/");
                    let twoDigitYear = str.substring(lastNum + 1);
                    let fourDigitYear = moment(twoDigitYear, 'YY').format('YYYY');

                    const numbers = (await db.collection('InvoiceGenerator').doc('invoiceNumber').get()).data();
                    
                    if((numbers[fourDigitYear] - invoiceNum) === 1)
                    {
                        const res = await axios.post(`${this.$store.state.apiLink}/invoice/delete`, {
                            type: 'FA',
                            year: fourDigitYear,
                            invoice: invoice,
                            latest: true
                        })
                        if(res.data.success)
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Faktura usunięta.",
                                subheader: `Faktura ${invoice.invoiceName} została usunięta.`,
                                success: true
                            }); 
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Błąd usuwania faktury.",
                                subheader: `Faktura ${invoice.invoiceName} nie została usunięta.`,
                                success: false
                            });
                        }
                    }
                    else
                    {
                        const res = await axios.post(`${this.$store.state.apiLink}/invoice/delete`, {
                            type: 'FA',
                            invoice: invoice,
                            latest: false
                        })
                        if(res.data.success)
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Faktura usunięta.",
                                subheader: `Faktura ${invoice.invoiceName} została usunięta.`,
                                success: true
                            }); 
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Błąd usuwania faktury.",
                                subheader: `Faktura ${invoice.invoiceName} nie została usunięta.`,
                                success: false
                            });
                        }
                    }
                }
                else if(str.startsWith('KOR')) {
                    let num = invoice.invoiceName.indexOf("/");
                    let invoiceNum = parseInt(str.slice(3, num))

                    let lastNum = invoice.invoiceName.lastIndexOf("/");
                    let twoDigitYear = str.substring(lastNum + 1);
                    let fourDigitYear = moment(twoDigitYear, 'YY').format('YYYY');
                    const numbers = (await db.collection('InvoiceGenerator').doc('invoiceNumber').get()).data();

                    if((numbers['K'+fourDigitYear] - invoiceNum) === 1)
                    {
                        const res = await axios.post(`${this.$store.state.apiLink}/invoice/delete`, {
                            type: 'KOR',
                            year: 'K'+fourDigitYear,
                            invoice: invoice,
                            latest: true
                        })
                        if(res.data.success)
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Faktura usunięta.",
                                subheader: `Faktura ${invoice.invoiceName} została usunięta.`,
                                success: true
                            }); 
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Błąd usuwania faktury.",
                                subheader: `Faktura ${invoice.invoiceName} nie została usunięta.`,
                                success: false
                            });
                        }
                    }
                    else
                    {
                        const res = await axios.post(`${this.$store.state.apiLink}/invoice/delete`, {
                            type: 'KOR',
                            invoice: invoice,
                            latest: false
                        })
                        if(res.data.success)
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Faktura usunięta.",
                                subheader: `Faktura ${invoice.invoiceName} została usunięta.`,
                                success: true
                            }); 
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Błąd usuwania faktury.",
                                subheader: `Faktura ${invoice.invoiceName} nie została usunięta.`,
                                success: false
                            });
                        }
                    }
                }

            } catch (error)
            {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Błąd usuwania faktury.",
                    subheader: `Faktura ${invoice.invoiceName} nie została usunięta.`,
                    success: false
                });  
            }
        },
        // async deleteInvoice(id){
        //     await db.collection('Invoices').doc(id).delete()
        // },
        // async testInvoice2(){
        //         this.invoicesGenerating = true
        //         let usersData = this.companies
        //         let groupsOfUsers = []
        //         const size = 1; 
        //         for (let i=0; i<usersData.length; i+=size) {
        //             groupsOfUsers.push(usersData.slice(i,i+size));
        //         }
        //         for (let y = 0; y < groupsOfUsers.length; y++) {
        //             const group = groupsOfUsers[y];
        //             const res = await axios.post(`${this.$store.state.apiLink}/generateInvoicesOnMyDate`, {
        //                 users: group
        //             })
        //             if(res.data.success)
        //             {
        //                 this.invoiceGenerateProgress = Math.round((((y+1)*100/groupsOfUsers.length) + Number.EPSILON) * 100) / 100
        //             }else{
        //                 alert('Nie udało się wygenerować części faktur - sprawdź konsole')
        //                 console.log(group)
        //             }
        //         }

        //         this.invoiceGenerateProgress = 0
        //         this.invoicesGenerating = false

        //         // const res = await axios.post(`${this.$store.state.apiLink}/generateInvoices`, {
        //         //     users: users
        //         // })
        //         // if(res.data.success)
        //         // {
        //         //     this.invoicesGenerating = false
        //         // }


        // },
        acceptAllUnacceptedInvoices(){
            // for (let i = 0; i < this.invoicesToAccept.length; i++) {
            //     const invoice = this.invoicesToAccept[i];
            //     this.acceptInvoice(invoice)
            // }
            this.acceptInvoices(this.invoicesToAccept)
        },
        async checkPeriod(){
            const currentDay = moment().date()
            let FromDate = null
            let ToDate = null
            let period = null
            if(currentDay >= 1 && currentDay <= 7){
                FromDate = moment().subtract(1, 'months').format("YYYYMM") + "24"
                let lastDayOfMonth = moment().subtract(1, 'months').endOf('month').format("DD")
                ToDate = moment().subtract(1, 'months').format("YYYYMM") + `${lastDayOfMonth}`
                period = moment(FromDate, 'YYYYMMDD').format("DD-MM-YYYY") + ' - ' +  moment(ToDate, 'YYYYMMDD').format("DD-MM-YYYY")
            }
            if(currentDay >= 8 && currentDay <= 16){
              FromDate = moment().format("YYYYMM") + "01"
              ToDate = moment().format("YYYYMM") + "07"
              period = moment(FromDate, 'YYYYMMDD').format("DD-MM-YYYY") + ' - ' +  moment(ToDate, 'YYYYMMDD').format("DD-MM-YYYY")
            }
            if(currentDay >= 17 && currentDay <= 23){
              FromDate = moment().format("YYYYMM") + "08"
              ToDate = moment().format("YYYYMM") + "16"
              period = moment(FromDate, 'YYYYMMDD').format("DD-MM-YYYY") + ' - ' +  moment(ToDate, 'YYYYMMDD').format("DD-MM-YYYY")
            }
            if(currentDay >= 24 && currentDay <= 31){
              FromDate = moment().format("YYYYMM") + "17"
              ToDate = moment().format("YYYYMM") + "23"
              period = moment(FromDate, 'YYYYMMDD').format("DD-MM-YYYY") + ' - ' +  moment(ToDate, 'YYYYMMDD').format("DD-MM-YYYY")
            }
            let result = await db.collection("Invoices").where('period', "==", period).get()
            if(result.empty){
                this.lockGenerateInvoice = false
            }else{
                this.lockGenerateInvoice = true
            }
        },
        async downloadUnAcceptedInvoices(){
            //download all invoices that are not accepted

            this.modalDataUnacceptedInvoices.bodyText = `Ładowanie`
            let refreshIntervalId = setInterval(() => {
                if(this.modalDataUnacceptedInvoices.bodyText.length >= 12){
                    this.modalDataUnacceptedInvoices.bodyText = `Ładowanie`
                }
                this.modalDataUnacceptedInvoices.bodyText = this.modalDataUnacceptedInvoices.bodyText + '.'
            }, 500);;

            let result = await db.collection("Invoices").where('active', "==", false).where('testInvoice', "==", false).get()
            result.forEach(doc => {
                let data = doc.data()
                data.id = doc.id
                this.invoicesToAccept.push(data)
            })
            clearInterval(refreshIntervalId);
            if(this.invoicesToAccept.length > 0){
                this.modalDataUnacceptedInvoices.bodyText = `Czy na pewno chcesz zaakceptować ${this.invoicesToAccept.length} faktur?`
            }else{
            this.modalDataUnacceptedInvoices.bodyText = `Brak faktur do zaakceptowania`
            }
        },
        openAcceptInvoiceModal(){
            this.downloadUnAcceptedInvoices()
            this.acceptInvoiceModal = true
        },
        openDeleteInvoiceModal(invoice)
        {
            this.deleteInvoiceModal = true
            this.modalDataDeleteSingleInvoice.invoice = invoice;
        },
        closeAcceptInvoiceModal(){
            this.acceptInvoiceModal = false
            this.invoicesToAccept = []
        },
        closeDeleteInvoiceModal(){
            this.deleteInvoiceModal = false
            this.modalDataDeleteSingleInvoice.invoice = null;
            // this.invoicesToAccept = []
        },
        closeProductStatementModal(){
            this.productStatementModal = false
        },
        openProductStatementModal(){
            this.productStatementModal = true
        },
        closeInvoiceStatementModal(){
            this.invoiceStatementModal = false
        },
        openInvoiceStatementModal(){
            this.invoiceStatementModal = true
        },
        closeCsvExportModal(){
            this.csvExportModal = false
        },
        openCsvExportModal(){
            this.csvExportModal = true
        },
        async sendEmail(invoice){
            let user = await db.collection("UsersData").doc(invoice.payerId).get()
            let userData = user.data()
            if(user.exists){
                let res = await axios.post(`${this.$store.state.apiLink}/sendEmail`, {
                    paymentDate: invoice.paymentDate,
                    company: userData.company.name,
                    email: userData.contact.invoiceEmail.length > 0 ? userData.contact.invoiceEmail : userData.contact.email
                })
            }else{
                alert('Nie mogę pobrać e-maila uzytkownika.')
            }
            // let res = await axios.post(`${this.$store.state.apiLink}/sendEmail`, {
            //         paymentDate: invoice.paymentDate,
            //     })
            // console.log(res)
        },
        selectUnacceptedInvoice(invoice)
        {
            if(this.invoicesToDelete.includes(invoice))
            {
                this.invoicesToDelete = this.invoicesToDelete.filter(item => item !== invoice);
            }
            else
            {
                this.invoicesToDelete = [...this.invoicesToDelete, invoice];
            }
        },
        selectUnacceptedInvoices()
        {
            this.invoicesToDelete = [];
            this.checkUnacceptedInvoices = !this.checkUnacceptedInvoices;
            // this.downloadInvoices()
        },
        log(content){
            console.log(content)
        },
        updateFilters(){
            this.dbListener()
            this.downloadInvoices()
        },
        toggleFilters(){
            this.showFilters = !this.showFilters
            if(!this.showFilters){
                this.paidStatus = "all",
                this.filterCompanyId = "all"
                this.updateFilters()
            }
        },
        async getUsers(){
            let users = await db.collection("UsersData").orderBy("company.name",'asc').get()
            users.forEach(user => {
                let userData = user.data()
                this.companies.push(userData)
            });
        },
        async testInvoice(){
            if(this.invoicesGenerating === false && this.lockGenerateInvoice === false){
                this.invoicesGenerating = true
                let usersData = this.companies
                let groupsOfUsers = []
                const size = 1; 
                for (let i=0; i<usersData.length; i+=size) {
                    groupsOfUsers.push(usersData.slice(i,i+size));
                }
                for (let y = 0; y < groupsOfUsers.length; y++) {
                    const group = groupsOfUsers[y];
                    const res = await axios.post(`${this.$store.state.apiLink}/generateInvoices`, {
                        users: group
                    })
                    if(res.data.success)
                    {
                        this.invoiceGenerateProgress = Math.round((((y+1)*100/groupsOfUsers.length) + Number.EPSILON) * 100) / 100
                    }else{
                        alert('Nie udało się wygenerować części faktur - sprawdź konsole')
                        console.log(group)
                    }
                }

                this.invoiceGenerateProgress = 0
                this.invoicesGenerating = false

                // const res = await axios.post(`${this.$store.state.apiLink}/generateInvoices`, {
                //     users: users
                // })
                // if(res.data.success)
                // {
                //     this.invoicesGenerating = false
                // }

            }
        },
        async removeTestInvoice(){
            if(this.testInvoicesDeleting === false){
                this.testInvoicesDeleting = true
                const res = await axios.post(`${this.$store.state.apiLink}/removeTestInvoices`, {
                })
                if(res.data.success)
                {
                    this.testInvoicesDeleting = false
                }
            }
        },
        async downloadInvoices() {
            if(this.dbListener !== null)
            {
                this.dbListener();
            }  
            this.query = db.collection("Invoices")
            if(this.paidStatus !== "all"){
                if(this.paidStatus === "paid"){
                    this.query = this.query.where('paid', "==", true)
                }
                if(this.paidStatus === "notPaid"){
                    this.query = this.query.where('paid', "==", false)
                }
            }
            if(this.filterCompanyId !== "all"){
                    this.query = this.query.where('payerId', "==", this.filterCompanyId)
            }
            this.query = this.query.orderBy("created.unix", "desc")
            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.queryLoading = true;
                this.invoicesList = [];
                if(querySnapshot.docs.length<this.queryLimit) {
                        this.queryLimitReached = true;
                }
                else {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id;
                    data.activeSelected = false;
                    this.invoicesList.push(data)
                });
                this.queryLoading = false;
            });
        },
        async acceptInvoice(invoice){
            const doc = await db.collection("Invoices").doc(invoice.id);

            await doc.update({
                active: true
            })
            this.sendEmail(invoice)
        },
        async acceptInvoices(invoices) {
            let res = await axios.post(`${this.$store.state.apiLink}/acceptAllInvoices`, {
                invoices: invoices,
            })
        },
        async updatePaidAmount(data){
            const docRef = db.collection("Invoices").doc(data.invoiceId);

            let deposits = data.deposits
            let paidAmount = 0;

            if((parseFloat(data.paidAmount.toFixed(2)) > 0 || parseFloat(data.paidAmount.toFixed(2)) < 0) && data.paidDate != null && typeof data.paidDate == 'object') {
                deposits.push({
                    amount: parseFloat(data.paidAmount.toFixed(2)),
                    date: moment(data.paidDate).startOf('day').unix()
                })
            }
            let paidAfterDueDate = false;
            for(let i = 0; i < deposits.length; i++) {
                paidAmount += deposits[i].amount;
                if(deposits[i].date > moment(data.paymentDate, 'DD.MM.YYYY').startOf('day').unix()) paidAfterDueDate = true;
            }
            console.log(parseFloat(paidAmount.toFixed(2)));
            await docRef.update({
                paidAmount: parseFloat(paidAmount.toFixed(2)),
                paid: parseFloat(paidAmount.toFixed(2)) == data.valueOfInvoice ? true : false,
                paidAfterDueDate: paidAfterDueDate,
                paidDate: (data.paidDate == null || data.paidDate.length === 0) && parseFloat(paidAmount.toFixed(2)) != data.valueOfInvoice  ? null : moment(data.paidDate).startOf('day').unix(),
                deposits
            })

        },
        loadMoreUsers() {
            this.dbListener();
            this.continueListening();  
        },
        continueListening() {
            this.queryLimit = this.queryLimit + 10;

            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.queryLoading = true;
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }

                this.invoicesList = [];

                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id;

                    this.invoicesList.push(data)
                });
                this.queryLoading = false;
            });
        }
    },
    mounted(){
        this.downloadInvoices()
        this.getUsers()
        this.checkPeriod()
    },
    beforeUnmount() {
        if(this.dbListener !== null)
        {
            this.dbListener();
        }  
    }
}

</script>
<style lang="scss" scoped>
    .fade-enter-active{
    transition: opacity 1s ease;
    }
    .fade-leave-active{
        transition: opacity 0s;
        display: none;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>