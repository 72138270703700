<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
            <div>
                <div class="sm:hidden">
                <label for="tabs" class="sr-only">Select a tab</label>
                <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                    <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
                </select>
                </div>
                <div class="hidden sm:block">
                <nav class="isolate flex divide-x divide-gray-200 rounded-t-lg shadow" aria-label="Tabs">
                    <div @click="openTab(tabIdx)" v-for="(tab, tabIdx) in tabs" :key="tab.name" :class="[tab.current ? 'text-gray-900' : 'text-gray-500  hover:text-gray-700 cursor-pointer', tabIdx === 0 ? '' : '', tabIdx === tabs.length - 1 ? '' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10']">
                    <span>{{ tab.name }}</span>
                    <span aria-hidden="true" :class="[tab.current ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                    </div>
                </nav>
                </div>
            </div>
            <div v-if="this.tabs[0].current" class="overflow-auto h-96">
                <table class="min-w-full divide-y  divide-gray-300">
                    <thead class="shadow">
                    <tr>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Numer faktury</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Płatne do</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Należności</th>
                        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                        <span class="sr-only">Edit</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody class="bg-white">
                    <tr v-for="(invoice, invoiceIdx) in this.invoicesData.invoices" :key="invoice" :class="invoiceIdx % 2 === 0 ? undefined : 'bg-gray-50'">
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{{ invoice.invoiceName }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ invoice.paymentDate }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ (invoice.valueOfInvoice - invoice.paidAmount).toFixed(2) }} PLN</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div v-if="!invoice.paid && !checkDeadline(invoice.paymentDate)" class="flex flex-col items-center">
                                <button type="button" @click.prevent="askForSendPaymentReminder = true, paymentReminderInvoice = invoice" class="rounded w-full bg-blue-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500">Przypomnij</button>
                                <span class="text-xxs"> przekroczy {{checkWhenPayment(invoice.paymentDate)}}</span>
                            </div>
                            <div v-if="!invoice.paid && checkDeadline(invoice.paymentDate)" class="flex flex-col">
                                <span class="text-red-500 text-xs">Przekroczono</span>
                                <span class="text-red-500 text-xs">termin płatności</span>
                            </div>
                            <!-- <button v-if="!invoice.paid" type="button" class="rounded bg-blue-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500">Przypomnij</button> -->
                            <span v-if="invoice.paid" class="text-green-500">Opłacona</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <EmptyState v-if="this.invoicesData.invoices.length === 0"></EmptyState>
            </div>
            <div v-if="this.tabs[1].current" class="overflow-auto h-96">
                <!-- {{this.notificationsData}} -->
                <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Data</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Zawarte faktury</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Wartość</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Typ</th>
                        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                        <span class="sr-only">Edit</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody class="bg-white">
                    <tr v-for="(notification, notificationIdx) in this.notificationsData" :key="notification" :class="notificationIdx % 2 === 0 ? undefined : 'bg-gray-50'">
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-3">{{ this.translateUnix(notification.created.unix)}}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">{{ notification.attachedInvoices[0].invoiceName }}<span v-tooltip="`${returnNames(notification.attachedInvoices)}`" v-if="notification.attachedInvoices.length > 1" class="ml-2 text-xxs text-blue-400 cursor-default">+{{notification.attachedInvoices.length - 1}} więcej</span></td>
                        <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">{{ notification.attachedInvoicesValue.toFixed(2) }} PLN</td>
                        <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                            <span v-if="notification.type === 'warning'" v-tooltip="`Upomnienie`" class="inline-flex cursor-default items-center rounded-md bg-red-100 px-1.5 py-0.5 text-xs font-medium text-red-700">U</span>
                            <span v-if="notification.type === 'reminder'" v-tooltip="`Przypomnienie`" class="inline-flex cursor-default items-center rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700">P</span>
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div v-if="notification.emailHtml !== undefined && notification.emailHtml !== null && notification.emailHtml.length > 0" class="flex flex-col items-center">
                                <button type="button" @click.prevent="openMailPreviewModal(notification.emailHtml, notification.created.unix)" class="rounded w-full bg-blue-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500">Podgląd maila</button>
                            </div>
                            <span v-else class="text-red-500">Brak danych</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <EmptyState v-if="this.notificationsData.length === 0"></EmptyState>
            </div>
            <div v-if="this.invoicesData.debt < 0 || this.invoicesData.debt > 0 && this.tabs[0].current" class="w-full bg-red-50 border-t border-red-100 px-4 py-2 flex justify-between items-center rounded-b-md">
                <span class="text-sm">Należności opóźnione: <span class="text-red-500 font-medium">{{this.invoicesData.debt.toFixed(2)}} PLN</span></span>
                <button @click.prevent="askForSendPaymentWarning = true" type="button" class="ml-2 rounded bg-red-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500">Upomnij</button>
            </div>

            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Zamknij</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <askFor v-if="askForSendPaymentReminder" @accept="sendPaymentReminder(this.paymentReminderInvoice)" :modalData="modalSendPaymentReminder" @closeModal="askForSendPaymentReminder = false, paymentReminderInvoice = null"></askFor>
  <askFor v-if="askForSendPaymentWarning" @accept="sendPaymentWarning()" :modalData="modalSendPaymentWarning" @closeModal="askForSendPaymentWarning = false"></askFor>
  <mailPreview v-if="showMailModal" :modalData="mailModalData" :mailDate="mailDateModalData" @close="closeMailPreviewModal"></mailPreview>
</template>

<script>
import { ref } from 'vue'
import {db} from "@/firebase/gfbconf.js";
import axios from 'axios';
import moment from 'moment'
import EmptyState from '@/components/EmptyState.vue';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import askFor from '@/components/AlertModal.vue';
import mailPreview from '@/components/MailPreviewModal.vue';
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    EmptyState,
    askFor,
    mailPreview
  },
  props: ["modalData", "modalNotificationsData"],
  data() {
    return {
        tabs: [
            { name: 'Faktury', current: true },
            { name: 'Przesłane upomnienia', current: false },
        ],
        notificationsData: null,
        invoicesData: {},
        askForSendPaymentReminder: false,
        paymentReminderInvoice: null,
        modalSendPaymentReminder: {
            headerText: "Przesyłanie powiadomienia",
            bodyText: "Czy na pewno chcesz przesłać klientowi przypomnienie o płatności za tą fakturę?",
            acceptButtonText: "Akceptuje"
        },
        askForSendPaymentWarning: false,
        modalSendPaymentWarning: {
            headerText: "Przesyłanie upomnienia",
            bodyText: `Czy na pewno chcesz przesłać klientowi upomnienie za wszystkie nieopłacone w terminie faktury?`,
            acceptButtonText: "Akceptuje"
        },
        showMailModal: false,
        mailModalData: null,
        mailDateModalData: null
    }
  },
  methods: {
    returnNames(invoices){
        let txt = ``
        for (let i = 0; i < invoices.length; i++) {
            txt = txt + `${i > 0 ? ' | ' : ''}` + invoices[i].invoiceName + ` (` +invoices[i].invoiceDebt.toFixed(2) + ` PLN)`
        }
        return txt
    },
    translateUnix(unix){
        moment.locale('pl')
        let translated = moment.unix(unix).format("lll");
        return translated
    },
    openTab(indx){
        for (let i = 0; i < this.tabs.length; i++) {
            this.tabs[i].current = false
        }
        this.tabs[indx].current = true
    },
    async sendPaymentReminder(invoice){
            let user = await db.collection("UsersData").doc(invoice.payerId).get()
            let userData = user.data()
            if(user.exists){
                let res = await axios.post(`${this.$store.state.apiLink}/sendPaymentReminder`, {
                        paymentDate: invoice.paymentDate,
                        company: userData.company.name,
                        email: userData.contact.invoiceEmail.length > 0 ? userData.contact.invoiceEmail : userData.contact.email,
                        invoiceName: invoice.invoiceName,
                        valueOfInvoice: invoice.valueOfInvoice,
                        userId: userData.id
                    })
                if(res.data !== undefined && res.data.status !== undefined && res.data.status === "OK"){
                    this.paymentReminderInvoice = null
                    this.close()
                }else{
                    alert('Nie udało się przesłać przypomnienia. Skontaktuj się z administratorem.')
                    this.paymentReminderInvoice = null
                }
            }else{
                alert('Nie mogę pobrać e-maila uzytkownika.')
                this.paymentReminderInvoice = null
            }
        },
    async sendPaymentWarning(){
            let user = await db.collection("UsersData").doc(this.invoicesData.delayedInvoices[0].payerId).get()
            let userData = user.data()
            let preparedInvoices = []
            for (let i = 0; i < this.invoicesData.delayedInvoices.length; i++) {
                let crntInvoice = this.invoicesData.delayedInvoices[i]
                let simpleInvoice = {
                    invoiceName: crntInvoice.invoiceName,
                    paymentDate: crntInvoice.paymentDate,
                    invoiceDebt: crntInvoice.valueOfInvoice - crntInvoice.paidAmount
                }
                preparedInvoices.push(simpleInvoice)
            }
            if(user.exists){
                let res = await axios.post(`${this.$store.state.apiLink}/sendPaymentWarning`, {
                        company: userData.company.name,
                        email: userData.contact.invoiceEmail.length > 0 ? userData.contact.invoiceEmail : userData.contact.email,
                        debtInvoices: preparedInvoices,
                        debtOfInvoices: this.invoicesData.debt,
                        userId: userData.id
                    })
                if(res.data !== undefined && res.data.status !== undefined && res.data.status === "OK"){
                    this.paymentReminderInvoice = null
                    this.close()
                }else{
                    alert('Nie udało się przesłać przypomnienia. Skontaktuj się z administratorem.')
                    this.paymentReminderInvoice = null
                }
            }else{
                alert('Nie mogę pobrać e-maila uzytkownika.')
                this.paymentReminderInvoice = null
            }
        },
    checkDeadline(date){
        let today = moment().format('YYYY-MM-DD')
        let exceeded = moment(date, 'DD.MM.YYYY').endOf('day').isBefore(today, 'day')
        return exceeded
    },
    checkWhenPayment(date){
        moment.locale('pl')
        let payment = moment(date, "DD.MM.YYYY").endOf('day').fromNow();
        return payment
    },
    watchForData(){
        if(this.modalData !== undefined && this.modalData !== null){
            this.invoicesData = this.modalData
            // this.invoicesData.invoices.reverse()
        }else{
            setTimeout(() => {
            this.watchForData()
        }, 100);
        }
    },
    watchForNotificationsData(){
        if(this.modalNotificationsData !== undefined && this.modalNotificationsData !== null){
            this.notificationsData = this.modalNotificationsData
            // this.invoicesData.invoices.reverse()
        }else{
            setTimeout(() => {
            this.watchForNotificationsData()
        }, 100);
        }
    },
    log(log){
        console.log(log)
    },
    emitClose()
    {
        this.$emit("close");
    },
    close()
    {
        this.emitClose();
        this.open = false
    },
    closeMailPreviewModal(){
        this.showMailModal = false
        this.mailModalData = null
        this.mailDateModalData = null
    },
    openMailPreviewModal(data,date){
        this.showMailModal = true
        this.mailModalData = data
        this.mailDateModalData = date
    },
  },
  computed: {
  },
  created(){
    this.watchForData()
    this.watchForNotificationsData()
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  }
}
</script>

<style scoped>


</style>
