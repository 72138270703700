<template>
<div>
    <div class="flex justify-between">
        <nav class="hidden sm:flex h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-slate-500 px-6 shadow">
                <li class="flex">
                    <div class="flex items-center">
                    <router-link to="/hub" class="transition ease-in-out duration-300 text-slate-100 hover:text-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                        <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </router-link>
                    </div>
                </li>
                <li class="flex">
                    <div class="flex items-center">
                    <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <div class="ml-4 text-xs font-medium text-slate-100 opacity-60 cursor-default select-none" aria-current="page">Wysyłka e-maili</div>
                    </div>
                </li>
            </ol>
            <!-- <div @click.prevent="testInvoice()" class="ml-4 cursor-pointer flex items-center justify-center px-4 bg-red-200">TEST FAKTUR</div> -->
        </nav>
        <div class="flex items-center sm:hidden">
            <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 text-sm font-medium text-slate-300 hover:text-slate-400" aria-current="page">&larr; Cofnij</router-link>
        </div>
    </div>
    <div class="mt-4">
        <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
            <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select>
        </div>
        <div class="hidden sm:block">
        <div class="border-b border-gray-400">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <a @click="openTab(tabIdx)" v-for="(tab, tabIdx) in tabs" :key="tab.name" :href="tab.href" :class="[tab.current ? 'border-white text-white' : 'border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-300', 'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium']" :aria-current="tab.current ? 'page' : undefined">
                <component :is="tab.icon" :class="[tab.current ? 'text-white' : 'text-gray-400 group-hover:text-gray-300', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
                <span>{{ tab.name }}</span>
            </a>
            </nav>
        </div>
        </div>
    </div>
    <div class="w-full mx-auto max-w-3xl lg:max-w-7xl mt-8">
        <h1 class="sr-only">Page title</h1>
        <!-- Main 3 column grid -->
        <Transition name="fade2">
        <div class="flex flex-col md:flex-col lg:flex-row gap-4" v-if="this.tabs[0].current">
            <!-- Left column -->
            <div @click="changeState ? changeState = false : ''" :class="[!changeState ? 'sm:w-full' : 'lg:w-96 cursor-pointer', 'relative']">
                <div v-if="changeState" class="absolute top-0 right-0 bottom-0 left-0 m-auto h-20 w-12 z-50 select-none">
                    <ChevronRightIcon class="w-12 text-white transition-all duration-300" /> 
                    <span class="uppercase -ml-2 text-white font-semibold">Rozwiń</span>
                </div>
                <section aria-labelledby="section-2-title" :class="[changeState ? 'opacity-30 select-none hover:opacity-60' : '', 'transition-all duration-300']">
                    <h2 class="sr-only" id="section-2-title">Section title</h2>
                    <div class="overflow-hidden rounded-lg shadow" :class="[changeState ? 'pointer-events-none' : '']">
                        <div class="max-h-144 overflow-y-scroll">
                            <!-- Your content -->
                            <div class="flex flex-col">
                                <div class="overflow-x-auto">
                                    <div class="inline-block min-w-full align-middle">
                                        <div class="overflow-hidden shadow border border-black border-opacity-5 rounded-b-lg" v-if="!queryLoading">
                                            <table class="min-w-full divide-y divide-slate-700">
                                                <thead class="bg-slate-700">
                                                    <tr>
                                                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold whitespace-nowrap text-slate-100 sm:pl-6">
                                                            <div class="flex">
                                                                ID
                                                                <ChevronDownIcon @click.prevent="sortTable.pickedColumn = null, sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === null && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== null }" aria-hidden="true" />
                                                                <ChevronUpIcon @click.prevent="sortTable.pickedColumn = null, sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === null && sortTable.type === 'desc' || sortTable.pickedColumn !== null" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== null }" aria-hidden="true" />
                                                            </div>
                                                        </th>
                                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                                            <div class="flex">
                                                                Firma
                                                                <ChevronDownIcon @click.prevent="sortTable.pickedColumn = 'businessName', sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === 'businessName' && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'businessName' }" aria-hidden="true" />
                                                                <ChevronUpIcon @click.prevent="sortTable.pickedColumn = 'businessName', sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === 'businessName' && sortTable.type === 'desc' || sortTable.pickedColumn !== 'businessName'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'businessName' }" aria-hidden="true" />
                                                            </div>
                                                        </th>
                                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                                            <div class="flex">
                                                                Imię Nazwisko 
                                                                <ChevronDownIcon @click.prevent="sortTable.pickedColumn = 'name', sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === 'name' && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'name' }" aria-hidden="true" />
                                                                <ChevronUpIcon @click.prevent="sortTable.pickedColumn = 'name', sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === 'name' && sortTable.type === 'desc' || sortTable.pickedColumn !== 'name'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'name' }" aria-hidden="true" />
                                                            </div>
                                                        </th>
                                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">E-mail</th>
                                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Telefon</th>
                                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                                            <div class="flex">
                                                                Grupa kart
                                                                <ChevronDownIcon @click.prevent="sortTable.pickedColumn = 'cardGroup', sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === 'cardGroup' && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'cardGroup' }" aria-hidden="true" />
                                                                <ChevronUpIcon @click.prevent="sortTable.pickedColumn = 'cardGroup', sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === 'cardGroup' && sortTable.type === 'desc' || sortTable.pickedColumn !== 'cardGroup'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'cardGroup' }" aria-hidden="true" />
                                                            </div>
                                                        </th>
                                                        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                            <div class="flex h-6 items-center">
                                                                <input v-model="isChecked" @click="checkUser($event, 'multiple', null)" id="comments" aria-describedby="comments-description" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue-400 focus:ring-blue-500" />
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="divide-y divide-slate-700 bg-slate-500">
                                                    <tr v-for="user in userList" :key="user.id">
                                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                                            <div class="flex items-center text-xs text-slate-100">
                                                                <!-- {{user.id}} -->
                                                                <span v-tooltip="`${user.id}`" @click.prevent="copyToClipboard(user.id)" class="transition ease-in-out duration-300 cursor-pointer text-blue-300 hover:text-blue-400">Kopiuj</span>
                                                            </div>
                                                        </td>
                                                        <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                                            <!-- {{user.name}} {{user.surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                                                <div class="flex items-center">
                                                                    <div v-tooltip="`${user.company.name}`" class="rounded-md focus:outline-none">{{user.company.name.length > 0 ? user.company.name.length < 20 ? user.company.name : user.company.name.substring(0,20) + '...' : '--'}}</div>
                                                                    <div v-if="!user.activeInvoices" v-tooltip="`FAKTUROWANIE TESTOWE`" class="ml-2 cursor-default flex justify-center border-white border px-1 rounded-full text-xs bg-red-500"><span class="text-white pt-px">T</span></div>
                                                                </div>
                                                        </td>
                                                        <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                                            <!-- {{user.name}} {{user.surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                                            <router-link target="_blank" class="cursor-pointer transition-all duration-300 hover:text-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" :to="'/dashboard/user?id=' + user.id">{{user.name}} {{user.surname}}</router-link>
                                                        </td>
                                                        <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 ">
                                                            <a :href="`mailto:${user.contact.email}`" alt="Napisz e-mail" class="cursor-pointer transition-all duration-300 hover:text-indigo-300 underline rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">{{user.contact.email}}</a>
                                                        </td>
                                                        <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                                            <div>
                                                                <a :href="`tel:${user.contact.phone}`" alt="Zadzwoń" class="cursor-pointer transition-all duration-300 hover:text-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 underline">{{user.contact.phone}}</a>
                                                            </div>
                                                        </td>
                                                        <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                                            <!-- {{user.name}} {{user.surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                                            <div class="rounded-md focus:outline-none">{{user.groupOfCards}}</div>
                                                        </td>
                                                        <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 select-none">
                                                            <div class="flex h-6 items-center">
                                                                <input v-model="user.checked" @click="checkUser($event, 'single', user)" id="comments" aria-describedby="comments-description" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue-400 focus:ring-blue-500" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <EmptyState v-if="userList.length === 0"></EmptyState>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            
            <!-- Right column -->
            <div @click.prevent="!changeState ? changeState = true : ''" :class="[!changeState ? 'lg:w-36 cursor-pointer' : 'sm:w-full', 'whitespace-nowrap', 'relative']">
                <div v-if="!changeState" class="absolute top-0 right-0 bottom-0 left-0 m-auto h-20 w-12 z-50">
                    <ChevronLeftIcon class="w-12 text-white hover:text-gray-400 transition-all duration-300 cursor-pointer" /> 
                    <span class="uppercase -ml-2 text-gray-200 font-semibold">Rozwiń</span>
                </div>
                <section aria-labelledby="section-1-title" :class="[!changeState ? 'opacity-20 select-none hover:opacity-60' : '', 'transition-all duration-300']">
                    <h2 class="sr-only" id="section-1-title">Section title</h2>
                    <div class="rounded-lg bg-white shadow" :class="[!changeState ? 'pointer-events-none' : '']">
                        <div class="p-6 h-144 overflow-y-scroll">
                            <!-- Your content -->
                            <div class="space-y-12">
                                <div>
                                    <h2 class="text-base font-semibold leading-7 text-gray-900">Wyślij wiadomość e-mail</h2>
                                    <p class="-mt-1 text-sm leading-6 text-gray-600">Uzupełnij wszystkie pola przed wysłaniem maila.</p>
                                    <div class="relative mt-4">
                                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                            <div class="w-full border-t border-gray-300" />
                                        </div>
                                    </div>
                                    <div class="mt-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                        <div class="col-span-full">
                                            <label for="subject" class="block text-sm leading-6 text-gray-600 font-semibold uppercase">Temat</label>
                                            <div class="col-span-3">
                                                <div class="relative mt-1 rounded-md shadow-sm w-144">
                                                    <input v-model="email.subjectText" type="text" name="subject" id="subject" :class="[errors.subject ? 'text-red-900 focus:ring-red-500 ring-red-300 placeholder:text-red-300' : 'ring-gray-300 placeholder:text-gray-400 text-gray-900 focus:ring-indigo-600', 'block w-full rounded-md border-0 py-1.5 pr-10 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 transition-all duration-300']" />
                                                    <div v-if="errors.subject" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                        <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                                                    </div>
                                                </div>
                                                <Transition name="fade">
                                                <p v-if="errors.subject" class="mt-1 text-xs text-red-600 select-none uppercase font-semibold" id="subject-error">Brak tematu.</p>
                                                </Transition>
                                            </div>
                                        </div>
                                        <div class="sm:col-span-3">
                                            <label for="header" class="block text-sm leading-6 text-gray-600 font-semibold uppercase mb-1">Nagłówek</label>
                                            <div class="relative mt-1 rounded-md shadow-sm w-144">
                                                <input v-model="email.headerText" type="text" name="header" id="header" :class="[errors.header ? 'text-red-900 focus:ring-red-500 ring-red-300 placeholder:text-red-300' : 'ring-gray-300 placeholder:text-gray-400 text-gray-900 focus:ring-indigo-600', 'block w-full rounded-md border-0 py-1.5 pr-10 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 transition-all duration-300']" />
                                                <div v-if="errors.header" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <Transition name="fade">
                                            <p v-if="errors.header" class="mt-1 text-xs text-red-600 select-none uppercase font-semibold" id="header-error">Brak nagłówka.</p>
                                            </Transition>
                                        </div>
                                        <div class="col-span-full">
                                            <label for="body" class="block text-sm leading-6 text-gray-600 font-semibold uppercase mb-1">Treść</label>
                                            <!-- <textarea rows="4" v-model="email.bodyText" name="comment" id="comment" class="block w-144 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" /> -->
                                            <div class="relative mt-1 rounded-md shadow-sm w-144">
                                                <textarea rows="1" v-model="email.bodyText" type="text" name="body" id="body" :class="[errors.body ? 'text-red-900 focus:ring-red-500 ring-red-300 placeholder:text-red-300' : 'ring-gray-300 placeholder:text-gray-400 text-gray-900 focus:ring-indigo-600', 'block w-full rounded-md border-0 py-1.5 pr-10 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 transition-all duration-300']" />
                                                <div v-if="errors.body" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <Transition name="fade">
                                            <p v-if="errors.body" class="mt-1 text-xs text-red-600 select-none uppercase font-semibold" id="body-error">Brak treści.</p>
                                            </Transition>
                                        </div>
                                        <div class="sm:col-span-3">
                                            <label for="footer" class="block text-sm leading-6 text-gray-600 font-semibold uppercase mb-1">Stopka</label>
                                            <div class="relative mt-1 rounded-md shadow-sm w-144">
                                                <input v-model="email.footerText" type="text" name="footer" id="footer" :class="[errors.footer ? 'text-red-900 focus:ring-red-500 ring-red-300 placeholder:text-red-300' : 'ring-gray-300 placeholder:text-gray-400 text-gray-900 focus:ring-indigo-600', 'block w-full rounded-md border-0 py-1.5 pr-10 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 transition-all duration-300']" />
                                                <div v-if="errors.footer" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <Transition name="fade">
                                            <p v-if="errors.footer" class="mt-1 text-xs text-red-600 select-none uppercase font-semibold" id="footer-error">Brak stopki.</p>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="relative">
                                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div class="w-full border-t border-gray-300" />
                                    </div>
                                </div> -->
                            </div>
                            <div class="absolute bottom-0 right-0 m-6">
                                <div class="flex justify-end mt-8">
                                    <button :disabled="blockButton" @click.prevent="validateBeforeSend" type="button" :class="[blockButton ? 'opacity-30 pointer-events-none' : '']" class="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-500">
                                        <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                        Wyślij
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        </Transition>
    </div>
    <SendedEmailsModal v-if="sendedEmailsModal" @close="closeSendedEmailsModal" />
</div>
</template>

<script>
import {db} from "@/firebase/gfbconf.js";
import axios from 'axios';
import moment from 'moment'
import { HomeIcon, UserIcon, UsersIcon, CheckCircleIcon, XCircleIcon, ChevronDownIcon, ChevronUpIcon, ChevronRightIcon, ChevronLeftIcon, ExclamationCircleIcon } from '@heroicons/vue/solid'
import EmptyState from '../components/EmptyState.vue'
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import SendedEmailsModal from '../components/SendedEmailsModal.vue'
export default {
    components: {
        SendedEmailsModal, HomeIcon, EmptyState, ChevronLeftIcon, LoadingSpinnerHub, CheckCircleIcon, XCircleIcon, ChevronDownIcon, ChevronUpIcon, ChevronRightIcon, ExclamationCircleIcon
    },
    name: "emails",
    data() {
        return {
            email: {
                // fromText: 'Faktury Lauron Sp. z o.o',
                subjectText: 'Powiadomienie z panelu klienta - Lauron',
                headerText: 'Szanowny kliencie',
                bodyText: 'ąśćźż',
                footerText: 'Pozdrawiamy, Zespół Lauron Sp. z o.o.'
            },
            blockButton: false,
            sendedEmailsModal: false,
            isChecked: true,
            clientList: true,
            showManageModal: false,
            showAddModal: false,
            pickedUsr: null,
            queryLoading: false,
            queryLimitReached: false,
            dbListener: null,
            changeState: false,
            lastLoaded: null,
            userList: [],
            checkedUsers: [],
            queryLimit: 25,
            sortTable: {
                pickedColumn: null,
                type: 'desc'
            },
            todayUnix: null,
            invoicesGenerating: false,
            errors: {
                subject: false,
                header: false,
                body: false,
                footer: false,
            },
            items: [
                {
                    id: 1,
                    name: 'Text',
                    description: 'Add freeform text with basic formatting options.',
                    url: '#',
                    color: 'bg-indigo-500',
                    icon: UserIcon,
                },
                {
                    id: 2,
                    name: 'Text',
                    description: 'Add freeform text with basic formatting options.',
                    url: '#',
                    color: 'bg-indigo-500',
                    icon: UserIcon,
                },
                {
                    id: 3,
                    name: 'Text',
                    description: 'Add freeform text with basic formatting options.',
                    url: '#',
                    color: 'bg-indigo-500',
                    icon: UserIcon,
                },
            ],
            tabs: [
                { name: 'Wyślij', href: '#', icon: UserIcon, current: true },
                { name: 'Wysłane', href: '#', icon: UsersIcon, current: false },
            ],
            queryLimit: 20,
            companies: [],
        }
    },
  
    methods: {
        closeSendedEmailsModal(){
            this.sendedEmailsModal = false;
            for (let i=0;i<this.tabs.length; i++)
            {
                this.tabs[i].current = false
            }
            this.tabs[0].current = true
        },
        openSendedEmailsModal(data, notifications){
            this.sendedEmailsModal = true
            this.sendedEmailsModalData = notifications
        },
        openTab(indx)
        {
            for (let i=0;i<this.tabs.length; i++)
            {
                this.tabs[i].current = false
            }
            if(indx === 1)
            {
                this.openSendedEmailsModal()
            }
            this.tabs[indx].current = true
        },
        checkUser(event, type, user)
        {
            if(type === 'multiple')
            {
                if(event.target.checked)
                {
                    this.isChecked = true;
                    for(let i=0; i<this.userList.length; i++)
                    {
                        this.userList[i].checked = true;
                        this.checkedUsers.push(this.userList[i])
                    };
                }
                else
                {
                    this.isChecked = false;
                    for(let i=0; i<this.userList.length; i++)
                    {
                        this.userList[i].checked = false;
                        this.checkedUsers = [];
                    };
                }
            }
            else
            {
                if(event.target.checked)
                {
                    this.checkedUsers.push(user)
                }
                else
                {
                    for(let i=0; i<this.checkedUsers.length; i++)
                    {
                        if(user.id === this.checkedUsers[i].id)
                        {
                            this.checkedUsers.splice(i, 1);
                            break;
                        }
                    }
                }
            }
        },
        async sendEmail()
        {
            this.blockButton = true;
            try {
                const res = await axios.post(`${this.$store.state.apiLink}/sendMultipleEmail`, {
                    users: this.checkedUsers,
                    message: this.email
                })
                if(res.data.status === 'OK')
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Akcja zakończona sukcesem!",
                        subheader: "Email został wysłany.",
                        success: true
                    });
                }
                else if(res.data.status === 'ERROR')
                {
                    throw res.data.log;
                }
                setTimeout(() => {
                    this.blockButton = false;
                }, 2500);
            }
            catch (error) {
                console.log(error); 
                alert('Nie mogę wysłać emaila do wybranych użytkowników.')
                setTimeout(() => {
                    this.blockButton = false;
                }, 2500);
            }
        },
        mailValidator()
        {
            let errorsAmount = 0;
            this.clearErrors();
            if(this.email.subjectText.length === 0)
            {
                errorsAmount++;
                this.errors.subject = true;
            }
            if(this.email.headerText.length === 0)
            {
                errorsAmount++;
                this.errors.header = true;
            }
            if(this.email.bodyText.length === 0)
            {
                errorsAmount++;
                this.errors.body = true;
            }
            if(this.email.footerText.length === 0)
            {
                errorsAmount++;
                this.errors.footer = true;
            }
            return errorsAmount;
        },
        clearErrors()
        {
            this.errors.subject = false;
            this.errors.header = false;
            this.errors.body = false;
            this.errors.footer = false;
        },
        validateBeforeSend()
        {
            this.clearErrors();
            if(this.mailValidator() > 0) return;
            this.sendEmail();    
        },
        async downloadUsers()
        {
            let query = ""
            query = db.collection("UsersData")
            if(this.sortTable.pickedColumn === null){
                query = query.orderBy("meta.created.unix", this.sortTable.type)
            }
            if(this.sortTable.pickedColumn === 'name'){
                query = query.orderBy("name", this.sortTable.type)
            }
            if(this.sortTable.pickedColumn === 'businessName'){
                query = query.orderBy("company.name", this.sortTable.type)
            }
            if(this.sortTable.pickedColumn === 'cardGroup'){
                query = query.orderBy("groupOfCards", this.sortTable.type)
            }
            if(this.sortTable.pickedColumn === 'dateOfAgreement'){
                query = query.orderBy("agreement.pickedDateOfAgreementUnix", this.sortTable.type)
            }
            query = query;
            this.dbListener = query
            .onSnapshot((querySnapshot) => {
                this.queryLoading = true;
                this.userList = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    let current = doc.data();
                    current.checked = true;
                    this.userList.push(current)
                    this.lastLoaded = current.id;
                });
                this.queryLoading = false;
                for(let i=0; i<this.userList.length; i++)
                {
                    this.checkedUsers.push(this.userList[i])
                    // if(this.userList[i].checked)
                    // {
                    //     this.isChecked = true;
                    //     break;
                    // }
                };
                // this.checkedUsers = this.userList;
            });
        },
    },
    async created()
    {
        await this.downloadUsers()
     
    },
}

</script>
<style lang="scss" scoped>
.fade-enter-active{
  transition: opacity 1s ease;
}
.fade-leave-active{
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}
</style>