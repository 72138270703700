<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900"> Wprowadź rabaty </DialogTitle>
                    <div class="mt-2 flex flex-col gap-2">
                      <div v-if="error.length > 0" class="text-red-500 text-sm mt-2 font-medium bg-red-100 px-2 py-1 rounded">{{error}}</div>
                      <div>
                        <div class="block text-sm font-medium leading-6 text-slate-900 mt-2 ring-red-500">Okres:</div>
                        <VueDatePicker placeholder="Wybierz okres rabatu..." :format="periodDateFormat" auto-apply locale="pl" v-model="periodDate" :enable-time-picker="false" :clearable="false" range /> 
                      </div>
                      <div class="flex gap-4">
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <div class="font-medium text-gray-700 text-sm">Shell FuelSave Diesel</div>
                            <input onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46' @change="validateInput('Shell FuelSave Diesel')" @blur="validateInput('Shell FuelSave Diesel')" v-model.number="products['Shell FuelSave Diesel']" type="text" name="price" id="price" class="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" aria-describedby="price-currency" />
                        </div>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <div class="font-medium text-gray-700 text-sm">Shell V-Power Nitro+ Diesel</div>
                            <input onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46' v-model.number="products['Shell V-Power Nitro+ Diesel']" type="text" name="price" id="price" class="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" aria-describedby="price-currency" />
                        </div>
                      </div>
                      <div class="flex gap-4">
                        <div class="relative mt-1 rounded-md shadow-sm flex gap-4">
                          <div>
                            <div class="font-medium text-gray-700 text-sm">AdBlue rabat</div>
                            <input onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46' v-model.number="products['AdBlue']" type="text" name="price" id="price" class="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" aria-describedby="price-currency" />
                          </div>
                          <div>
                            <div class="font-medium text-gray-700 text-sm">AdBlue cena zakupu</div>
                            <input onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46' v-model.number="products['AdBlueBuy']" type="text" name="price" id="price" class="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" aria-describedby="price-currency" />
                          </div>
                        </div>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <div class="font-medium text-gray-700 text-sm">Shell FuelSave 95</div>
                            <input onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46' v-model.number="products['Shell FuelSave 95']" type="text" name="price" id="price" class="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" aria-describedby="price-currency" />
                        </div>
                      </div>
                      <div class="flex gap-4">
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <div class="font-medium text-gray-700 text-sm">Shell V-Power Nitro+ 95 AL</div>
                            <input onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46' v-model.number="products['Shell V-Power Nitro+ 95 AL']" type="text" name="price" id="price" class="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" aria-describedby="price-currency" />
                        </div>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <div class="font-medium text-gray-700 text-sm">Shell Racing 100</div>
                            <input onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46' v-model.number="products['Shell Racing 100']" type="text" name="price" id="price" class="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" aria-describedby="price-currency" />
                        </div>
                      </div>
                      <div class="flex gap-4">
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <div class="font-medium text-gray-700 text-sm">Gaz samochodowy LPG</div>
                            <input onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46' v-model.number="products['Gaz samochodowy LPG']" type="text" name="price" id="price" class="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" aria-describedby="price-currency" />
                        </div>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <div class="font-medium text-gray-700 text-sm">Inne</div>
                            <input onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46' v-model.number="products['Inne']" type="text" name="price" id="price" class="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" aria-describedby="price-currency" />
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <!-- <button type="button" :disabled="(paidDate == null || paidDate.length == 0)" :class="[(paidDate == null || paidDate.length == 0) ? 'bg-gray-200 text-base font-medium pointer-events-none' : 'bg-green-500 text-base font-medium hover:bg-green-600 focus:ring-green-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm']" @click="emitAccept">Oznacz</button> -->
              <button type="button" :class="['bg-green-500 text-base font-medium hover:bg-green-600 focus:ring-green-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm']" @click="emitAccept">Dodaj</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Anuluj</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { TrashIcon } from '@heroicons/vue/solid'
import {db} from "@/firebase/gfbconf.js";
import VueDatePicker from '@vuepic/vue-datepicker';
import moment from 'moment'
import '@vuepic/vue-datepicker/dist/main.css'
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    VueDatePicker,
    TrashIcon
  },
  props: ["modalData"],
  data() {
    return {
        inputDisabled: false,
        periodDate: [],
        periods: [],
        products: {
            'Shell FuelSave Diesel': 0,
            'Shell V-Power Nitro+ Diesel': 0,
            'AdBlue': 0,
            'AdBlueBuy': 0,
            'Shell FuelSave 95': 0,
            'Shell V-Power Nitro+ 95 AL': 0,
            'Shell Racing 100': 0,
            'Gaz samochodowy LPG': 0,
            'Inne': 0
        },
        error: '',
        moment: moment
    }
  },
  methods: {
    validateInput(name) {
      if(this.products[name].length === 0 || this.products[name] < 0 || isNaN(this.products[name])) this.products[name] = 0
    },
    periodDateFormat() {
        let date = '';
        if(this.periodDate.length > 0)
        {
            date = `${moment(this.periodDate[0]).format('DD.MM.YYYY')} - ${moment(this.periodDate[1]).format('DD.MM.YYYY')}`
        }
        return date;
    },
    emitClose()
    {
        this.$emit("closeModal");
    },
    async downloadPeriods() {
      let periods = await db.collection('GainsLossesPeriods').doc('periods').get()
      this.periods = periods.data().periods
    },
    async emitAccept() {
      if(this.periodDate.length > 0) {
        let start = moment(this.periodDate[0]).startOf('day').unix();
        let end = moment(this.periodDate[1]).startOf('day').unix();
        let periodExisted = false
        for(let i = 0; i < this.periods.length; i++) {
          let existingStart = this.periods[i].start;
          let existingEnd = this.periods[i].end;
            if(((start >= existingStart && start <= existingEnd) || (end >= existingStart && end <= existingEnd)) || (start <= existingStart && end >= existingEnd) || (start >= existingStart && end <= existingEnd)) {
                periodExisted = true
            }
        }
        if(!periodExisted) {
          this.error = ''
          this.periods.push({start: start, end: end, products: this.products})
        } else {
          this.error = 'Taki okres już istnieje'
          return
        }
      }
      await db.collection('GainsLossesPeriods').doc('periods').set({periods: this.periods})
      this.emitClose()
    },
    close()
    {
        this.emitClose();
        this.open = false
    },
  },
  computed: {
  },
  created()
  {
    this.downloadPeriods()
  },
  watch: {
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  }
}
</script>

<style scoped>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

</style>
