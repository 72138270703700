<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="overflow-auto h-96">
                <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Okres</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Ilość faktur</th>
                        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                        <span class="sr-only">Edit</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody class="bg-white">
                    <tr v-for="(invoicePeriod, invoicePeriodIdx) in invoicePeriods" :key="invoicePeriod" :class="invoicePeriodIdx % 2 === 0 ? undefined : 'bg-gray-50'">
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{{ invoicePeriod[0] }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ invoicePeriod[1].length }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><input v-model="invoicePeriod[3]" aria-describedby="comments-description" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600" /></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button type="button" :class="['transition ease-in-out duration-300 bg-blue-600 text-base font-medium hover:bg-blue-700 focus:ring-blue-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm']" @click.prevent="generateStatement()">Generuj</button>
                <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Zamknij</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import {db} from "@/firebase/gfbconf.js";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  props: ["modalData"],
  data() {
    return {
        invoicePeriods: {}
    }
  },
  methods: {
    generateStatement(){
        let dataToGenerate = []
        let includedPeriods = []
        for (let i = 0; i < this.invoicePeriods.length; i++) {
            let period = this.invoicePeriods[i]
            if(period[3]){
                includedPeriods.push(period[0])
                for (let f = 0; f < period[1].length; f++) {
                    let invoice = period[1][f]
                    for (let t = 0; t < invoice.transactions.length; t++) {
                        let transaction = invoice.transactions[t]
                            let dataToPush = {
                            clientName: invoice.payerName,
                            productName: transaction.name,
                            quantity: transaction.quantity,
                            grossValue: transaction.grossValue
                        }
                        let productExsts = false
                        for (let cd = 0; cd < dataToGenerate.length; cd++) {
                            if(dataToGenerate[cd].clientName === invoice.payerName && dataToGenerate[cd].productName === transaction.name){
                                productExsts = true
                                dataToGenerate[cd].quantity += transaction.quantity
                                dataToGenerate[cd].quantity = Math.round(dataToGenerate[cd].quantity * 100) / 100
                                dataToGenerate[cd].grossValue += transaction.grossValue
                                dataToGenerate[cd].grossValue = Math.round(dataToGenerate[cd].grossValue * 100) / 100
                            }    
                        }
                        if(!productExsts){
                            dataToGenerate.push(dataToPush)
                        }
                    }
                }
            }
        }
        dataToGenerate.sort((a, b) => {
            const nameA = a.clientName.toUpperCase(); // ignore upper and lowercase
            const nameB = b.clientName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        });

        let productSummary = {}

        for (let cp = 0; cp < dataToGenerate.length; cp++) {
            const element = dataToGenerate[cp];
            if(productSummary[element.productName] === undefined){
                productSummary[element.productName] = {
                    product: element.productName,
                    grossValue: element.grossValue,
                    quantity: element.quantity
                }
            }else{
                productSummary[element.productName].grossValue += element.grossValue
                productSummary[element.productName].quantity += element.quantity
            }
            productSummary[element.productName].grossValue = parseFloat((productSummary[element.productName].grossValue).toFixed(2))
            productSummary[element.productName].quantity = parseFloat((productSummary[element.productName].quantity).toFixed(2))
        }

        let changeToArray = []
        for (let cta = 0; cta < Object.keys(productSummary).length; cta++) {
            const element = Object.keys(productSummary)[cta];
            changeToArray.push(productSummary[element])
        }
        productSummary = changeToArray


        if(dataToGenerate.length > 0){
        let productsTable = [
                    [
                        {
                        text: 'Lp.',
                        border: [false, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 5, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Klient',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 5, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Produkt',
                        fillColor: '#eaf2f5',
                        alignment: 'center',
                        border: [true, true, true, true],
                        margin: [-9, 5, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Ilość (łącznie)',
                        fillColor: '#eaf2f5',
                        alignment: 'center',
                        border: [true, true, true, true],
                        margin: [-9, 5, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Wartość brutto (łącznie)',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 5, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        }
                    ]
                    ]


                    for (let t = 0; t < dataToGenerate.length; t++) {
                        let crntProduct = dataToGenerate[t]
                        productsTable.push([
                            {
                            text: `${t+1}`,
                            border: [false, true, true, true],
                            
                            margin: [-9, 5, -5, 5],
                            alignment: 'right',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntProduct.clientName}`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntProduct.productName}`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntProduct.quantity.toFixed(2)}`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntProduct.grossValue.toFixed(2)} PLN`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            }
                        ])
                    }

                    let productsSummaryTable = []
                    if(productSummary.length > 0){
                        productsSummaryTable.push([
                        {
                        text: 'Lp.',
                        border: [false, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 5, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Produkt',
                        fillColor: '#eaf2f5',
                        alignment: 'center',
                        border: [true, true, true, true],
                        margin: [-9, 5, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Ilość (łącznie)',
                        fillColor: '#eaf2f5',
                        alignment: 'center',
                        border: [true, true, true, true],
                        margin: [-9, 5, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Wartość brutto (łącznie)',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 5, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        }
                    ])


                    for (let t = 0; t < productSummary.length; t++) {
                        let crntProduct = productSummary[t]
                        productsSummaryTable.push([
                            {
                            text: `${t+1}`,
                            border: [false, true, true, true],
                            
                            margin: [-9, 5, -5, 5],
                            alignment: 'right',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntProduct.product}`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntProduct.quantity.toFixed(2)}`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntProduct.grossValue.toFixed(2)} PLN`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            }
                        ])
                    }

                    }

        let docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
        content: [
            {
            columns: [
                [
                {
                    text: 'Zestawienie produktowe dla wszystkich klientów',
                    color: '#333333',
                    width: '*',
                    fontSize: 16,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 10, 0, 10],
                }
                ],
            ],
            },
            {
            columns: [
                {
                text: 'Zestawienie dla okresów:',
                color: '#aaaaab',
                bold: true,
                fontSize: 12,
                alignment: 'left',
                margin: [0, 15, 0, 2],
                }
            ],
            },
            {
            columns: [
                {
                text: `${includedPeriods.toString().replaceAll(',', '\n')}`,
                bold: true,
                fontSize: 10,
                color: '#333333',
                alignment: 'left',
                }
            ],
            },
            '\n',
            {
            layout: {
                defaultBorder: false,
                hLineWidth: function(i, node) {
                return 1;
                },
                vLineWidth: function(i, node) {
                return 1;
                },
                hLineColor: function(i, node) {
                if (i === 1 || i === 0) {
                    return '#bfdde8';
                }
                return '#eaeaea';
                },
                vLineColor: function(i, node) {
                return '#eaeaea';
                },
                hLineStyle: function(i, node) {
                // if (i === 0 || i === node.table.body.length) {
                return null;
                //}
                },
                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                paddingLeft: function(i, node) {
                return 10;
                },
                paddingRight: function(i, node) {
                return 10;
                },
                paddingTop: function(i, node) {
                return 2;
                },
                paddingBottom: function(i, node) {
                return 2;
                },
                fillColor: function(rowIndex, node, columnIndex) {
                return '#fff';
                },
            },
            table: {
                headerRows: 1,
                dontBreakRows: true,
                widths: [1,'*',200,85,85],
                body: productsTable
            },
            },
            '\n',
            '\n',
            '\n',
            {
            columns: [
                [
                {
                    text: 'Podsumowanie',
                    color: '#333333',
                    width: '*',
                    fontSize: 16,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 10, 0, 10],
                }
                ],
            ],
            },
            {
            layout: {
                defaultBorder: false,
                hLineWidth: function(i, node) {
                return 1;
                },
                vLineWidth: function(i, node) {
                return 1;
                },
                hLineColor: function(i, node) {
                if (i === 1 || i === 0) {
                    return '#bfdde8';
                }
                return '#eaeaea';
                },
                vLineColor: function(i, node) {
                return '#eaeaea';
                },
                hLineStyle: function(i, node) {
                // if (i === 0 || i === node.table.body.length) {
                return null;
                //}
                },
                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                paddingLeft: function(i, node) {
                return 10;
                },
                paddingRight: function(i, node) {
                return 10;
                },
                paddingTop: function(i, node) {
                return 2;
                },
                paddingBottom: function(i, node) {
                return 2;
                },
                fillColor: function(rowIndex, node, columnIndex) {
                return '#fff';
                },
            },
            table: {
                headerRows: 1,
                dontBreakRows: true,
                widths: [1,'*',120,120],
                body: productsSummaryTable
            },
            },
        ],
        styles: {
        },
        defaultStyle: {
            columnGap: 20,
            //font: 'Quicksand',
        },
        };


        pdfMake.createPdf(docDefinition).download(`Zestawienie_produktów_${includedPeriods.toString().replaceAll(',', '_').replaceAll(' - ', '-')}.pdf`);
        setTimeout(() => {
            this.close()
        }, 500);
        }else{
            alert('Aby wygenerować zestawienie, musisz wybrać okres rozliczeniowy')
        }

    },
    log(log){
        console.log(log)
    },
    emitClose()
    {
        this.paidAmount = 0;
        this.inputDisabled = false;
        this.fullAmountChecked = false;
        this.$emit("close");
    },
    close()
    {
        this.emitClose();
        this.open = false
    },
    async downloadInvoices(){
        let invoices = await db.collection("Invoices").orderBy("created.unix", "desc").get()
        for (let i = 0; i < invoices.docs.length; i++) {
            let invoice = invoices.docs[i].data()
            // if(invoice.active){
                if(this.invoicePeriods[invoice.period] === undefined){
                this.invoicePeriods[invoice.period] = {
                    invoices: []
                }
                this.invoicePeriods[invoice.period].invoices.push(invoice)
            }else{
                this.invoicePeriods[invoice.period].invoices.push(invoice)
            }
            // }
        }
        let sortable = []
        let invoicePeriodsByName = Object.keys(this.invoicePeriods)
        for (let x = 0; x < invoicePeriodsByName.length; x++) {
            sortable.push([invoicePeriodsByName[x], this.invoicePeriods[invoicePeriodsByName[x]].invoices, this.invoicePeriods[invoicePeriodsByName[x]].invoices[0].created.unix, false])
        }
        sortable.sort(function(a, b) {
            return a[2] - b[2];
        });
        sortable.reverse()
        this.invoicePeriods = sortable
    }
  },
  computed: {
  },
  created(){
    this.downloadInvoices()
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  }
}
</script>

<style scoped>


</style>
