<template>
    <div>
        <div class="flex justify-between">
            <nav class="hidden sm:flex h-8" aria-label="Breadcrumb">
                <ol role="list" class="flex space-x-4 rounded-md bg-slate-500 px-6 shadow">
                    <li class="flex">
                        <div class="flex items-center">
                        <router-link to="/hub" class="transition ease-in-out duration-300 text-slate-100 hover:text-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span class="sr-only">Home</span>
                        </router-link>
                        </div>
                    </li>
                    <li class="flex">
                        <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <div class="ml-4 text-xs font-medium text-slate-100 opacity-60 cursor-default select-none" aria-current="page">Noty odsetkowe</div>
                        </div>
                    </li>
                </ol>
                <!-- <div @click.prevent="testInvoice()" class="ml-4 cursor-pointer flex items-center justify-center px-4 bg-red-200">TEST FAKTUR</div> -->
            </nav>
            <div class="flex items-center sm:hidden">
                <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 text-sm font-medium text-slate-300 hover:text-slate-400" aria-current="page">&larr; Cofnij</router-link>
            </div>
            <div class="flex">
                <Transition name="fade">
                <div v-if="showFilters" class="mr-8 flex">
                    <div class="relative rounded-md  focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600 mr-5">
                        <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-slate-600 px-1 text-xs font-medium text-slate-300 rounded-t-md">Status</label>
                        <select @change="updateFilters()" v-model="paidStatus" class="block w-full pl-3 pr-10 py-2 text-sm text-slate-200 bg-slate-600 border-slate-300 focus:outline-none focus:ring-blue-300 focus:border-blue-300 rounded-md">
                            <option value="all">Wszystkie</option>
                            <option value="paid">Zapłacone</option>
                            <option value="notPaid">Nie zapłacone</option>
                        </select>
                    </div>
                    <div class="relative rounded-md  focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                        <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-slate-600 px-1 text-xs font-medium text-slate-300 rounded-t-md">Firma</label>
                        <select @change="updateFilters()" v-model="filterCompanyId" class="block w-full pl-3 pr-10 py-2 text-sm text-slate-200 bg-slate-600 border-slate-300 focus:outline-none focus:ring-blue-300 focus:border-blue-300 rounded-md">
                            <option value="all">Wszystkie</option>
                            <option v-for="company in companies" :key="company.id" :value="company.id">{{company.company.name}}</option>
                        </select>
                    </div>
                </div>
                </Transition>
                <div @click="toggleFilters()" class="mr-4 cursor-pointer flex justify-center items-center flex-col select-none">
                    <FilterIcon class="h-4 w-4 text-slate-200 flex-shrink-0" aria-hidden="true" />
                    <span class="text-xs font-medium text-slate-300">{{showFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
                </div>
            </div>
        </div>
    <InterestNotePaymentModal v-if="modalVisible" @closeModal="modalVisible = false" :modalData="modalData" @accept="updatePaidAmount"/>
    <div class="mt-4 flex flex-col">
        <div class="overflow-x-auto">
            <div class="inline-block min-w-full py-2 align-middle">
                <div class="overflow-hidden min-h-64 shadow border border-slate-700 rounded-lg">
                    <table class="min-w-full divide-y divide-slate-700">
                        <thead class="bg-slate-700">
                            <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold whitespace-nowrap text-slate-100 sm:pl-6">Numer noty</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Firma</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Kwota odsetek</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Pierwsze pobranie</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Zapłacono</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Pobierz</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                    <div class="flex items-center gap-5">
                                        <Menu as="div" class="relative inline-block text-left">
                                            <div>
                                                <MenuButton class="transition ease-in-out duration-300 inline-flex w-full justify-center gap-x-1.5 font-medium rounded-md bg-slate-600 px-2 py-1 text-sm text-slate-100 shadow-sm ring-1 ring-inset ring-slate-800 hover:bg-slate-500">
                                                    Menu
                                                    <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </MenuButton>
                                            </div>
                                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                            <MenuItems class="absolute right-0 z-10 mt-2 w-62 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div class="py-1">
                                                <MenuItem v-slot="{ active }">
                                                    <div @click.prevent="goToCreateInterestNote()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']">Stwórz notę odsetkową</div>
                                                </MenuItem>
                                                <MenuItem v-slot="{ active }">
                                                    <div @click.prevent="openInterestSetModal()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']">Ustaw wartość odsetek</div>
                                                </MenuItem>
                                                <MenuItem v-slot="{ active }">
                                                    <div @click.prevent="acceptInterestNote()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', acceptingInterestNotes ? 'pointer-events-none' : '', 'block px-4 py-2 text-sm cursor-pointer']">{{acceptingInterestNotes ? 'Akceptowanie....' : 'Akceptuj wszystkie noty'}}</div>
                                                </MenuItem>
                                                </div>
                                            </MenuItems>
                                            </transition>
                                        </Menu>
                                        <!-- <button type="button" @click.prevent="testInvoice2()" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">Test faktur</button> -->
                                        <!-- <button type="button" @click.prevent="removeTestInvoice()" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">U. test. f.</button> -->
                                        <div v-if="checkUnacceptedInvoices" class="flex justify-end items-center w-5 h-5">
                                            <input id="active-select" aria-describedby="active-select" name="active-select" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-gray-400 focus:ring-gray-600" />
                                        </div>
                                    </div>
                                </th>

                            </tr>
                        </thead>
                        <tbody class="divide-y divide-slate-600 bg-slate-500">
                            <tr v-for="interestNote in invoicesList" :key="interestNote.id">
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-semibold text-slate-100 sm:pl-6">{{ interestNote.interestNoteName }}</td>
                                <td v-tooltip="`${interestNote.payerName}`" class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-semibold">{{interestNote.payerName.length > 0 ? interestNote.payerName.length < 20 ? interestNote.payerName : interestNote.payerName.substring(0,20) + '...' : '--'}}</td>
                                <td @click="log(interestNote)" class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                    <span class="flex">{{ interestNote.valueOfInterestNote.toFixed(2) }} PLN</span>
                                </td>
                                <td v-if="!interestNote.testInvoice" class="whitespace-nowrap px-3 py-4 text-xs" :class="[interestNote.firstDownload  ? 'text-yellow-300 font-semibold' : 'text-slate-300 font-bold']">{{ interestNote.firstDownload || "Brak" }}</td>
                                <td v-if="interestNote.testInvoice" class="whitespace-nowrap px-3 py-4 text-xs"><div class="text-slate-300 opacity-20 font-normal">Nie dotyczy</div></td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs font-bold">
                                    <div v-if="!interestNote.paid" class="flex flex-col gap-3">
                                        <button v-if="!interestNote.testInvoice" @click="modalData=interestNote, modalVisible = true" class="transition ease-in-out duration-300 bg-slate-600 hover:bg-slate-700 text-white font-semibold py-2 px-4 rounded">Zarządzaj</button>
                                        <div v-else class="text-slate-300 opacity-20 font-normal">Nie dotyczy</div>
                                    </div>
                                    <div class="flex gap-1 items-center text-green-400" v-else>
                                        Całość
                                        <PencilAltIcon v-tooltip="`Edytuj zapłaconą kwotę.`" @click="modalData=interestNote, modalVisible = true" class="hover:text-gray-300 transition-all duration-300 cursor-pointer w-5 h-5" />
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                    <div class="flex">
                                        <a target="_blank" :href="interestNote.pdf.link">
                                            <DocumentTextIcon :class="['text-slate-400 hover:text-slate-300', 'mr-3 flex-shrink-0 h-6 w-6', 'transition ease-in-out duration-300 hover:text-blue-300 cursor-pointer' ]"></DocumentTextIcon>
                                        </a>
                                        <!-- <a v-if="interestNote.overviewPdf.link.length > 0" target="_blank" :href="interestNote.overviewPdf.link" class="flex justify-center">
                                            <DocumentReportIcon :class="['text-slate-400 hover:text-slate-300', 'mr-3 flex-shrink-0 h-6 w-6', 'transition ease-in-out duration-300 hover:text-blue-300 cursor-pointer' ]"></DocumentReportIcon>
                                        </a> -->
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                    <span v-if="interestNote.acceptingInterestNotes || acceptingInterestNotes" class="font-bold text-yellow-400">Akceptowanie...</span>
                                    <div class="flex items-center gap-2" v-else>
                                        <TransitionGroup name="fade">
                                        <button @click="acceptInterestNote(interestNote)" :disabled="acceptingInterestNotes || interestNote.acceptingInterestNotes" v-if="!interestNote.active && !interestNote.testInvoice" class="transition ease-in-out duration-300 bg-slate-600 hover:bg-slate-700 text-white font-semibold py-2 px-4 rounded">Akceptuj</button> 
                                        </TransitionGroup> 
                                        <div v-if="interestNote.testInvoice" class="text-blue-300 font-normal opacity-70">Nota testowa</div>
                                        <span v-if="interestNote.active && !interestNote.testInvoice" class="text-green-400 font-bold">Zaakceptowana</span> 
                                        <button v-tooltip="'Usuń notę.'" @click.prevent="openDeleteInterestNoteModal(interestNote)" class="group group-hover:text-red-400 transition ease-in-out duration-300 bg-slate-600 hover:bg-slate-700 text-white font-semibold py-1.5 px-2 rounded">
                                            <TrashIcon class="w-5 h-5 text-gray-200 group-hover:text-red-400 transition-all duration-300" />
                                        </button> 
                                        <!-- <span @click.prevent="sendEmail(interestNote)" class="cursor-pointer">TEST EMAIL</span> -->
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <EmptyState v-if="invoicesList.length === 0"></EmptyState>
                </div>
                <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false">
                        <button type="button" @click.prevent="loadMoreUsers" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Załaduj więcej..</button>
                </div>
            </div>
        </div>
    </div>
    <transition name="fade">
      <div v-if="this.invoicesGenerating">
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gradient-to-r from-slate-600 via-slate-700 to-slate-600 opacity-85 flex flex-col items-center justify-center">
            <h2 class="text-center text-slate-200 text-sm font-normal">Trwa generowanie noty odsetkowej</h2>
            <p class="w-1/3 text-center mb-2.5 text-xs text-red-400 opacity-60">Nie wyłączaj tej strony do momentu zapełnienia paska progresu.</p>
            <div class="w-1/4 bg-gray-200 rounded-full h-2.5 relative">
                <div v-tooltip="'Umowy własne'" :class="'z-20'" class="bg-blue-500 h-2.5 rounded-full transition-all duration-1000 absolute" :style="`width: ${invoiceGenerateProgress}%`"></div>
            </div>
            <p class="w-1/3 mt-1 text-center text-xs text-slate-400 opacity-60">{{invoiceGenerateProgress.toFixed(2)}}%</p>
            <p class="w-1/3 text-center text-xxs font-medium mt-4 text-red-500 opacity-60">W przypadku wyłączenia tej podstrony, bądź utraty połączenia z internetem generowanie noty odsetkowej zostanie przerwane i nie będzie możliwości ponownego wygenerowania noty odsetkowej.</p>
            <p class="w-1/3 text-center text-xxs font-medium text-red-500 opacity-60">W takim przypadku zgłoś się do administratora systemu.</p>
        </div>
      </div>
    </transition>
</div>
<InterestSetModal v-if="interestSetModal" @closeModal="interestSetModal = false" :periods="periods" @accept="closeInterestSetModal"/>
<DeleteInterestNote v-if="deleteInterestNote" @accept="deleteInterestNoteFunc" :modalData="modalDataDeleteSingleInterestNote" @closeModal="closeDeleteInterestNoteModal()" :iconColor="`indigo`" :buttonColor="`indigo`" />
</template>

<script>
import EmptyState from '../components/EmptyState.vue'
import { HomeIcon, DownloadIcon, ArrowNarrowRightIcon, ChevronDownIcon, TrashIcon } from '@heroicons/vue/solid'
import { FilterIcon,DocumentTextIcon,DocumentReportIcon, PencilAltIcon } from '@heroicons/vue/outline'
import {db, storage} from "@/firebase/gfbconf.js";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import InterestNotePaymentModal from '../components/InterestNotePaymentModal.vue'
import InvoiceStatementModal from '../components/InvoiceStatementModal.vue'
import InterestSetModal from '../components/InterestSetModal.vue'
import ProductStatementModal from '../components/ProductStatementModal.vue'
import CsvExportModal from '../components/CsvExportModal.vue'
import acceptInvoceModal from '@/components/AlertModal.vue';
import DeleteInterestNote from '@/components/DeleteInterestNote.vue';
import axios from 'axios';
import moment from 'moment'

export default {
    name: "invoices",
    data(){
        return{
            checkUnacceptedInvoices: false,
            invoicesToDelete: [],
            invoiceGenerateProgress: 0,
            invoicesGenerating: false,
            acceptingInterestNotes: false,
            testInvoicesDeleting: false,
            modalVisible: false,
            invoicesList: [],
            periods: [],
            dbListener: null,
            query: null,
            queryLoading: false,
            queryLimit: 20,
            queryLimitReached: false,
            modalData: null,
            companies: [],
            showFilters: false,
            paidStatus: "all",
            filterCompanyId: "all",
            invoiceStatementModal: false,
            productStatementModal: false,
            interestSetModal: false,
            csvExportModal: false,
            lockGenerateInvoice: false,
            acceptInvoiceModal: false,
            invoicesToAccept: [],
            deleteInterestNote: false,
            modalDataUnacceptedInvoices: {
                headerText: "Akceptowanie wszystkich not odsetkowych",
                bodyText: `Czy na pewno chcesz zaakceptować [?] not odsetkowych?`,
                acceptButtonText: "Zaakceptuj"
            },
            modalDataDeleteSingleInterestNote: {
                headerText: "Usuwanie noty odsetkowej",
                bodyText: `Czy na pewno chcesz usunąć wybraną notę odsetkową?`,
                acceptButtonText: "Usuń",
                interestNote: null
            },
        }
    },
    components: {
        HomeIcon, PencilAltIcon, TrashIcon,
        DownloadIcon,
        ArrowNarrowRightIcon,
        ChevronDownIcon,
        FilterIcon,
        EmptyState,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        InterestNotePaymentModal,
        InvoiceStatementModal,
        ProductStatementModal,
        CsvExportModal,
        DocumentTextIcon,
        DocumentReportIcon,
        acceptInvoceModal,
        DeleteInterestNote,
        InterestSetModal
    },
    methods: {
        async downloadPeriods() {
            this.periods = (await db.collection("interestPeriods").doc("periods").get()).data().periods;
        },
        openDeleteInterestNoteModal(interestNote)
        {
            this.deleteInterestNote = true
            this.modalDataDeleteSingleInterestNote.interestNote = interestNote;
        },
        openInterestSetModal() {
            this.interestSetModal = true;
        },
        closeInterestSetModal() {
            this.interestSetModal = false;
        },
        closeDeleteInterestNoteModal(){
            this.deleteInterestNote = false
            this.modalDataDeleteSingleInterestNote.interestNote = null;
        },
        goToCreateInterestNote(){
            window.open('/dashboard/createInterestNote', '_blank');
            // this.$router.push('')
        },
        log(content){
            console.log(content)
        },
        updateFilters(){
            this.dbListener()
            this.downloadInvoices()
        },
        toggleFilters(){
            this.showFilters = !this.showFilters
            if(!this.showFilters){
                this.paidStatus = "all",
                this.filterCompanyId = "all"
                this.updateFilters()
            }
        },
        async getUsers(){
            let users = await db.collection("UsersData").orderBy("company.name",'asc').get()
            users.forEach(user => {
                let userData = user.data()
                this.companies.push(userData)
            });
        },
        async downloadInvoices() {
            if(this.dbListener !== null)
            {
                this.dbListener();
            }  
            this.query = db.collection("InterestNotes")
            this.query = this.query.orderBy("created.unix", "desc")
            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.queryLoading = true;
                this.invoicesList = [];
                if(querySnapshot.docs.length<this.queryLimit) {
                        this.queryLimitReached = true;
                }
                else {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id;
                    data.activeSelected = false;
                    this.invoicesList.push(data)
                });
                this.queryLoading = false;
            });
        },
        async updatePaidAmount(data){
            const doc = await db.collection("InterestNotes").doc(data.id);
            let {paidAmount} = data;

            paidAmount = paidAmount.toFixed(2)
            paidAmount = parseFloat(paidAmount)

            if(paidAmount > data.valueOfInterestNote){
                paidAmount = data.valueOfInterestNote;
            } 
            if(paidAmount == data.valueOfInterestNote){
                await doc.update({
                    paidAmount,
                    paid: true,
                    // paidData: moment(data.paidData).format("DD.MM.YYYY")
                })
                return;
            }

            await doc.update({
                paidAmount,
                paid: false,
                // paidData: null
            })
        },
        loadMoreUsers() {
            this.dbListener();
            this.continueListening();  
        },
        continueListening() {
            this.queryLimit = this.queryLimit + 10;

            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.queryLoading = true;
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }

                this.invoicesList = [];

                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id;

                    this.invoicesList.push(data)
                });
                this.queryLoading = false;
            });
        },
        async deleteInterestNoteFunc(data){
            try {
                let interestNote = this.modalDataDeleteSingleInterestNote.interestNote
                const storageRef = storage.ref();
                const fileRef = storageRef.child(interestNote.pdf.ref);

                if(!data.unCheckInvoices) {
                    for(let i = 0; i < interestNote.invoices.length; i++) {
                        const invoice = interestNote.invoices[i];
                        await db.collection('Invoices').doc(invoice.id).update({
                            interestNote: false
                        })
                    }
                }
                await fileRef.delete();
                await db.collection("InterestNotes").doc(interestNote.id).delete();
                this.$store.commit('setNotification', {
                    show: true,
                    head: "Pomyślnie usunięto notę.",
                    subheader: `Nota ${interestNote.interestNoteName} została usunięta.`,
                    success: true
                });
                console.log('File deleted successfully');
            } catch (error) {
                console.error('Error deleting file:', error);
            }
        },
        async acceptInterestNote(interestNote){
            try {
                if(interestNote != null) {
                    interestNote.acceptingInterestNotes = true
                } else {
                    this.acceptingInterestNotes = true
                }
                let res = await axios.post(`${this.$store.state.apiLink}/interestNote/accept`, {
                    interestNote: interestNote == null ? null : interestNote,
                })
                if(interestNote != null) {
                    interestNote.acceptingInterestNotes = false
                } else {
                    this.acceptingInterestNotes = false
                }
            } catch(error){
                console.log(error)
            }
        }
    },
    mounted(){
        this.downloadInvoices()
        this.getUsers()
        this.downloadPeriods()
    },
    beforeUnmount() {
        if(this.dbListener !== null)
        {
            this.dbListener();
        }  
    }
}

</script>
<style lang="scss" scoped>
    .fade-enter-active{
    transition: opacity 1s ease;
    }
    .fade-leave-active{
        transition: opacity 0s;
        display: none;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>