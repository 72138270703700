<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
            <div>
                <div class="sm:hidden">
                <label for="tabs" class="sr-only">Select a tab</label>
                <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                    <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
                </select>
                </div>
                <div class="hidden sm:block">
                <nav class="isolate flex divide-x divide-gray-200 rounded-t-lg shadow" aria-label="Tabs">
                    <div @click="openTab(tabIdx)" v-for="(tab, tabIdx) in tabs" :key="tab.name" :class="[tab.current ? 'text-gray-900' : 'text-gray-500  hover:text-gray-700 cursor-pointer', tabIdx === 0 ? '' : '', tabIdx === tabs.length - 1 ? '' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10']">
                    <span>{{ tab.name }}</span>
                    <span aria-hidden="true" :class="[tab.current ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                    </div>
                </nav>
                </div>
            </div>
            <div v-if="this.tabs[0].current" class="overflow-auto h-96">
                <!-- {{this.notificationsData}} -->
                <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Data</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Klient</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Mail</th>
                        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                        <span class="sr-only">Edit</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody class="bg-white">
                    <tr v-for="(email, emailIdx) in emailsData" :key="emailIdx" :class="emailIdx % 2 === 0 ? undefined : 'bg-gray-50'">
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-3">{{ moment.unix(email.meta.created.unix).format('LLL')}}</td>
                        <td v-tooltip="`${email.sendedTo.name + ' ' + email.sendedTo.company.name }`" class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                          <div class="w-44 truncate">
                            {{ email.sendedTo.name + ' ' + email.sendedTo.company.name }}
                          </div>
                          </td>
                        <td v-tooltip="`${email.sendedTo.contact.email}`" class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                          <div class="w-24 truncate">
                            {{ email.sendedTo.contact.email }}
                          </div>
                          </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div v-if="email.message.html !== undefined && email.message.html !== null && email.message.html.length > 0" class="flex flex-col items-center">
                                <button type="button" @click.prevent="openMailPreviewModal(email.message.html, email.meta.created.unix)" class="rounded w-full bg-blue-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500">Podgląd maila</button>
                            </div>
                            <span v-else class="text-red-500">Brak danych</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <EmptyState v-if="this.emailsData.length === 0"></EmptyState>
            </div>
            <div class="flex items-center justify-center mt-4" v-if="this.emailsData.length > 0 && !queryLimitReached">
                <button type="button" @click.prevent="loadMoreEmails" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..</button>
            </div>  
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Zamknij</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <mailPreview v-if="showMailModal" :modalData="mailModalData" :mailDate="mailDateModalData" @close="closeMailPreviewModal"></mailPreview>
</template>

<script>
import { ref } from 'vue'
import {db} from "@/firebase/gfbconf.js";
import axios from 'axios';
import moment from 'moment'
import EmptyState from '@/components/EmptyState.vue';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import askFor from '@/components/AlertModal.vue';
import mailPreview from '@/components/MailPreviewModal.vue';
import LoadingSpinnerHub from './LoadingSpinnerHub.vue';
export default {
  components: {
    Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, EmptyState, askFor, mailPreview, LoadingSpinnerHub
  },
  props: ["modalData"],
  data() {
    return {
        tabs: [
          { name: 'Przesłane maile', current: true },
        ],
        emailsData: [],
        showMailModal: false,
        mailModalData: null,
        mailDateModalData: null,
        queryLimit: 25,
        loading: false,
        dbListener: null,
        query: null,
        queryLimitReached: false
    }
  },
  methods: {
    translateUnix(unix){
        moment.locale('pl')
        let translated = moment.unix(unix).format("lll");
        return translated
    },
    emitClose()
    {
        this.$emit("close");
    },
    close()
    {
        this.emitClose();
        this.open = false
    },
    closeMailPreviewModal(){
        this.showMailModal = false
        this.mailModalData = null
        this.mailDateModalData = null
    },
    openMailPreviewModal(data,date){
        this.showMailModal = true
        this.mailModalData = data
        this.mailDateModalData = date
    },
    continueListening()
    {
      this.queryLimit = this.queryLimit + 25;
      this.dbListener = this.query.limit(this.queryLimit)
      .onSnapshot((querySnapshot) => {
        this.emailsData = [];
        if(querySnapshot.docs.length<this.queryLimit)
        {
          this.queryLimitReached = true;
        }
        else
        {
          this.queryLimitReached = false;
        }
        querySnapshot.forEach((doc) => {
          let current = doc.data()
          this.emailsData.push(current);
        });
      });
    },
    loadMoreEmails()
    {
      this.dbListener();
      this.continueListening();      
    },
    getSendedEmails()
    {
      this.query = null;
      this.queryLimit = 25;
      if(this.dbListener !== null){
        this.dbListener()
        this.dbListener = null
      }
      this.query = db.collection("Emails")
      this.query = this.query.orderBy("meta.created.unix", "desc")

      this.dbListener = this.query.limit(this.queryLimit)
      .onSnapshot((querySnapshot) => {
        this.emailsData = [];
        if(querySnapshot.docs.length<this.queryLimit)
        {
          this.queryLimitReached = true;
        }
        else
        {
          this.queryLimitReached = false;
        }
        querySnapshot.forEach((doc) => {
          let current = doc.data()
          this.emailsData.push(current);
        });
      });
      this.loading = false;
    },
  },
  computed: {
  },
  created(){
    moment.locale('pl')
    this.moment = moment;
    this.getSendedEmails();
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  }
}
</script>

<style scoped>


</style>
