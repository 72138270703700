<template>
    <div>
        <div class="flex justify-between">
            <nav class="hidden sm:flex h-8" aria-label="Breadcrumb">
                <ol role="list" class="flex space-x-4 rounded-md bg-slate-500 px-6 shadow">
                    <li class="flex">
                        <div class="flex items-center">
                        <router-link to="/hub" class="transition ease-in-out duration-300 text-slate-100 hover:text-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span class="sr-only">Home</span>
                        </router-link>
                        </div>
                    </li>
                    <li class="flex">
                        <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <div class="ml-4 text-xs font-medium text-slate-100 opacity-60 cursor-default select-none" aria-current="page">Niezafakturowane transakcje</div>
                        </div>
                    </li>
                </ol>
            </nav>
            <div class="flex items-center sm:hidden">
                <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 text-sm font-medium text-purple-300 hover:text-purple-400" aria-current="page">&larr; Cofnij</router-link>
            </div>
            <!-- <div class="flex items-center">
                DEVELOPER: 
                <div class="flex items-center">
                    <button type="button" @click.prevent="allTestInvoice()" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">Fakturowanie testowe</button>
                </div>
                <div class="flex items-center">
                    <button type="button" @click.prevent="allRealInvoice()" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">Fakturowanie aktywne</button>
                </div>
            </div> -->
            <div class="flex w-64" :class="!loaded ? 'opacity-30 cursor-not-allowed' : ''">
                <VueDatePicker :disabled="!loaded" :format="datePickerFormat" placeholder="Wybierz przedział.." auto-apply locale="pl" @update:model-value="downloadUninvoicedTransactions" v-model="date" range :enable-time-picker="false" :min-date="moment(`01-04-2024`, 'DD-M-YYYY').toDate()" :clearable="false" />
            </div>
        </div>
          <div class="mt-4">
            <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <!-- <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
            </select> -->
            </div>
        <div class="flex flex-col mx-4 lg:px-0" >
            <div class="-my-2 -mx-4 overflow-x-auto">
                <div class="inline-block min-w-full py-2 align-middle">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg" v-if="!queryLoading">
                        <table class="min-w-full divide-y divide-slate-700">
                            <thead class="bg-slate-700">
                                <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold whitespace-nowrap text-slate-100 sm:pl-6">
                                    <div class="flex">
                                        ID
                                        <ChevronDownIcon @click.prevent="sortTable.pickedColumn = null, sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === null && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== null }" aria-hidden="true" />
                                        <ChevronUpIcon @click.prevent="sortTable.pickedColumn = null, sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === null && sortTable.type === 'desc' || sortTable.pickedColumn !== null" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== null }" aria-hidden="true" />
                                    </div>
                                    </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                    <div class="flex">
                                        Firma
                                        <ChevronDownIcon @click.prevent="sortTable.pickedColumn = 'businessName', sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === 'businessName' && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'businessName' }" aria-hidden="true" />
                                        <ChevronUpIcon @click.prevent="sortTable.pickedColumn = 'businessName', sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === 'businessName' && sortTable.type === 'desc' || sortTable.pickedColumn !== 'businessName'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'businessName' }" aria-hidden="true" />
                                    </div>
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                    <div class="flex">
                                        Imię Nazwisko 
                                        <ChevronDownIcon @click.prevent="sortTable.pickedColumn = 'name', sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === 'name' && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'name' }" aria-hidden="true" />
                                        <ChevronUpIcon @click.prevent="sortTable.pickedColumn = 'name', sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === 'name' && sortTable.type === 'desc' || sortTable.pickedColumn !== 'name'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'name' }" aria-hidden="true" />
                                    </div>
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Data transakcji</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Nazwa produktu</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                    <div class="flex">
                                        Kwota netto
                                    </div>
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                    
                                </th>
                                </tr>
                            </thead>

                            <tbody v-for="user in userList" class="divide-y divide-slate-700 bg-slate-500">
                                <tr>
                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                        <div class="flex items-center text-xs text-slate-100">
                                            <!-- {{user.id}} -->
                                            <span v-tooltip="`${user.id}`" @click.prevent="copyToClipboard(user.id)" class="transition ease-in-out duration-300 cursor-pointer text-blue-300 hover:text-blue-400">Kopiuj</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                        <!-- {{user.name}} {{user.surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                            <div class="flex items-center">
                                                <!-- {{userList[user.userId].company.name}} -->
                                                <div v-tooltip="user.company.name">{{user.company.name.length > 0 ? user.company.name.length < 20 ? user.company.name : user.company.name.substring(0,20) + '...' : '--'}}</div>
                                            </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                        {{user.name}} {{user.surname}} <span class="text-xs py-1 px-2 bg-red-100 border border-red-300 rounded-lg text-red-500 font-medium" v-if="user.permissions.admin">(ADMINISTRATOR)</span>
                                        <!-- {{userList[user.userId].name}} {{userList[user.userId].surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="userList[user.userId].permissions.admin">(ADMINISTRATOR)</span> -->
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        -
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        -
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        -
                                    </td>
                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 select-none flex justify-end">
                                        <button v-if="loaded" :disabled="user.transactions.length === 0" @click="user.showTransactions = !user.showTransactions" :class="user.transactions.length === 0 ? 'opacity-30 cursor-not-allowed' : 'hover:bg-slate-700'" class="transition ease-in-out duration-300 bg-slate-600 text-white font-semibold py-2 px-4 rounded w-48 flex justify-center">{{user.showTransactions ? `Ukryj transakcje (${user.transactions.length})` : `Pokaż transakcje (${user.transactions.length})`}}</button>
                                        <button v-if="!loaded" :disabled="true" :class="!loaded ? '' : 'hover:bg-slate-700 cursor-not-allowed'" class="transition ease-in-out duration-300 bg-slate-600 text-white font-semibold py-2 px-4 rounded w-48 flex justify-center">
                                            <div class="spinner"></div>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="12" v-if="user.showTransactions && loaded" class="bg-gray-400 p-6">
                                        <div class="flex justify-end">
                                            <button @click.prevent="pushUninvoicedTransactions(user, user.transactions)" type="button" class="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                                <ClipboardCopyIcon class="transform -scale-x-1 -ml-0.5 h-5 w-5" aria-hidden="true" />
                                                Zafakturuj wszystkie
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr @click="log(transaction)" v-if="user.showTransactions && loaded" v-for="transaction in user.transactions" class="bg-gray-500">
                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6 ">
                                        <!-- <div class="flex items-center text-xs text-slate-100">
                                            <span v-tooltip="`test1`" @click.prevent="copyToClipboard('test1')" class="transition ease-in-out duration-300 cursor-pointer text-blue-300 hover:text-blue-400">Kopiuj</span>
                                        </div> -->
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        -
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        -
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 ">
                                        {{moment(`${transaction.date} ${transaction.time}`, "YYYYMMDD hh:mm:ss").format('LLL')}}
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        <div>
                                            {{transaction.productName}}
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        {{transaction.WbTankNetValue}} PLN
                                    </td>
                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 flex justify-end text-sm font-medium sm:pr-6 select-none">
                                        <button @click="transactionAlreadyInvoiced(transaction, user)" class="transition ease-in-out duration-300 bg-slate-600 hover:bg-slate-700 text-white font-semibold py-2 px-4 rounded w-56 flex justify-center">
                                            <div v-if="transaction.invoiced" class="spinner"></div>
                                            <div v-if="!transaction.invoiced" class="whitespace-nowrap">Oznacz jako zafakturowane</div>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <WarningUserModal v-if="WarningUserModal" @close="closeWarningUserModal" :modalData="warningUserModalData" :modalNotificationsData="warningUserModalNotificationsData"/>
    </div>
</template>

<script>
    import { HomeIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/solid'
    import { ClipboardCopyIcon } from '@heroicons/vue/outline'
    import axios from 'axios';
    import EmptyState from '@/components/EmptyState.vue';
    import {db} from "@/firebase/gfbconf.js"
    import LoaderData from '../components/LoaderData.vue';
    import BounceBall from '../components/BounceBallLoader.vue';
    import WarningUserModal from '../components/WarningUserModal.vue'
    import moment from 'moment'
    import currency from 'currency.js'
    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    export default {
        name: "ManageUsers",
        data()
        {
            return {
                showAddModal: false,
                pickedUsr: null,
                queryLoading: false,
                dbListener: null,
                lastLoaded: null,
                userList: {},
                date: [],
                sortTable: {
                    pickedColumn: null,
                    type: 'desc'
                },
                orlenData: null,
                loaded: false,
                uninvoicedTransactions: [],
                todayUnix: null,
                invoicesReady: false,
                WarningUserModal: false,
                warningUserModalData: null,
                warningUserModalNotificationsData: null,
                moment: moment
            }
        },
        components: {
            ClipboardCopyIcon, EmptyState, HomeIcon, ChevronDownIcon, ChevronUpIcon, LoaderData, BounceBall, WarningUserModal, VueDatePicker
        },
        async created()
        {
            moment.locale('pl')
            this.todayUnix = Math.floor(Date.now() / 1000)
            if(moment().subtract(1, 'months').unix() < 1711922400) {
                this.date = [moment().startOf('month').toDate(), moment().endOf('month').toDate()]
            } else {
                this.date = [moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()]
            }
            this.$store.commit('setUninvoicedTransactions', {
                user: null,
                uninvoiced: []
            })
            if(!this.$store.state.userData.permissions.admin)
            {
                this.$router.push("/hub")
            }
            await this.downloadUsers()
        },
        methods:
        {
            pushUninvoicedTransactions(user, transactions)
            {
                let data = {
                    user,
                    transactions
                };
                this.$store.commit('setUninvoicedTransactions', data)
                this.$router.push('/dashboard/createInvoice')
            },
            log(log){
                console.log(log)
            },
            datePickerFormat(date) {
                return moment(date[0]).format('DD/MM/YYYY') + ' -> ' + moment(date[1]).format('DD/MM/YYYY');
            },
            async transactionAlreadyInvoiced(transaction, user)
            {
                try {
                    transaction.invoiced = true
                    const res = await axios.post(`${this.$store.state.apiLink}/transactionAlreadyInvoiced`, {
                        transactionData: transaction
                    })
                    if(res.data.success)
                    {
                        for(let i = 0; i < user.transactions.length; i++) {
                            if(user.transactions[i].uniqueTransactionId === transaction.uniqueTransactionId) {
                                user.transactions.splice(i, 1)
                                
                            }
                        }
                        for(let i = 0; i < this.uninvoicedTransactions.length; i++) {
                            if(this.uninvoicedTransactions[i].userId === user.id) {
                                for(let y = 0; y < this.uninvoicedTransactions[i].transactions.length; y++) {
                                    if(this.uninvoicedTransactions[i].transactions[y].uniqueTransactionId === transaction.uniqueTransactionId) {
                                        this.uninvoicedTransactions[i].transactions.splice(y, 1)
                                    }
                                }
                                if(this.uninvoicedTransactions[i].transactions.length === 0) {
                                    this.uninvoicedTransactions.splice(i, 1)
                                }
                            }
                        }
                    }else{
                        alert('Nie udało się oznaczyć transakcji - sprawdź konsole')
                    }
                } catch(error) {
                    alert('Nie udało się oznaczyć transakcji - sprawdź konsole')
                }
            },
            async downloadUninvoicedTransactions(date) {
                this.loaded = false

                let dateData = []
                if(date != null) { 
                  dateData = [...date]
                  dateData[0] = moment(dateData[0]).unix()
                  dateData[1] = moment(dateData[1]).unix()
                }
                const res = await axios.post(`${this.$store.state.apiLink}/getUninvoicedTransactions`, {
                    users: [],
                    date: dateData
                })
                this.orlenData = res.data.orlenData
                if(res.data.success)
                {
                    if(res.data.preparedTransactions.length > 0)
                    {
                        for(let i = 0; i < Object.values(this.userList).length; i++) {
                            for(let y = 0; y < res.data.preparedTransactions.length; y++) {
                                if(Object.values(this.userList)[i].id === res.data.preparedTransactions[y].userId) {
                                    Object.values(this.userList)[i].transactions = []
                                    Object.values(this.userList)[i].transactions.push(...res.data.preparedTransactions[y].transactions)
                                    break
                                }
                            }
                        }
                    }
                    this.countPrices()
                } else{
                    console.log('Nie udało się pobrać transakcji - sprawdź konsole')
                }
            },
            closeWarningUserModal(){
                this.WarningUserModal = false
                this.warningUserModalData = null
                this.warningUserModalNotificationsData = null
            },
            openWarningUserModal(data, notifications){
                this.WarningUserModal = true
                this.warningUserModalData = data
                this.warningUserModalNotificationsData = notifications
            },
            copyToClipboard(id)
            {
                navigator.clipboard.writeText(id).then(()=>
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Skopiowano do schowka!",
                        subheader: ``,
                        success: true
                    }); 
                })

            },
            log(log){
                console.log(log)
            },
            async downloadUsers(){
                let checkTransactions = false
                if(this.uninvoicedTransactions.length > 0) {
                    checkTransactions = true
                }
                let query = ""
                    query = db.collection("UsersData")
                    if(this.sortTable.pickedColumn === 'name'){
                        query = query.orderBy("name", this.sortTable.type)
                    }
                    else if(this.sortTable.pickedColumn === 'businessName'){
                        query = query.orderBy("company.name", this.sortTable.type)
                    }
                    else if(this.sortTable.pickedColumn === 'cardGroup'){
                        query = query.orderBy("groupOfCards", this.sortTable.type)
                    }
                    else {
                        if(this.sortTable.pickedColumn !== null){
                            this.sortTable.pickedColumn = null
                            this.sortTable.type = 'desc'
                        }
                        query = query.orderBy("meta.created.unix", this.sortTable.type)
                    }
                this.dbListener = query
                .onSnapshot((querySnapshot) => {
                    this.queryLoading = true;
                    this.userList = {};
                    for(let i = 0; i < querySnapshot.docs.length; i++) {
                        let user = querySnapshot.docs[i].data()

                        user.notifications.sort((a,b)=>
                        {
                            if(a.created.unix > b.created.unix) return 1;
                            if(a.created.unix < b.created.unix) return -1;
                            return 0;
                        })
                        user.notifications.reverse()
                        let preparedUser = user
                        preparedUser.showTransactions = false
                        preparedUser.transactions = []
                        preparedUser.downloadingUninvoicedTransactions = false
                        preparedUser.key = user.id
                        if(checkTransactions) {
                            for(let y = 0; y < this.uninvoicedTransactions.length; y++) {
                                if(this.uninvoicedTransactions[y].userId === user.id) {
                                    preparedUser.transactions.push(...this.uninvoicedTransactions[y].transactions)
                                    break
                                }
                            }
                        }
                        this.userList[preparedUser.id] = preparedUser
                        this.lastLoaded = user.id;
                    }
                    if(!checkTransactions) {
                        this.downloadUninvoicedTransactions(this.date)
                    }
                    this.queryLoading = false;
                });
            },
            createPermissionsList(usr)
            {
                const permissions = Object.entries(usr.permissions);
                let translatedListOfPermissions = [];
                permissions.forEach(element => {
                    if(element[1] === true)
                    {
                        translatedListOfPermissions.push(element[0].toUpperCase());
                    }
                })

                return translatedListOfPermissions
            },
        async countPrices(){
                let res = await db.collection("ProductTranslations").doc('products').get()
                let productNames = res.data()
                for(let i = 0; i < Object.values(this.userList).length; i++) {
                    let polandTransactions = {
                        wbtank: {
                            adBlue: [],
                            diesel: [],
                            dieselVPower: [],
                            petrol95: [],
                            petrol95VPower: [],
                            petrol100Racing: [],
                            lpg: [],
                            others: []
                        },
                        economy: {
                            adBlue: [],
                            diesel: [],
                            dieselVPower: [],
                            petrol95: [],
                            petrol95VPower: [],
                            petrol100Racing: [],
                            lpg: [],
                            others: []
                        },
                        standard: {
                            adBlue: [],
                            diesel: [],
                            dieselVPower: [],
                            petrol95: [],
                            petrol95VPower: [],
                            petrol100Racing: [],
                            lpg: [],
                            others: []
                        },
                        highway: {
                            adBlue: [],
                            diesel: [],
                            dieselVPower: [],
                            petrol95: [],
                            petrol95VPower: [],
                            petrol100Racing: [],
                            lpg: [],
                            others: []
                        },
                        unassigned: {
                            others: []
                        }
                    }
                    let internationalTransactions = []
                    let currentUser = Object.values(this.userList)[i]
                    let transactions = currentUser.transactions
                    transactions.forEach(transaction => {
                        transaction.SiteGroupName = transaction.SiteGroupName.toUpperCase()
                            if(transaction.PurchasedInCountryCode === "PL"){
                                //TRANSACTIONS ON WBTANK SITE
                                if(transaction.SiteCode === '8452'){
                                    if((transaction.ProductName.toUpperCase()).includes('ADBLUE') && transaction.ProductCode === '38'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                            currentUser.discounts.adBlue.wbtank.forEach(discount => {
                                            if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                            }
                                            if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                            transaction.assignedDiscount = discount.amount
                                            }
                                        });
                                        polandTransactions.wbtank.adBlue.push(transaction)
                                    }
                                    else if((transaction.ProductName.toUpperCase()).includes('DIESEL') && transaction.ProductCode === '30'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                            currentUser.discounts.diesel.wbtank.forEach(discount => {
                                            if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                            }
                                            if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                            transaction.assignedDiscount = discount.amount
                                            transaction.assignedDiscountOrlenSpot = discount.orlenSpot
                                            }
                                        });
                                        polandTransactions.wbtank.diesel.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '33'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.dieselVPower.wbtank.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.wbtank.dieselVPower.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '22'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.petrol95.wbtank.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.wbtank.petrol95.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '20' || transaction.ProductCode === '26'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.petrol95VPower.wbtank.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.wbtank.petrol95VPower.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '24' || transaction.ProductCode === '27'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.petrol100Racing.wbtank.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.wbtank.petrol100Racing.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '34'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.lpg.wbtank.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.wbtank.lpg.push(transaction)
                                    }
                                    else{
                                        polandTransactions.wbtank.others.push(transaction)
                                    }
                                } // TRANSACTIONS ON ECONOMY SITE
                                else if(transaction.SiteGroupName.includes('ECONO')){
                                    if((transaction.ProductName.toUpperCase()).includes('ADBLUE') && transaction.ProductCode === '38'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                            currentUser.discounts.adBlue.economy.forEach(discount => {
                                            if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                            }
                                            if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                            transaction.assignedDiscount = discount.amount
                                            }
                                        });
                                        polandTransactions.economy.adBlue.push(transaction)
                                    }
                                    else if((transaction.ProductName.toUpperCase()).includes('DIESEL') && transaction.ProductCode === '30'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.diesel.economy.forEach(discount => {
                                            if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                            }
                                            if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                            transaction.assignedDiscount = discount.amount
                                            transaction.assignedDiscountOrlenSpot = discount.orlenSpot
                                            }
                                        });
                                        polandTransactions.economy.diesel.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '33'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.dieselVPower.economy.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.economy.dieselVPower.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '22'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.petrol95.economy.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.economy.petrol95.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '20' || transaction.ProductCode === '26'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.petrol95VPower.economy.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.economy.petrol95VPower.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '24' || transaction.ProductCode === '27'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.petrol100Racing.economy.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.economy.petrol100Racing.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '34'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.lpg.economy.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.economy.lpg.push(transaction)
                                    }
                                    else{
                                        polandTransactions.economy.others.push(transaction)
                                    }
                                } // TRANSACTIONS ON STANDARD SITE
                                else if(transaction.SiteGroupName.includes('DEFAUL') || transaction.SiteGroupName.includes('COVERA')){
                                    if((transaction.ProductName.toUpperCase()).includes('ADBLUE') && transaction.ProductCode === '38'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.adBlue.standard.forEach(discount => {
                                            if(discount.dateTo === null){
                                                discount.dateTo = {}
                                                discount.dateTo.seconds = moment().unix()
                                            }
                                            if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                            transaction.assignedDiscount = discount.amount
                                            }
                                        });
                                        polandTransactions.standard.adBlue.push(transaction)
                                    }
                                    else if((transaction.ProductName.toUpperCase()).includes('DIESEL') && transaction.ProductCode === '30'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                            currentUser.discounts.diesel.standard.forEach(discount => {
                                            if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                            }
                                            if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                            transaction.assignedDiscount = discount.amount
                                            transaction.assignedDiscountOrlenSpot = discount.orlenSpot
                                            }
                                        });
                                        polandTransactions.standard.diesel.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '33'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.dieselVPower.standard.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.standard.dieselVPower.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '22'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.petrol95.standard.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.standard.petrol95.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '20' || transaction.ProductCode === '26'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.petrol95VPower.standard.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.standard.petrol95VPower.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '24' || transaction.ProductCode === '27'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.petrol100Racing.standard.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.standard.petrol100Racing.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '34'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.lpg.standard.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                                transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.standard.lpg.push(transaction)
                                    }
                                    else{
                                        polandTransactions.standard.others.push(transaction)
                                    }
                                } // TRANSACTIONS ON MOTORWAY
                                else if(transaction.SiteGroupName.includes('MOTORWA')){
                                    // polandTransactions.highway.others.push(transaction)
                                    if((transaction.ProductName.toUpperCase()).includes('ADBLUE') && transaction.ProductCode === '38'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.adBlue.highway.forEach(discount => {
                                        if(discount.dateTo === null){
                                                discount.dateTo = {}
                                                discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                            transaction.assignedDiscount = discount.amount
                                        }
                                        });
                                        polandTransactions.highway.adBlue.push(transaction)
                                    }
                                    else if((transaction.ProductName.toUpperCase()).includes('DIESEL') && transaction.ProductCode === '30'){
                                        let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.diesel.highway.forEach(discount => {
                                        if(discount.dateTo === null){
                                            discount.dateTo = {}
                                            discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                            transaction.assignedDiscount = discount.amount
                                            transaction.assignedDiscountOrlenSpot = discount.orlenSpot
                                        }
                                        });
                                        polandTransactions.highway.diesel.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '33'){
                                    let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.dieselVPower.highway.forEach(discount => {
                                        if(discount.dateTo === null){
                                        discount.dateTo = {}
                                        discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                            transaction.assignedDiscount = discount.amount
                                        }
                                    });
                                    polandTransactions.highway.dieselVPower.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '22'){
                                    let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.petrol95.highway.forEach(discount => {
                                        if(discount.dateTo === null){
                                        discount.dateTo = {}
                                        discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                            transaction.assignedDiscount = discount.amount
                                        }
                                    });
                                    polandTransactions.highway.petrol95.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '20' || transaction.ProductCode === '26'){
                                    let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.petrol95VPower.highway.forEach(discount => {
                                        if(discount.dateTo === null){
                                        discount.dateTo = {}
                                        discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                            transaction.assignedDiscount = discount.amount
                                        }
                                    });
                                    polandTransactions.highway.petrol95VPower.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '24' || transaction.ProductCode === '27'){
                                    let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.petrol100Racing.highway.forEach(discount => {
                                        if(discount.dateTo === null){
                                        discount.dateTo = {}
                                        discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                            transaction.assignedDiscount = discount.amount
                                        }
                                    });
                                    polandTransactions.highway.petrol100Racing.push(transaction)
                                    }
                                    else if(transaction.ProductCode === '34'){
                                    let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                        currentUser.discounts.lpg.highway.forEach(discount => {
                                        if(discount.dateTo === null){
                                        discount.dateTo = {}
                                        discount.dateTo.seconds = moment().unix()
                                        }
                                        if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                            transaction.assignedDiscount = discount.amount
                                        }
                                    });
                                    polandTransactions.highway.lpg.push(transaction)
                                    }
                                    else{
                                        polandTransactions.highway.others.push(transaction)
                                    }
                                }else{
                                    polandTransactions.unassigned.others.push(transaction)
                                }

                            }else{
                                // IF INTERNATIONAL TRANSACTION assign VAT rate for invoice and VAT rate from transaction
                                transaction.VATRateFromTransaction = parseFloat(transaction.VATRate)
                                transaction.VATRate = parseFloat(0.23)
                                internationalTransactions.push(transaction)
                            }
                        if(productNames[transaction.ProductCode] !== undefined){
                            transaction.ProductName = productNames[transaction.ProductCode].wbtankName
                        }
                        if(transaction.DelcoName === 'Shell Polska sp. z o.o.'){
                            transaction.DelcoName = 'Shell Polska'
                        }
                    });

                    for (let pT = 0; pT < Object.keys(polandTransactions).length; pT++) {
                        const typeOfStation = Object.keys(polandTransactions)[pT];
                        for (let pN = 0; pN < Object.keys(polandTransactions[typeOfStation]).length; pN++) {
                        const productName = Object.keys(polandTransactions[typeOfStation])[pN];
                        polandTransactions[typeOfStation][productName].forEach(transaction => {
                            
                            // INVOICE DATA
                            let grossUnitPrice = null
                            let grossValue = null
                            let netValue = null
                            let netUnitPrice = null
                            let vatValue = null

                            // FOR DIESEL TRANSACTIONS WHEN ORLEN
                            let orlenDieselSpotPrice = null
                            let roundedOrlenDieselSpotPrice = null
                            let readyTransactionDate = null
                            let isBefore = null

                            if(productName === 'diesel'){
                            // tutaj koniec - przgotować pod kazdy rodzaj transakcji, przygotować discounty pod false itd...
                            orlenDieselSpotPrice = 0
                            readyTransactionDate = moment(transaction.TransactionDate, "YYYYMMDD").format("YYYY-MM-DD")
                            isBefore = moment(readyTransactionDate).isBetween(this.orlenData[0].effectiveDate)
                            if(isBefore){
                                orlenDieselSpotPrice = parseFloat(this.orlenData[0].value) / 1000
                            }else{
                                for (let oD = 0; oD < this.orlenData.length; oD++) {
                                if(this.orlenData[oD+1] !== undefined){
                                let first = this.orlenData[oD]
                                let second = this.orlenData[oD+1]
                                let firstDataDate = moment(first.effectiveDate).format("YYYY-MM-DD")
                                let secondDataDate = moment(second.effectiveDate).format("YYYY-MM-DD")
                                let isBetween = moment(readyTransactionDate).isBetween(secondDataDate, firstDataDate, undefined, '(]')
                                if(isBetween){
                                    if(readyTransactionDate === firstDataDate){
                                    orlenDieselSpotPrice = parseFloat(first.value) / 1000
                                    }else{
                                    orlenDieselSpotPrice = parseFloat(second.value) / 1000
                                    }
                                }
                                }
                                }
                            }
                            roundedOrlenDieselSpotPrice = currency(orlenDieselSpotPrice).value

                            // if orlenSpot true
                            // ORLEN 0% - false
                            // ORLEN +2% - null
                            // ORLEN - X = from -0.3 to -0.01
                            // ORLEN + X = from 0.01 to 0.05
                            // else
                            // SŁUP 0% - false
                            // SŁUP  +2% - null
                            // SŁUP - X = from -0.3 to -0.01
                            // SŁUP + X = from 0.01 to 0.05

                            if(transaction.DelcoRetailPriceUnitNet !== undefined && transaction.DelcoRetailPriceUnitNet !== null && parseFloat(transaction.DelcoRetailPriceUnitNet) !== 0)
                            {
                                if(transaction.assignedDiscount !== undefined){
                                if(transaction.assignedDiscountOrlenSpot === undefined){
                                    if(transaction.assignedDiscount === false){
                                    // SŁUP 0%
                                    grossUnitPrice = currency(currency(parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                    }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                                    // SŁUP +2%
                                    let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                                    grossUnitPrice = currency(currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * discountInPercentAsNumber, {precision: 4}).value, {precision: 2}).value
                                    }else{
                                    // ORLEN 0% - 0
                                    // ORLEN - X = od -0.3 do -0.01
                                    // ORLEN + X = od 0.01 do 0.05
                                    grossUnitPrice = currency(currency((roundedOrlenDieselSpotPrice + transaction.assignedDiscount) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                    }
                                }else if(transaction.assignedDiscountOrlenSpot === true){
                                    if(transaction.assignedDiscount === false){
                                    // orlen 0%
                                    grossUnitPrice = currency(currency((parseFloat(roundedOrlenDieselSpotPrice) * (parseFloat(transaction.VATRate) + 1)), {precision: 4}).value, {precision: 2}).value
                                    // grossUnitPrice = Math.round(((roundedOrlenDieselSpotPrice) * (parseFloat(transaction.VATRate) + 1)) * 100) / 100 
                                    }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                                    // orlen +2%
                                    let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                                    grossUnitPrice = currency(currency((parseFloat(roundedOrlenDieselSpotPrice) * (parseFloat(transaction.VATRate) + 1)) * discountInPercentAsNumber, {precision: 4}).value, {precision: 2}).value
                                    }else{
                                    // ORLEN - X = od -0.3 do -0.01
                                    // ORLEN + X = od 0.01 do 0.05
                                    grossUnitPrice = currency(currency(((roundedOrlenDieselSpotPrice + transaction.assignedDiscount) * (parseFloat(transaction.VATRate) + 1)), {precision: 4}).value, {precision: 2}).value
                                    }
                                }else if(transaction.assignedDiscountOrlenSpot === false){
                                    if(transaction.assignedDiscount === false){
                                    // SŁUP 0%
                                    grossUnitPrice = currency(currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)), {precision: 4}).value, {precision: 2}).value
                                    }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                                    // SŁUP +2%
                                    let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                                    grossUnitPrice = currency(currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * discountInPercentAsNumber, {precision: 4}).value, {precision: 2}).value
                                    }else{
                                    // SŁUP - X = od -0.3 do -0.01
                                    // SŁUP + X = od 0.01 do 0.05
                                    grossUnitPrice = currency(currency(parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1) + transaction.assignedDiscount, {precision: 4}).value, {precision: 2}).value
                                    }
                                }
                                }else{
                                grossUnitPrice = currency(currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * 1.02, {precision: 4}).value, {precision: 2}).value
                                }
                            }else{
                                if(transaction.assignedDiscount !== undefined){
                                if(transaction.assignedDiscountOrlenSpot === undefined){
                                    if(transaction.assignedDiscount === false){
                                    // SŁUP 0%
                                    grossUnitPrice = currency(currency((parseFloat(transaction.UnitPriceInTransactionCurrency)) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                    }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                                    // SŁUP +2%
                                    let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                                    grossUnitPrice = currency(currency((parseFloat(transaction.UnitPriceInTransactionCurrency) * discountInPercentAsNumber) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                    }else{
                                    // ORLEN 0% - 0
                                    // ORLEN - X = od -0.3 do -0.01
                                    // ORLEN + X = od 0.01 do 0.05
                                    grossUnitPrice = currency(currency((roundedOrlenDieselSpotPrice + transaction.assignedDiscount) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                    }
                                }else if(transaction.assignedDiscountOrlenSpot === true){
                                    if(transaction.assignedDiscount === false){
                                    // ORLEN 0%
                                    grossUnitPrice = currency(currency((parseFloat(roundedOrlenDieselSpotPrice)) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                    }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                                    // ORLEN +2%
                                    let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                                    grossUnitPrice = currency(currency((parseFloat(roundedOrlenDieselSpotPrice) * discountInPercentAsNumber) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                    }else{
                                    // ORLEN - X = od -0.3 do -0.01
                                    // ORLEN + X = od 0.01 do 0.05
                                    grossUnitPrice = currency(currency((roundedOrlenDieselSpotPrice + transaction.assignedDiscount) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                    }
                                }else if(transaction.assignedDiscountOrlenSpot === false){
                                    if(transaction.assignedDiscount === false){
                                    // SŁUP 0%
                                    grossUnitPrice = currency(currency((parseFloat(transaction.UnitPriceInTransactionCurrency)) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                    }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                                    // SŁUP +2%
                                    let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                                    grossUnitPrice = currency(currency((parseFloat(transaction.UnitPriceInTransactionCurrency) * discountInPercentAsNumber) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                    }else{
                                    // SŁUP - X = od -0.3 do -0.01
                                    // SŁUP + X = od 0.01 do 0.05
                                    grossUnitPrice = currency(currency(parseFloat(transaction.UnitPriceInTransactionCurrency) * (parseFloat(transaction.VATRate) + 1) + transaction.assignedDiscount, {precision: 4}).value, {precision: 2}).value
                                    }
                                }
                                }else{
                                grossUnitPrice = currency(currency((parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                }
                            }
                            }else if(productName === 'adBlue'){
                            // SŁUP 0% - false
                            // SŁUP +2% - null
                            // SŁUP - X - from 0.1 to 3.41
                            if(transaction.DelcoListPriceUnitNet !== undefined && transaction.DelcoListPriceUnitNet !== null && parseFloat(transaction.DelcoListPriceUnitNet) !== 0){
                                if(transaction.assignedDiscount !== undefined){
                                if(transaction.assignedDiscount === false){
                                    // SŁUP 0%
                                    grossUnitPrice = currency(currency(parseFloat(transaction.DelcoListPriceUnitNet) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                                    // SŁUP +2%
                                    let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                                    grossUnitPrice = currency(currency((parseFloat(transaction.DelcoListPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * discountInPercentAsNumber, {precision: 4}).value, {precision: 2}).value
                                }else{
                                    // SŁUP - X (x = discount)
                                    grossUnitPrice = currency(currency((parseFloat(transaction.DelcoListPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) - transaction.assignedDiscount, {precision: 4}).value, {precision: 2}).value
                                    // jeśli brutto + 2% - rabat:
                                    // grossUnitPrice = Math.round(((Math.round((parseFloat(transaction.DelcoListPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * 100) / 100 - transaction.assignedDiscount) * 1.02) * 100) / 100
                                }
                                }else{
                                grossUnitPrice = currency(currency((parseFloat(transaction.DelcoListPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * 1.02, {precision: 4}).value, {precision: 2}).value
                                }
                            }else{
                                grossUnitPrice = currency(currency((transaction.assignedDiscount !== undefined ? (transaction.UnitPriceInTransactionCurrency - transaction.assignedDiscount) * (parseFloat(transaction.VATRate) + 1)  : (parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1)), {precision: 4}).value, {precision: 2}).value
                            }
                            }else if(productName === 'others'){
                            if(transaction.DelcoRetailPriceUnitNet !== undefined && transaction.DelcoRetailPriceUnitNet !== null && parseFloat(transaction.DelcoRetailPriceUnitNet) !== 0){
                                grossUnitPrice = currency(currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * 1.02, {precision: 4}).value, {precision: 2}).value
                            }else{
                                grossUnitPrice = currency(currency((parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                            }
                            }else{
                            // PRODUCTS: dieselVPower, petrol95, petrol95VPower, petrol100Racing, lpg
                            // SŁUP 0% - false
                            // SŁUP +2% - null
                            // SŁUP - X = from -0.3 to -0.01
                            // SŁUP + X = from 0.01 to 0.05
                            if(transaction.DelcoRetailPriceUnitNet !== undefined && transaction.DelcoRetailPriceUnitNet !== null && parseFloat(transaction.DelcoRetailPriceUnitNet) !== 0){
                                if(transaction.assignedDiscount !== undefined){
                                if(transaction.assignedDiscount === false){
                                    // SŁUP 0%
                                    grossUnitPrice = currency(currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)), {precision: 4}).value, {precision: 2}).value
                                }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                                    // SŁUP +2%
                                    let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                                    grossUnitPrice = currency(currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * discountInPercentAsNumber, {precision: 4}).value, {precision: 2}).value
                                }else{
                                    // SŁUP - X (x = discount)
                                    grossUnitPrice = currency(currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1) + transaction.assignedDiscount), {precision: 4}).value, {precision: 2}).value
                                }
                                }else{
                                grossUnitPrice = currency(currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * 1.02, {precision: 4}).value, {precision: 2}).value
                                }
                            }else{
                                grossUnitPrice = currency(currency((transaction.assignedDiscount !== undefined ? (transaction.UnitPriceInTransactionCurrency - transaction.assignedDiscount) * (parseFloat(transaction.VATRate) + 1)  : (parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1)), {precision: 4}).value, {precision: 2}).value
                                if(transaction.assignedDiscount !== undefined){
                                if(transaction.assignedDiscount === false){
                                    // SŁUP 0%
                                    grossUnitPrice = currency(currency(parseFloat(transaction.UnitPriceInTransactionCurrency) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                                    // SŁUP +2%
                                    let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                                    grossUnitPrice = currency(currency((parseFloat(transaction.UnitPriceInTransactionCurrency) * discountInPercentAsNumber) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                }else{
                                    // SŁUP - X = from -0.3 to -0.01
                                    // SŁUP + X = from 0.01 to 0.05
                                    grossUnitPrice = currency(currency(transaction.UnitPriceInTransactionCurrency * (parseFloat(transaction.VATRate) + 1) + transaction.assignedDiscount, {precision: 4}).value, {precision: 2}).value
                                }
                                }else{
                                grossUnitPrice = currency(currency((parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                                }
                            }
                            }


                            // V2.1 - FIXED IF VAT FOR OWNER - TESTING (added real vatratefromtransaction)
                            if(transaction.Type !== 'SalesItem') {
                                let unitPrice = currency(currency((parseFloat(transaction.TransactionNetAmount) * (parseFloat(transaction.VATRate) + 1)) * 1.02, {precision: 4}).value, {precision: 2}).value
                                grossValue = unitPrice
                                netValue = transaction.TransactionNetAmount
                                netUnitPrice = netValue
                                vatValue = currency(currency(grossValue - netValue, {precision: 4}).value, {precision: 2}).value
                                transaction.Quantity = 1
                            } else {
                                grossValue = currency(currency(grossUnitPrice * parseFloat(transaction.Quantity), {precision: 4}).value, {precision: 2}).value
                                netValue = currency(currency(grossValue / (parseFloat(transaction.VATRate) * 100 + 100) * 100, {precision: 4}).value, {precision: 2}).value
                                netUnitPrice = currency(currency(netValue / parseFloat(transaction.Quantity), {precision: 4}).value, {precision: 2}).value
                                vatValue = currency(currency(grossValue - netValue, {precision: 4}).value, {precision: 2}).value
                            }
                            // count values based on grossUnitPrice

                            transaction.WbTankGrossValue = grossValue
                            transaction.WbTankNetValue = netValue
                            transaction.WbTankVATValue = currency(currency(grossValue - netValue, {precision: 4}).value, {precision: 2}).value
                            transaction.WbTankNetUnitPrice = netUnitPrice
                            transaction.WbTankGrossUnitPrice = grossUnitPrice


                        });
                        }
                    }

                    internationalTransactions.forEach(transaction => {
                        // V1 - WRONG  
                        // let grossUnitPrice = transaction.TransactionCurrencyCode !== 'PLN' ? Math.round(((parseFloat(transaction.UnitPriceInTransactionCurrency) * parseFloat(transaction.ColCoExchangeRate) * 1.02) * (parseFloat(transaction.VATRate) + 1)) * 100) / 100 : Math.round(((parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1)) * 100) / 100
                        
                        // V2 - WRONG IF VAT FOR OWNER
                        // let grossUnitPrice = transaction.TransactionCurrencyCode !== 'PLN' ? Math.round(((parseFloat(transaction.UnitPriceInInvoiceCurrency) * 1.02) * 1.23) * 100) / 100 : Math.round(((parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1)) * 100) / 100
                        
                        // V3 - WRONG IF CZK / FINE FOR EUR ONLY (?)
                        // let grossUnitPrice = transaction.TransactionCurrencyCode !== 'PLN' ? Math.round((((parseFloat(transaction.TransactionGrossAmount) / parseFloat(transaction.Quantity)) * parseFloat(transaction.ColCoExchangeRate)  * 1.02) * 1.23) * 100) / 100 : Math.round(((parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1)) * 100) / 100
                        
                        let grossUnitPrice = 0
                        let grossValue = 0
                        let netValue = 0
                        let netUnitPrice = 0
                        let vatValue = 0

                        // V2.1 - FIXED IF VAT FOR OWNER - TESTING (added real vatratefromtransaction)
                        if(transaction.Type !== 'SalesItem') {
                            grossUnitPrice = currency(currency((parseFloat(transaction.TransactionNetAmount) * (parseFloat(transaction.VATRate) + 1)) * 1.02, {precision: 4}).value, {precision: 2}).value
                            grossValue = grossUnitPrice
                            netValue = transaction.TransactionNetAmount
                            netUnitPrice = netValue
                            vatValue = currency(currency(grossValue - netValue, {precision: 4}).value, {precision: 2}).value
                            transaction.Quantity = 1
                        } else {
                            grossUnitPrice = transaction.TransactionCurrencyCode !== 'PLN' ? currency(currency((parseFloat(transaction.UnitPriceInInvoiceCurrency) * (parseFloat(transaction.VATRateFromTransaction) + 1) * 1.02) * 1.23, {precision: 4}).value, {precision: 2}).value : currency(currency((parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1), {precision: 4}).value, {precision: 2}).value
                            grossValue = currency(currency(grossUnitPrice * parseFloat(transaction.Quantity), {precision: 4}).value, {precision: 2}).value
                            netValue = currency(currency(grossValue / (parseFloat(transaction.VATRate) * 100 + 100) * 100, {precision: 4}).value, {precision: 2}).value
                            netUnitPrice = currency(currency(netValue / parseFloat(transaction.Quantity), {precision: 4}).value, {precision: 2}).value
                            vatValue = currency(currency(grossValue - netValue, {precision: 4}).value, {precision: 2}).value
                        }

                        transaction.WbTankGrossValue = grossValue  
                        transaction.WbTankNetValue = netValue
                        transaction.WbTankVATValue = currency(currency(grossValue - netValue, {precision: 4}).value, {precision: 2}).value
                        transaction.WbTankNetUnitPrice = netUnitPrice
                        transaction.WbTankGrossUnitPrice = grossUnitPrice
                    });
                    this.pricesLoaded = true
                    this.loaded = true   
                }

            },
        },
        beforeUnmount() {
            if(this.dbListener !== null)
            {
                this.dbListener();
            }  
        }
    }
</script>
<style lang="scss" scoped>
  .spinner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3.8px solid #d6d6d6;
    animation: spinner-bulqg1 1.1199999999999999s infinite linear alternate,
          spinner-oaa3wk 2.2399999999999998s infinite linear;
  }

  @keyframes spinner-bulqg1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }

    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
  }

  @keyframes spinner-oaa3wk {
    0% {
        transform: scaleY(1) rotate(0deg);
    }

    49.99% {
        transform: scaleY(1) rotate(135deg);
    }

    50% {
        transform: scaleY(-1) rotate(0deg);
    }

    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
  }
</style>