<template>
    <div>
        <div class="flex justify-between">
            <nav class="hidden sm:flex h-8" aria-label="Breadcrumb">
                <ol role="list" class="flex space-x-4 rounded-md bg-slate-500 px-6 shadow">
                    <li class="flex">
                        <div class="flex items-center">
                        <router-link to="/hub" class="transition ease-in-out duration-300 text-slate-100 hover:text-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span class="sr-only">Home</span>
                        </router-link>
                        </div>
                    </li>
                    <li class="flex">
                        <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <div class="ml-4 text-xs font-medium text-slate-100 opacity-60 cursor-default select-none" aria-current="page">Upomnienia</div>
                        </div>
                    </li>
                </ol>
            </nav>
            <div class="flex items-center sm:hidden">
                <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 text-sm font-medium text-purple-300 hover:text-purple-400" aria-current="page">&larr; Cofnij</router-link>
            </div>
            <!-- <div class="flex items-center">
                DEVELOPER: 
                <div class="flex items-center">
                    <button type="button" @click.prevent="allTestInvoice()" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">Fakturowanie testowe</button>
                </div>
                <div class="flex items-center">
                    <button type="button" @click.prevent="allRealInvoice()" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">Fakturowanie aktywne</button>
                </div>
            </div> -->
        </div>
          <div class="mt-4">
            <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <!-- <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
            </select> -->
            </div>
        
        <div class="flex flex-col mx-4 lg:px-0" >
            <div class="-my-2 -mx-4 overflow-x-auto">
                <div class="inline-block min-w-full py-2 align-middle">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg" v-if="!queryLoading">
                        <table class="min-w-full divide-y divide-slate-700">
                            <thead class="bg-slate-700">
                                <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold whitespace-nowrap text-slate-100 sm:pl-6">
                                    <div class="flex">
                                        ID
                                        <ChevronDownIcon @click.prevent="sortTable.pickedColumn = null, sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === null && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== null }" aria-hidden="true" />
                                        <ChevronUpIcon @click.prevent="sortTable.pickedColumn = null, sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === null && sortTable.type === 'desc' || sortTable.pickedColumn !== null" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== null }" aria-hidden="true" />
                                    </div>
                                    </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                    <div class="flex">
                                        Firma
                                        <ChevronDownIcon @click.prevent="sortTable.pickedColumn = 'businessName', sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === 'businessName' && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'businessName' }" aria-hidden="true" />
                                        <ChevronUpIcon @click.prevent="sortTable.pickedColumn = 'businessName', sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === 'businessName' && sortTable.type === 'desc' || sortTable.pickedColumn !== 'businessName'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'businessName' }" aria-hidden="true" />
                                    </div>
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                    <div class="flex">
                                        Imię Nazwisko 
                                        <ChevronDownIcon @click.prevent="sortTable.pickedColumn = 'name', sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === 'name' && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'name' }" aria-hidden="true" />
                                        <ChevronUpIcon @click.prevent="sortTable.pickedColumn = 'name', sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === 'name' && sortTable.type === 'desc' || sortTable.pickedColumn !== 'name'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'name' }" aria-hidden="true" />
                                    </div>
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">E-mail</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Telefon</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                    <div class="flex">
                                        Zaległe faktury
                                        <div v-if="invoicesReady">
                                            <ChevronDownIcon @click.prevent="sortTable.pickedColumn = 'debt', sortTable.type = 'desc', sortDebtUsers()" v-if="sortTable.pickedColumn === 'debt' && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'debt' }" aria-hidden="true" />
                                            <ChevronUpIcon @click.prevent="sortTable.pickedColumn = 'debt', sortTable.type = 'asc', sortDebtUsers()" v-if="sortTable.pickedColumn === 'debt' && sortTable.type === 'desc' || sortTable.pickedColumn !== 'debt'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'debt' }" aria-hidden="true" />
                                        </div>
                                        <div v-if="!invoicesReady" class="ml-3">
                                            <BounceBall ></BounceBall>
                                        </div>
                                    </div>
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                        <div class="flex">
                                            <Menu as="div" class="relative inline-block text-left">
                                                <div>
                                                <MenuButton class="transition ease-in-out duration-300 inline-flex w-full justify-center gap-x-1.5 font-medium rounded-md bg-slate-600 px-2 py-1 text-sm text-slate-100 shadow-sm ring-1 ring-inset ring-slate-800 hover:bg-slate-500">
                                                    Menu
                                                    <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </MenuButton>
                                                </div>

                                                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                                <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div class="py-1">
                                                    <MenuItem v-slot="{ active }">
                                                        <div @click.prevent="generateDelayedInvoicesList()" :class="[active && invoicesReady ? 'bg-gray-100 text-gray-900' : 'text-gray-700', !invoicesReady ? 'opacity-50 pointer-events-none' : '' , 'block px-4 py-2 text-sm cursor-pointer']">{{!invoicesReady ? 'Trwa pobieranie faktur...' : 'Zestawienie zaległych faktur'}}</div>
                                                    </MenuItem>
                                                    </div>
                                                </MenuItems>
                                                </transition>
                                            </Menu>
                                            <!-- <button type="button" @click.prevent="testInvoice()" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">Test faktur</button>
                                            <button type="button" @click.prevent="removeTestInvoice()" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">U. test. f.</button> -->
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            <tbody class="divide-y divide-slate-700 bg-slate-500">
                                <tr @click="log(user)" v-for="user in userList" :key="user.id">
                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                        <div class="flex items-center text-xs text-slate-100">
                                            <!-- {{user.id}} -->
                                            <span v-tooltip="`${user.id}`" @click.prevent="copyToClipboard(user.id)" class="transition ease-in-out duration-300 cursor-pointer text-blue-300 hover:text-blue-400">Kopiuj</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                        <!-- {{user.name}} {{user.surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                            <div class="flex items-center">
                                                <div v-tooltip="`${user.company.name}`" class="rounded-md focus:outline-none">{{user.company.name.length > 0 ? user.company.name.length < 20 ? user.company.name : user.company.name.substring(0,20) + '...' : '--'}}</div>
                                                <div v-if="!user.activeInvoices" v-tooltip="`FAKTUROWANIE TESTOWE`" class="ml-2 cursor-default flex justify-center border-white border px-1 rounded-full text-xs bg-red-500"><span class="text-white pt-px">T</span></div>
                                            </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                        <!-- {{user.name}} {{user.surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                         <router-link target="_blank" class="cursor-pointer transition-all duration-300 hover:text-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" :to="'/dashboard/user?id=' + user.id">{{user.name}} {{user.surname}}</router-link>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 ">
                                        <a :href="`mailto:${user.contact.email}`" alt="Napisz e-mail" class="cursor-pointer transition-all duration-300 hover:text-indigo-300 underline rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">{{user.contact.email}}</a>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        <div >
                                            <a :href="`tel:${user.contact.phone}`" alt="Zadzwoń" class="cursor-pointer transition-all duration-300 hover:text-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 underline">{{user.contact.phone}}</a>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        <div >
                                            <LoaderData v-if="!user.invoices.loaded" class="mt-2 mb-2"></LoaderData>
                                            <span v-else :class="[user.invoices.delayedInvoices.length > 0 ? 'text-red-400' : 'text-green-400']">{{user.invoices.delayedInvoices.length}} ({{user.invoices.debt.toFixed(2)}} PLN)</span>
                                        </div>
                                    </td>
                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 select-none">
                                        <!-- <div class="flex items-center"> -->
                                            <!-- <div @click="testInvoice(user)" class="transition ease-in-out duration-300 mr-2 text-sm text-blue-400 hover:text-blue-300 cursor-pointer">generuj fakture</div> -->
                                        <button v-if="user.invoices.loaded" type="button" @click.prevent="openWarningUserModal(user.invoices, user.notifications)" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Szczegóły</button>
                                        <LoaderData v-if="!user.invoices.loaded" class="mt-2 mb-2"></LoaderData>
                                        <!-- <div v-if="user.agreement === undefined" @click="update(user)" class="transition ease-in-out duration-300 mr-2 text-sm text-blue-400 hover:text-blue-300 cursor-pointer">updateuser</div>
                                        </div> -->
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                        <EmptyState v-if="userList.length === 0"></EmptyState>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <WarningUserModal v-if="WarningUserModal" @close="closeWarningUserModal" :modalData="warningUserModalData" :modalNotificationsData="warningUserModalNotificationsData"/>
    </div>
</template>

<script>
    import { HomeIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/solid'
    import axios from 'axios';
    import EmptyState from '@/components/EmptyState.vue';
    import {db} from "@/firebase/gfbconf.js"
    import LoaderData from '../components/LoaderData.vue';
    import BounceBall from '../components/BounceBallLoader.vue';
    import WarningUserModal from '../components/WarningUserModal.vue'
    import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
    import moment from 'moment'
    import pdfMake from "pdfmake/build/pdfmake";
    import pdfFonts from "pdfmake/build/vfs_fonts";
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    export default {
        name: "ManageUsers",
        data()
        {
            return {
                showAddModal: false,
                pickedUsr: null,
                queryLoading: false,
                dbListener: null,
                lastLoaded: null,
                userList: [],
                sortTable: {
                    pickedColumn: null,
                    type: 'desc'
                },
                todayUnix: null,
                invoicesReady: false,
                WarningUserModal: false,
                warningUserModalData: null,
                warningUserModalNotificationsData: null
            }
        },
        components: {EmptyState,HomeIcon,ChevronDownIcon, ChevronUpIcon, LoaderData, BounceBall, WarningUserModal, Menu, MenuButton, MenuItem, MenuItems},
        async created()
        {
            this.todayUnix = Math.floor(Date.now() / 1000)
            if(!this.$store.state.userData.permissions.admin)
            {
                this.$router.push("/hub")
            }
            this.downloadUsers()
        },
        methods:
        {
            generateDelayedInvoicesList(){
                let amountOfDelayedInvoices = 0
                let valueOfDelayedInvoices = 0

                let contentOfUsers = []
                for(let i = 0; i < this.userList.length; i++)
                {
                    let userValueOfDelayedInvoices = 0
                    amountOfDelayedInvoices += this.userList[i].invoices.delayedInvoices.length
                    valueOfDelayedInvoices += this.userList[i].invoices.debt
                    if(this.userList[i].invoices.delayedInvoices.length > 0)
                    {
                        let userDelayedInvoices = []
                        for(let j = 0; j < this.userList[i].invoices.delayedInvoices.length; j++)
                        {
                            let invoice = this.userList[i].invoices.delayedInvoices[j]
                            let invoiceDueDate = invoice.paymentDate
                            let invoiceValue = invoice.valueOfInvoice.toFixed(2)
                            let invoiceNumber = invoice.invoiceName
                            let invoiceInfo = "Przekroczono termin płatności"
                            let invoiceRow = [
                                {
                                    text: `${j+1}`,
                                    border: [false, true, true, true],
                                    margin: [-9, 5, -5, 5],
                                    alignment: 'right',
                                    fontSize: 8,
                                },
                                {
                                    text: `${invoiceNumber}`,
                                    border: [true, true, true, true],
                                    fillColor: '#f5f5f5',
                                    margin: [-9, 5, -9, 5],
                                    alignment: 'center',
                                    fontSize: 8,
                                },
                                {
                                    text: `${invoiceDueDate}`,
                                    border: [true, true, true, true],
                                    fillColor: '#f5f5f5',
                                    margin: [-9, 5, -9, 5],
                                    alignment: 'center',
                                    fontSize: 8,
                                },
                                {
                                    text: `${invoiceValue} PLN`,
                                    border: [true, true, true, true],
                                    fillColor: '#f5f5f5',
                                    margin: [-9, 5, -9, 5],
                                    alignment: 'center',
                                    fontSize: 8,
                                },
                                {
                                    text: `${invoiceInfo}`,
                                    border: [true, true, true, true],
                                    fillColor: '#f5f5f5',
                                    color: '#ff0000',
                                    margin: [-9, 5, -9, 5],
                                    alignment: 'center',
                                    fontSize: 8,
                                },
                            ]
                            userValueOfDelayedInvoices += parseFloat(invoice.valueOfInvoice)
                            userDelayedInvoices.push(invoiceRow)
                        }
                        let contentOfUser = {
                            stack :[
                            {
                            layout: {
                                defaultBorder: false,
                                hLineWidth: function(i, node) {
                                return 1;
                                },
                                vLineWidth: function(i, node) {
                                return 1;
                                },
                                hLineColor: function(i, node) {
                                if (i === 1 || i === 0) {
                                    return '#90c3d6';
                                }else if ( i === 2){
                                    return '#bfdde8';
                                }
                                return '#eaeaea';
                                },
                                vLineColor: function(i, node) {
                                return '#eaeaea';
                                },
                                hLineStyle: function(i, node) {
                                // if (i === 0 || i === node.table.body.length) {
                                return null;
                                //}
                                },
                                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                paddingLeft: function(i, node) {
                                return 10;
                                },
                                paddingRight: function(i, node) {
                                return 10;
                                },
                                paddingTop: function(i, node) {
                                return 2;
                                },
                                paddingBottom: function(i, node) {
                                return 2;
                                },
                                fillColor: function(rowIndex, node, columnIndex) {
                                return '#fff';
                                },
                            },
                            table: {
                                headerRows: 2,
                                dontBreakRows: true,
                                widths: [1,80,30,60,'*'],
                                body: [
                                    [
                                    {
                                    text: `${this.userList[i].company.name}`,
                                    border: [false, true, true, true],
                                    alignment: 'center',
                                    fillColor: '#c7e1eb',
                                    margin: [-9, 6, -9, 5],
                                    textTransform: 'uppercase',
                                    fontSize: 11,
                                    bold: true,
                                    colSpan: 5
                                    },
                                    {},
                                    {},
                                    {},
                                    {},
                                ],
                                    [
                                    {
                                    text: 'Lp.',
                                    border: [false, true, true, true],
                                    alignment: 'center',
                                    fillColor: '#eaf2f5',
                                    margin: [-9, 6, -9, 5],
                                    textTransform: 'uppercase',
                                    fontSize: 8,
                                    },
                                    {
                                    text: 'Numer faktury',
                                    fillColor: '#eaf2f5',
                                    alignment: 'center',
                                    border: [true, true, true, true],
                                    margin: [-9, 6, -9, 5],
                                    textTransform: 'uppercase',
                                    fontSize: 8,
                                    },
                                    {
                                    text: 'Płatne do',
                                    fillColor: '#eaf2f5',
                                    alignment: 'center',
                                    border: [true, true, true, true],
                                    margin: [-9, 6, -9, 5],
                                    textTransform: 'uppercase',
                                    fontSize: 8,
                                    },
                                    {
                                    text: 'Należności',
                                    border: [true, true, true, true],
                                    alignment: 'center',
                                    fillColor: '#eaf2f5',
                                    margin: [-9, 6, -9, 5],
                                    textTransform: 'uppercase',
                                    fontSize: 8,
                                    },
                                    {
                                    text: 'Informacje',
                                    border: [true, true, true, true],
                                    alignment: 'center',
                                    fillColor: '#eaf2f5',
                                    margin: [-9, 6, -9, 5],
                                    textTransform: 'uppercase',
                                    fontSize: 8,
                                    },
                                ],
                                    ...userDelayedInvoices,
                                    [
                                        {
                                        text: 'Łącznie należności opóźnionych',
                                        bold: true,
                                        fontSize: 12,
                                        alignment: 'right',
                                        border: [false, false, false, true],
                                        margin: [0, 5, 0, 5],
                                        colSpan: 4,
                                        },
                                        {},
                                        {},
                                        {},
                                        {
                                            text: `${userValueOfDelayedInvoices.toFixed(2)} PLN`,
                                            bold: true,
                                            fontSize: 12,
                                            alignment: 'right',
                                            border: [false, false, false, true],
                                            fillColor: '#f5f5f5',
                                            margin: [0, 5, 0, 5],
                                        },
                                    ],
                                    
                                ],
                            },
                            },
                            ],
                            unbreakable: true,
                            margin: [0,0,0,30]
                        }
                        contentOfUsers.push(contentOfUser)
                    }
                }

                let docDefinition = {
                    pageSize: 'A4',
                    content: [
                    {
                    columns: [
                        [
                        {
                            text: 'Zestawienie faktur zaległych',
                            color: '#333333',
                            width: '*',
                            fontSize: 16,
                            bold: true,
                            alignment: 'center',
                            margin: [0, 10, 0, 10],
                        }
                        ],
                    ],
                    },
                    contentOfUsers,
                { stack:[
                    {
                    layout: {
                        defaultBorder: false,
                        hLineWidth: function(i, node) {
                        return 1;
                        },
                        vLineWidth: function(i, node) {
                        return 1;
                        },
                        hLineColor: function(i, node) {
                        if (i === 1 || i === 0) {
                            return '#d6d6d6';
                        }
                        return '#eaeaea';
                        },
                        vLineColor: function(i, node) {
                        return '#eaeaea';
                        },
                        hLineStyle: function(i, node) {
                        // if (i === 0 || i === node.table.body.length) {
                        return null;
                        //}
                        },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        paddingLeft: function(i, node) {
                        return 10;
                        },
                        paddingRight: function(i, node) {
                        return 10;
                        },
                        paddingTop: function(i, node) {
                        return 3;
                        },
                        paddingBottom: function(i, node) {
                        return 3;
                        },
                        fillColor: function(rowIndex, node, columnIndex) {
                        return '#fff';
                        },
                    },
                    table: {
                        headerRows: 1,
                        dontBreakRows: true,
                        widths: ['66%', '34%'],
                        body: [
                        [
                            {
                            text: 'Podsumowanie ogólne',
                            border: [false, true, true, true],
                            alignment: 'center',
                            fillColor: '#e3e3e3',
                            margin: [-9, 6, -9, 5],
                            textTransform: 'uppercase',
                            fontSize: 11,
                            bold: true,
                            colSpan: 2
                            },
                            {
                            },
                        ],
                        [
                            {
                            text: 'Ilość faktur zaległych',
                            border: [false, true, false, true],
                            alignment: 'right',
                            margin: [0, 5, 0, 5],
                            },
                            {
                            border: [false, true, false, true],
                            text: `${amountOfDelayedInvoices}`,
                            alignment: 'right',
                            fillColor: '#f5f5f5',
                            margin: [0, 5, 0, 5],
                            },
                        ],
                        [
                            {
                            text: 'Wartość faktur zaległych',
                            bold: true,
                            fontSize: 16,
                            alignment: 'right',
                            border: [false, false, false, true],
                            margin: [0, 5, 0, 5],
                            },
                            {
                            text: `${valueOfDelayedInvoices.toFixed(2)} PLN`,
                            bold: true,
                            fontSize: 16,
                            alignment: 'right',
                            border: [false, false, false, true],
                            fillColor: '#f5f5f5',
                            margin: [0, 5, 0, 5],
                            },
                        ],
                        ],
                    },
                    },
                    ],
                    unbreakable: true
                    }


                ],
                styles: {
                },
                defaultStyle: {
                    columnGap: 20,
                    //font: 'Quicksand',
                },
    }
                pdfMake.createPdf(docDefinition).download(`Zestawienie_zaleglych_faktur_na_dzien_${moment().format('L')}.pdf`);
            },
            closeWarningUserModal(){
                this.WarningUserModal = false
                this.warningUserModalData = null
                this.warningUserModalNotificationsData = null
            },
            openWarningUserModal(data, notifications){
                this.WarningUserModal = true
                this.warningUserModalData = data
                this.warningUserModalNotificationsData = notifications
            },
            sortDebtUsers(){
                if(this.sortTable.pickedColumn === 'debt'){
                    this.userList.sort((a, b) => parseFloat(a.invoices.debt) - parseFloat(b.invoices.debt));
                    if(this.sortTable.type === 'desc'){
                        this.userList.reverse()
                    }
                }
            },
            copyToClipboard(id)
            {
                navigator.clipboard.writeText(id).then(()=>
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Skopiowano do schowka!",
                        subheader: ``,
                        success: true
                    }); 
                })

            },
            log(log){
                console.log(log)
            },
            async downloadUsers(){
                let query = ""
                    query = db.collection("UsersData")
                    if(this.sortTable.pickedColumn === 'name'){
                        query = query.orderBy("name", this.sortTable.type)
                    }
                    else if(this.sortTable.pickedColumn === 'businessName'){
                        query = query.orderBy("company.name", this.sortTable.type)
                    }
                    else if(this.sortTable.pickedColumn === 'cardGroup'){
                        query = query.orderBy("groupOfCards", this.sortTable.type)
                    }
                    else {
                        if(this.sortTable.pickedColumn !== null){
                            this.sortTable.pickedColumn = null
                            this.sortTable.type = 'desc'
                        }
                        query = query.orderBy("meta.created.unix", this.sortTable.type)
                    }
                this.dbListener = query
                .onSnapshot((querySnapshot) => {
                    this.queryLoading = true;
                    this.userList = [];
                    querySnapshot.forEach((doc) => {
                        let user = doc.data()
                        user.notifications.sort((a,b)=>
                        {
                            if(a.created.unix > b.created.unix) return 1;
                            if(a.created.unix < b.created.unix) return -1;
                            return 0;
                        })
                        user.notifications.reverse()
                        let preparedUser = user
                        preparedUser.invoices = {
                            loaded: false,
                            debt: 0,
                            invoices: [],
                            delayedInvoices: []
                        }
                        this.userList.push(preparedUser)
                        this.lastLoaded = user.id;
                    });
                    this.queryLoading = false;
                    this.downloadInvoices()
                });
            },
async downloadInvoices() {
  this.invoicesReady = false;

  // Wywołaj funkcję backendową i przekaż listę użytkowników
  try{
    const res = await axios.post(`${this.$store.state.apiLink}/downloadDelayedInvoices`, {
        userList: this.userList
    })
    if(res.data.success)
    {
        for(let i = 0; i < res.data.userList.length; i++)
        {
            if(this.userList[i].id === res.data.userList[i].id)
            {
                this.userList[i].invoices = res.data.userList[i].invoices
            }
        }
        // this.userList = res.data.userList
        // console.log(res.data.userList)
        this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona sukcesem!",
            subheader: "Pomyślnie pobrano upomnienia.",
            success: true
        });
    }
    else
    {
        this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: "Coś poszło nie tak..",
            success: false
        });
    }
}
catch(error)
{
    console.log(error);
    this.$store.commit('setNotification',{
    show: true,
    head: "Akcja zakończona niepowodzeniem!",
    subheader: "Coś poszło nie tak..",
    success: false
    });
}

  this.invoicesReady = true;
},
            createPermissionsList(usr)
            {
                const permissions = Object.entries(usr.permissions);
                let translatedListOfPermissions = [];
                permissions.forEach(element => {
                    if(element[1] === true)
                    {
                        translatedListOfPermissions.push(element[0].toUpperCase());
                    }
                })

                return translatedListOfPermissions
            },
        },
        beforeUnmount() {
            if(this.dbListener !== null)
            {
                this.dbListener();
            }  
        }
    }
</script>
<style lang="scss" scoped>

</style>