<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900"> Podaj zapłaconą kwotę </DialogTitle>
                    <div class="mt-2">
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <input onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 44 || event.charCode === 46' :disabled="inputDisabled" v-model.number="paidAmount" @change="validateInput" @blur="validateInput" type="text" name="price" id="price" class="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" aria-describedby="price-currency" />
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <span class="text-gray-500 sm:text-sm" id="price-currency">/ {{ calculateNeededAmount().toFixed(2) }} zł</span>
                            </div>
                        </div>
                        <div class="relative flex items-start mt-3">
                            <div class="flex h-5 items-center">
                                <input v-model="fullAmountChecked" id="paid-full-amount" aria-describedby="paid-full-amount" name="paid-full-amount" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                            </div>
                            <div class="ml-3 text-sm">
                                <label for="paid-full-amount" class="font-medium text-gray-700">Zapłacone w całości</label>
                            </div>
                        </div>
                        <div class="relative mt-1 rounded-md shadow-sm">
                          <div class="block text-sm font-medium leading-6 text-gray-700 mt-2">Data wpłaty:</div>
                          <VueDatePicker :format="dateFormat" auto-apply locale="pl" v-model="paidDate" :enable-time-picker="false" :clearable="true" />
                        </div>
                        <div class="flex flex-col gap-2 mt-2">
                          <div class="font-medium text-gray-700">Płatności</div>
                          <div class="flex flex-col gap-2">
                            <div v-for="(deposit, index) in deposits" :key="index" class="flex items-center gap-2">
                              <label class="text-sm font-medium text-gray-700"><span class="font-bold text-green-500">{{deposit.amount.toFixed(2)}} zł</span> - {{moment.unix(deposit.date).format('DD.MM.YYYY')}}</label>
                              <TrashIcon class="h-5 w-5 text-red-500 cursor-pointer" @click="deleteDeposit(index)" />
                            </div>
                            <div v-if="deposits.length === 0">
                              <p class="text-sm font-medium text-red-500">Brak wpłat</p>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <div class="mt-2 ml-8 whitespace-nowrap">
                        <p class="text-sm mb-3">Wartość faktury: <span class="font-bold text-yellow-500">{{modalData.valueOfInvoice.toFixed(2)}} zł</span></p>
                        <p class="text-sm mb-3">Zapłacono: <span class="font-bold text-green-500">{{modalData.paidAmount.toFixed(2)}} zł</span></p>
                        <p class="text-sm mb-3">Pozostało: <span class="font-bold text-red-500">{{(modalData.valueOfInvoice - modalData.paidAmount).toFixed(2)}} zł</span></p>
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <!-- <button type="button" :disabled="(paidDate == null || paidDate.length == 0)" :class="[(paidDate == null || paidDate.length == 0) ? 'bg-gray-200 text-base font-medium pointer-events-none' : 'bg-green-500 text-base font-medium hover:bg-green-600 focus:ring-green-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm']" @click="emitAccept">Oznacz</button> -->
              <button type="button" :disabled="parseFloat(paidAmount) > 0 && (paidDate == null || typeof paidDate == 'string')" :class="[parseFloat(paidAmount) > 0 && (paidDate == null || typeof paidDate == 'string') ? 'bg-gray-200 text-base font-medium pointer-events-none' : 'bg-green-500 text-base font-medium hover:bg-green-600 focus:ring-green-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm']" @click="emitAccept">Oznacz</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Anuluj</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { TrashIcon } from '@heroicons/vue/solid'
import VueDatePicker from '@vuepic/vue-datepicker';
import moment from 'moment'
import '@vuepic/vue-datepicker/dist/main.css'
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    VueDatePicker,
    TrashIcon
  },
  props: ["modalData"],
  data() {
    return {
        paidAmount: 0,
        fullAmountChecked: false,
        inputDisabled: false,
        paidDate: '',
        deposits: [],
        moment: moment
    }
  },
  methods: {
    calculateNeededAmount() {
      let paidAmount = 0
      for(let i = 0; i < this.deposits.length; i++){
        paidAmount += this.deposits[i].amount
      }
      return (this.modalData.valueOfInvoice - paidAmount)
    },
    deleteDeposit(index){
      this.deposits.splice(index, 1)
    },
    dateFormat() {
        let date = ''
        date = `${moment(this.paidDate).format('DD.MM.YYYY')}`
        return date;
    },
    emitClose()
    {
        this.paidAmount = 0;
        this.inputDisabled = false;
        this.fullAmountChecked = false;
        this.$emit("closeModal");
    },
    emitAccept()
    {
        if(typeof this.paidAmount !== 'number') return
        this.paidAmount = this.paidAmount.toFixed(2)
        this.paidAmount = parseFloat(this.paidAmount)
        this.$emit("accept", {
            valueOfInvoice: this.modalData.valueOfInvoice,
            paymentDate: this.modalData.paymentDate,
            paidAmount: this.paidAmount,
            invoiceId: this.modalData.id,
            deposits: this.deposits,
            paidDate: this.paidDate
        });
        this.emitClose()
    },
    close()
    {
        this.emitClose();
        this.open = false
    },
    validateInput(){
        if(this.paidAmount > this.calculateNeededAmount()){
            this.paidAmount = parseFloat(this.calculateNeededAmount().toFixed(2))
        }

        if(this.paidAmount < 0 || isNaN(this.paidAmount)) this.paidAmount = 0
    }
  },
  computed: {
    initialPaidAmount(){
      return this.modalData?.paidAmount
    },
  },
  created()
  {
    if(this.modalData.deposits != null) this.deposits = this.modalData.deposits;
    if(this.modalData.paidAmount === this.modalData.valueOfInvoice)
    {
      this.fullAmountChecked = true;
    }
    else
    {
      this.fullAmountChecked = false;
    }
  },
  watch: {
    fullAmountChecked(value){
        if(value){
            this.paidAmount = parseFloat(this.calculateNeededAmount().toFixed(2))
            this.inputDisabled = true
        } else {
            this.paidAmount= ""
            this.inputDisabled = false
        }
    },
    initialPaidAmount(value){
      this.paidAmount = value
    },
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  }
}
</script>

<style scoped>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

</style>
