<template>
        <div class="">
            <div class="">
                <div class="bounceball"></div>
            </div>
        </div>
</template>
<style lang="scss" scoped>

$width: 7.5px;
$height: 7.5px;
$bounce_height: 10px;


.bounceball {
  position: relative;
  display: inline-block;
  margin-bottom: 9px;
  height: 5px;
  width: $width;
  &:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    width: $width;
    height: $height;
    border-radius: 50%;
    background-color: #6b6b6b;
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
  }
}

@keyframes bounce {
  0% {
    top: $bounce_height;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: $height;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}
</style>