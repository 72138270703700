<template>
    <div>
        <div class="flex justify-between">
            <nav class="hidden sm:flex h-8" aria-label="Breadcrumb">
                <ol role="list" class="flex space-x-4 rounded-md bg-slate-500 px-6 shadow">
                    <li class="flex">
                        <div class="flex items-center">
                        <router-link to="/hub" class="transition ease-in-out duration-300 text-slate-100 hover:text-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span class="sr-only">Home</span>
                        </router-link>
                        </div>
                    </li>
                    <li class="flex">
                        <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <div class="ml-4 text-xs font-medium text-slate-100 opacity-60 cursor-default select-none" aria-current="page">Użytkownicy</div>
                        </div>
                    </li>
                </ol>
                <!-- <div @click.prevent="copyUsers()">Copy users</div> -->
                <!-- <div @click.prevent="updateInvoicesWithDueDate()">test</div> -->
                <!-- <div @click.prevent="createAmountOfInvoicesInMonth()">AmountOfInvoicesInMonth</div> -->
                <!-- <input type="file" @change="handleFileUpload($event)" accept=".csv" class="cursor-pointer w-64 relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-10 trasition-all duration-300" /> -->
            </nav>
            <div class="flex items-center sm:hidden">
                <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 text-sm font-medium text-purple-300 hover:text-purple-400" aria-current="page">&larr; Cofnij</router-link>
            </div>
            <!-- <div class="flex items-center">
                <div class="flex items-center">
                    <button type="button" @click.prevent="allTestInvoice()" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">Fakturowanie testowe</button>
                </div>
                <div class="flex items-center">
                    <button type="button" @click.prevent="allRealInvoice()" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">Fakturowanie aktywne</button>
                </div>
                <div class="flex items-center">
                    <button type="button" @click.prevent="testPeriods()" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">TEST OKRSOW</button>
                </div>
            </div> -->
            <div class="flex items-center">
                <button type="button" @click.prevent="showAddModal = true" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">Dodaj użytkownika</button>
            </div>
        </div>
        <!-- <button type="button" @click.prevent="addProduct()" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">Dodaj produkt</button> -->
          <div class="mt-4">
            <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <!-- <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
            </select> -->
            </div>
            <div v-if="$store.state.userData.permissions.superAdmin" class="block">
            <nav class="isolate flex divide-x divide-slate-700 rounded-t-lg shadow" aria-label="Tabs">
                <div @click.prevent="changeList('clients')" :class="[clientList ? 'text-slate-100 bg-slate-900 pointer-events-none' : 'text-slate-300 bg-slate-800 hover:bg-slate-900 hover:text-slate-100', 'transition ease-in-out duration-200 rounded-tl-lg group select-none relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-sm font-medium text-center focus:z-10']">
                <span> Lista klientów </span>
                <span aria-hidden="true" :class="[clientList ? 'bg-indigo-300' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                </div>
                <div @click.prevent="changeList('workers')" :class="[!clientList ? 'text-slate-100 bg-slate-900 pointer-events-none' : 'text-slate-300 bg-slate-800 hover:bg-slate-900 hover:text-slate-100', 'transition ease-in-out duration-200 rounded-tr-lg group select-none relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-sm font-medium text-center focus:z-10']">
                <span> Lista pracowników </span>
                <span aria-hidden="true" :class="[!clientList ? 'bg-indigo-300' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                </div>
            </nav>
            </div>
        
        <ModalManageUser :show="showManageModal" :managedUsr="pickedUsr" @reloadUser="reloadUserInModal" @closeClientModal="closeClientModal" v-if="pickedUsr !== null"></ModalManageUser>
        <ModalNewUser v-if="showAddModal" :show="showAddModal" @closeClientModal="showAddModal = false"></ModalNewUser>
        <div class="flex flex-col mx-4 lg:px-0" >
            <div class="-my-2 -mx-4 overflow-x-auto">
                <div class="inline-block min-w-full py-2 align-middle">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-b-lg" v-if="!queryLoading">
                        <table v-if="!clientList" class="min-w-full divide-y divide-slate-700">
                            <thead class="bg-slate-700">
                                <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold whitespace-nowrap text-slate-100 sm:pl-6">ID</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Imię Nazwisko</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">E-mail</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Telefon</th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span class="sr-only">Edit</span>
                                </th>
                                </tr>
                            </thead>

                            <tbody class="divide-y divide-slate-700 bg-slate-500">
                                <tr v-for="user in userList" :key="user.id">
                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                        <div class="flex items-center text-xs text-slate-100">
                                            {{user.id}}
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                        <!-- {{user.name}} {{user.surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                         <router-link target="_blank" class="cursor-pointer transition-all duration-300 hover:text-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" :to="'/dashboard/user?id=' + user.id">{{user.name}} {{user.surname}}</router-link>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 ">
                                        <a :href="`mailto:${user.contact.email}`" alt="Napisz e-mail" class="cursor-pointer transition-all duration-300 hover:text-indigo-300 underline rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">{{user.contact.email}}</a>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        <div >
                                            <a :href="`tel:${user.contact.phone}`" alt="Zadzwoń" class="cursor-pointer transition-all duration-300 hover:text-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 underline">{{user.contact.phone}}</a>
                                        </div>
                                    </td>
                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 select-none">
                                        <button type="button" @click.prevent="showManageModal = true, pickedUsr = user" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Zarządzaj</button>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                        <table v-if="clientList" class="min-w-full divide-y divide-slate-700">
                            <thead class="bg-slate-700">
                                <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold whitespace-nowrap text-slate-100 sm:pl-6">
                                    <div class="flex">
                                        ID
                                        <ChevronDownIcon @click.prevent="sortTable.pickedColumn = null, sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === null && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== null }" aria-hidden="true" />
                                        <ChevronUpIcon @click.prevent="sortTable.pickedColumn = null, sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === null && sortTable.type === 'desc' || sortTable.pickedColumn !== null" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== null }" aria-hidden="true" />
                                    </div>
                                    </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                    <div class="flex">
                                        Firma
                                        <ChevronDownIcon @click.prevent="sortTable.pickedColumn = 'businessName', sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === 'businessName' && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'businessName' }" aria-hidden="true" />
                                        <ChevronUpIcon @click.prevent="sortTable.pickedColumn = 'businessName', sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === 'businessName' && sortTable.type === 'desc' || sortTable.pickedColumn !== 'businessName'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'businessName' }" aria-hidden="true" />
                                    </div>
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                    <div class="flex">
                                        Imię Nazwisko 
                                        <ChevronDownIcon @click.prevent="sortTable.pickedColumn = 'name', sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === 'name' && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'name' }" aria-hidden="true" />
                                        <ChevronUpIcon @click.prevent="sortTable.pickedColumn = 'name', sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === 'name' && sortTable.type === 'desc' || sortTable.pickedColumn !== 'name'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'name' }" aria-hidden="true" />
                                    </div>
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">E-mail</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Telefon</th>
                                <!-- <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                    <div class="flex">
                                        Grupa kart
                                        <ChevronDownIcon @click.prevent="sortTable.pickedColumn = 'cardGroup', sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === 'cardGroup' && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'cardGroup' }" aria-hidden="true" />
                                        <ChevronUpIcon @click.prevent="sortTable.pickedColumn = 'cardGroup', sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === 'cardGroup' && sortTable.type === 'desc' || sortTable.pickedColumn !== 'cardGroup'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'cardGroup' }" aria-hidden="true" />
                                    </div>
                                </th> -->
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                    <div class="flex">
                                        Koniec umowy
                                        <ChevronDownIcon @click.prevent="sortTable.pickedColumn = 'dateOfAgreement', sortTable.type = 'desc', downloadUsers()" v-if="sortTable.pickedColumn === 'dateOfAgreement' && sortTable.type === 'asc'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'dateOfAgreement' }" aria-hidden="true" />
                                        <ChevronUpIcon @click.prevent="sortTable.pickedColumn = 'dateOfAgreement', sortTable.type = 'asc', downloadUsers()" v-if="sortTable.pickedColumn === 'dateOfAgreement' && sortTable.type === 'desc' || sortTable.pickedColumn !== 'dateOfAgreement'" :class="{'h-5 w-5 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 hover:text-blue-400': true, 'opacity-25': sortTable.pickedColumn !== 'dateOfAgreement' }" aria-hidden="true" />
                                    </div>
                                </th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span class="sr-only">Edit</span>
                                </th>
                                </tr>
                            </thead>

                            <tbody class="divide-y divide-slate-700 bg-slate-500">
                                <tr v-for="user in userList" :key="user.id">
                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                        <div class="flex items-center text-xs text-slate-100">
                                            <!-- {{user.id}} -->
                                            <span v-tooltip="`${user.id}`" @click.prevent="copyToClipboard(user.id)" class="transition ease-in-out duration-300 cursor-pointer text-blue-300 hover:text-blue-400">Kopiuj</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                        <!-- {{user.name}} {{user.surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                            <div class="flex items-center">
                                                <div v-tooltip="`${user.company.name}`" :class="['rounded-md focus:outline-none', user.company.name.length > 0 ? user.company.name.length < 60 ? 'text-xs' : 'text-xxs' : 'text-xs']">{{user.company.name.length > 0 ? user.company.name.length < 80 ? user.company.name : user.company.name.substring(0,80) + '...' : '--'}}</div>
                                                <div v-if="!user.activeInvoices" v-tooltip="`FAKTUROWANIE TESTOWE`" class="ml-2 cursor-default flex justify-center border-white border px-1 rounded-full text-xs bg-red-500"><span class="text-white pt-px">T</span></div>
                                            </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                        <!-- {{user.name}} {{user.surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                         <router-link target="_blank" class="cursor-pointer transition-all duration-300 hover:text-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" :to="'/dashboard/user?id=' + user.id">{{user.name}} {{user.surname}}</router-link>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 ">
                                        <!-- <a :href="`mailto:${user.contact.email}`" alt="Napisz e-mail" class="cursor-pointer transition-all duration-300 hover:text-indigo-300 underline rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">{{user.contact.email}}</a> -->
                                        <span v-tooltip="`${user.contact.email}`" @click.prevent="copyToClipboard(user.contact.email)" class="transition ease-in-out duration-300 cursor-pointer text-blue-300 hover:text-blue-400">Kopiuj</span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        <div >
                                            <a :href="`tel:${user.contact.phone}`" alt="Zadzwoń" class="cursor-pointer transition-all duration-300 hover:text-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 underline">{{user.contact.phone}}</a>
                                        </div>
                                    </td>
                                    <!-- <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                         <div class="rounded-md focus:outline-none">{{user.groupOfCards}}</div>
                                    </td> -->
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                        <!-- {{user.name}} {{user.surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                         <div :class="['rounded-md focus:outline-none', user.agreement === undefined || user.agreement.pickedDateOfAgreementUnix === null ? 'text-slate-400' : user.agreement.pickedDateOfAgreementUnix < this.todayUnix ? 'text-red-400' : user.agreement.pickedDateOfAgreementUnix > (this.todayUnix + 604800) ? 'text-green-400' : 'text-yellow-400']">{{user.agreement !== undefined && user.agreement.pickedDateOfAgreementUnix !== null ? changeDateFormat(user.agreement.pickedDateOfAgreementUnix) : 'Brak danych'}}</div>
                                    </td>
                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 select-none">
                                        <div class="flex items-center gap-2">
                                            <!-- <div v-if="user.id === '46G2zmxi4mSJrlD9XCgM8hsMdBg1'" @click="singleInvoice(user)" class="transition ease-in-out duration-300 mr-2 text-sm text-blue-400 hover:text-blue-300 cursor-pointer">generuj fakture</div> -->
                                            <!-- <div @click="copyUserDiscounts(user)" class="transition ease-in-out duration-300 mr-2 text-sm text-blue-400 hover:text-blue-300 cursor-pointer">kopiuj znizki</div> -->
                                            <!-- <div v-if="checkIfHaveAnyDiscountNull(user)" @click="changeAllDiscountsWithNull(user)" class="transition ease-in-out duration-300 mr-2 text-sm text-blue-400 hover:text-blue-300 cursor-pointer">Aktualizuj rabaty</div> -->
                                            <!-- <button @click="userLoginUrl(user.id, user)" :disabled="user.automaticLoading" class="inline-flex w-16 h-8 items-center justify-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"> -->
                                            <button v-tooltip="`Zaloguj`" @click="userLoginUrl(user.id, user)" :disabled="user.automaticLoading" class="cursor-pointer">
                                                <div v-if="user.automaticLoading" class="spinner"></div>
                                                <!-- <div v-else>Zaloguj</div> -->
                                                <div v-else>
                                                    <LoginIcon :class="{'h-6 w-6 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 text-slate-200 hover:text-blue-400': true }" aria-hidden="true" />
                                                </div>
                                            </button>
                                            <!-- PencilAltIcon, LoginIcon -->
                                            <!-- <button type="button" @click.prevent="showManageModal = true, pickedUsr = user" class="inline-flex h-8 items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"> -->
                                            <button v-tooltip="`Zarządzaj`" type="button" @click.prevent="showManageModal = true, pickedUsr = user" class="cursor-pointer">
                                                <!-- Zarządzaj -->
                                                <PencilAltIcon :class="{'h-6 w-6 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 text-slate-200 hover:text-blue-400': true }" aria-hidden="true" />
                                            </button>
                                            <div v-if="user.amountOfInvoicesInMonth === undefined" class="transition ease-in-out duration-300 mr-2 text-sm text-blue-400 hover:text-blue-300 cursor-pointer">updateuser</div>
                                            <!-- <div  @click="update(user)" class="transition ease-in-out duration-300 mr-2 text-sm text-blue-400 hover:text-blue-300 cursor-pointer">updateuser</div> -->
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                        <EmptyState v-if="userList.length === 0"></EmptyState>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false">
                <button type="button" @click.prevent="loadMoreUsers(10)" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Załaduj więcej..</button>
                <!-- <button type="button" @click.prevent="loadMoreUsers(1000)" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Załaduj wszystkie..</button> -->
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    import { HomeIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/solid'
    import { PencilAltIcon, LoginIcon } from '@heroicons/vue/outline'
    import axios from 'axios';
    import EmptyState from '@/components/EmptyState.vue';
    import ModalNewUser from '@/components/ModalNewUser.vue';
    import ModalManageUser from '@/components/ModalManageUser.vue';
    import Papa from 'papaparse';
    import {db} from "@/firebase/gfbconf.js"
    import moment from 'moment'
    export default {
        name: "ManageUsers",
        data()
        {
            return {
                clientList: true,
                showManageModal: false,
                showAddModal: false,
                pickedUsr: null,
                file: '',
                content: null,
                queryLoading: false,
                queryLimitReached: false,
                dbListener: null,
                lastLoaded: null,
                userList: [],
                queryLimit: 9999,
                sortTable: {
                    pickedColumn: null,
                    type: 'desc'
                },
                todayUnix: null,
                invoicesGenerating: false,
            }
        },
        components: {ModalNewUser,EmptyState,ModalManageUser,HomeIcon,ChevronDownIcon, ChevronUpIcon,PencilAltIcon, LoginIcon},
        async created()
        {
            this.todayUnix = Math.floor(Date.now() / 1000)
            if(!this.$store.state.userData.permissions.admin)
            {
                this.$router.push("/hub")
            }
            this.downloadUsers()
        },
        methods:
        {
            checkIfHaveAnyDiscountNull(user){
                let copyOfDiscounts = JSON.parse(JSON.stringify(user.discounts))
                for(let i = 0; i < Object.keys(copyOfDiscounts).length; i++) {
                    let disconutProduct = Object.keys(copyOfDiscounts)[i]
                    for(let j = 0; j < Object.keys(copyOfDiscounts[disconutProduct]).length; j++) {
                        let placeOfDiscount = Object.keys(copyOfDiscounts[disconutProduct])[j]
                        for(let k = 0; k < copyOfDiscounts[disconutProduct][placeOfDiscount].length; k++) {
                            let discount = copyOfDiscounts[disconutProduct][placeOfDiscount][k]
                            if(discount.amount === '2%'){
                                return true
                            }
                        }
                    }
                }
                return false
            },
            async changeAllDiscountsWithNull(user){
                //copy of user.discounts
                let copyOfDiscounts = JSON.parse(JSON.stringify(user.discounts))
                //copyOfDiscounts is an object
                for(let i = 0; i < Object.keys(copyOfDiscounts).length; i++) {
                    let disconutProduct = Object.keys(copyOfDiscounts)[i]
                    //copyOfDiscounts[disconutProduct] is an object
                    for(let j = 0; j < Object.keys(copyOfDiscounts[disconutProduct]).length; j++) {
                        let placeOfDiscount = Object.keys(copyOfDiscounts[disconutProduct])[j]
                        console.log('Rabaty dla produktu: ' + disconutProduct + ' w miejscu: ' + placeOfDiscount + ' wynoszą: ')
                        //copyOfDiscounts[disconutProduct][placeOfDiscount] is an array
                        for(let k = 0; k < copyOfDiscounts[disconutProduct][placeOfDiscount].length; k++) {
                            let discount = copyOfDiscounts[disconutProduct][placeOfDiscount][k]
                            // discount.dateFrom = moment.unix(discount.dateFrom.seconds)
                            discount.dateFrom = new Date(discount.dateFrom.seconds * 1000)
                            if(discount.dateTo !== null) {
                                // discount.dateTo = moment.unix(discount.dateTo.seconds)
                                discount.dateTo = new Date(discount.dateTo.seconds * 1000)
                            }
                            if(discount.amount === '2%'){
                                discount.amount = '-2.5%'
                            }
                        
                        }
                    }
                }
                console.log(copyOfDiscounts);
                await db.collection("UsersData").doc(user.id).update({ discounts: copyOfDiscounts });
            },
            async updateInvoicesWithDueDate() {
                let getInvoices = await db.collection("Invoices").where('paid', '==', true).get()
                let invoiceNumber = 0
                for(let i = 0; i < getInvoices.docs.length; i++) {
                    let invoice = getInvoices.docs[i].data()
                    if(invoice.deposits == null) {
                        invoiceNumber++
                        
                    }
                }
                console.log(invoiceNumber);
            },
            async getInvoicesWithoutDeposits() {
                let invoiceHeader = {
                    index: 'L.p.',
                    invoiceNumber: 'Numer faktury',
                    invoiceDate: 'Data wystawienia',
                    payerName: 'Nazwa płatnika',
                };

                const csvBadInvoices = [
                    invoiceHeader
                ];
    

                let getInvoices = await db.collection("Invoices").where("paidAmount", ">=", 0).get()
                for(let i = 0; i < getInvoices.docs.length; i++) {
                    let invoice = getInvoices.docs[i].data()
                    if(invoice.deposits == null || invoice.deposits.length === 0) {
                        let invoiceObject = {
                            index: i+1,
                            invoiceNumber: invoice.invoiceName,
                            invoiceDate: invoice.invoiceDeliveryDate,
                            payerName: invoice.payerName,
                        };
                        csvBadInvoices.push(invoiceObject)
                    }
                }

                const badCsvdata = this.csvmaker(csvBadInvoices);
                this.download(badCsvdata, 'pozostałe_faktury');
            },
            handleFileUpload(event)
            {
            if(event.target.files[0] !== undefined)
            {
                this.file = event.target.files[0];
                this.parseFile();
            }
            else
            {
                this.content = null;
            }
            },
            parseFile() {
                Papa.parse( this.file, {
                    header: true,
                    skipEmptyLines: true,
                    delimiter: ",",
                    complete: function( results ){
                        this.content = results;
                        this.parsed = true;
                        this.parseBankStatement()
                    }.bind(this)
                } );
            },
            async parseBankStatement() {
                let invoices = {}
                let badInvoices = {}
                for(let i = 0; i < this.content.data.length; i++) {
                    let row = this.content.data[i];
                    let preparedText = row._2.replace(/\s/g, '').toLowerCase();
                    let amount = parseFloat(row['Kwota'])

                    let invoiceNumber = null
                    let yearNumber = null
                    if(preparedText.indexOf("/la/es/") != -1) {
                        invoiceNumber = preparedText.slice(preparedText.indexOf("/la/es/")-4, preparedText.indexOf("/la/es/")).replace(/[^0-9]/g, '');
                        yearNumber = preparedText.slice(preparedText.indexOf("/la/es/")+7, preparedText.indexOf("/la/es/")+9);
                    }
                    if(invoiceNumber !== null && yearNumber !== null) {
                        let preparedInvoiceNumber = `FA${parseFloat(invoiceNumber) < 100 ? '00'+parseFloat(invoiceNumber) : parseFloat(invoiceNumber) > 100 && parseFloat(invoiceNumber) < 1000 ? '0'+parseFloat(invoiceNumber) : parseFloat(invoiceNumber)}/LA/ES/${yearNumber}`
                        if(row[''].includes('LAURON')) {
                            badInvoices[preparedInvoiceNumber] = {
                                boolean: true,
                                date: row['Data operacji'],
                                payerName: row[''],
                                amount: amount
                            }
                            continue
                        }
                        if(amount < 0) {
                            badInvoices[preparedInvoiceNumber] = {
                                boolean: true,
                                date: row['Data operacji'],
                                payerName: row[''],
                                amount: amount
                            }
                            continue
                        }
                        if(badInvoices[preparedInvoiceNumber] != null && badInvoices[preparedInvoiceNumber].boolean === true) {
                            continue
                        }
                        // console.log(invoiceNumber);
                        // console.log(yearNumber);
                        // console.log(preparedInvoiceNumber);
                        // console.log(amount);
                        if(invoices[preparedInvoiceNumber] == null) {
                            invoices[preparedInvoiceNumber] = {
                                amount: amount,
                                deposits: [{ amount: amount, date: moment(row['Data operacji'], 'YYYY-MM-DD').unix() }],
                                date: row['Data operacji'],
                                payerName: row['']
                            }
                        } else {
                            invoices[preparedInvoiceNumber].amount += amount
                            invoices[preparedInvoiceNumber].deposits.push({ amount: amount, date: moment(row['Data operacji'], 'YYYY-MM-DD').unix() })
                        }
                    }
                }
                let updatedInvoices = {}
                for(let i = 0; i < Object.keys(invoices).length; i++) {
                    let invoiceNumber = Object.keys(invoices)[i]
                    let invoiceData = invoices[invoiceNumber]
                    let invoiceNumberNew = invoiceNumber.toLowerCase().slice(invoiceNumber.toLowerCase().indexOf("/la/es/")-4, invoiceNumber.toLowerCase().indexOf("/la/es/")).replace(/[^0-9]/g, '');
                    let yearNumber = invoiceNumber.toLowerCase().slice(invoiceNumber.toLowerCase().indexOf("/la/es/")+7, invoiceNumber.toLowerCase().indexOf("/la/es/")+9);

                    let invoiceNewNumber1 = 'FA'+parseFloat(invoiceNumberNew)+'/LA/ES/'+yearNumber
                    let invoiceNewNumber2 = 'FA00'+parseFloat(invoiceNumberNew)+'/LA/ES/'+yearNumber

                    let usedInvoiceNumber = invoiceNumber
                    let dbData = []
                    dbData = (await db.collection("Invoices").where('invoiceName', '==', invoiceNumber).get()).docs
                    if(dbData.length === 0) {
                        dbData = (await db.collection("Invoices").where('invoiceName', '==', invoiceNewNumber1).get()).docs
                        usedInvoiceNumber = invoiceNewNumber1
                    }
                    if(dbData.length === 0) {
                        dbData = (await db.collection("Invoices").where('invoiceName', '==', invoiceNewNumber2).get()).docs
                        usedInvoiceNumber = invoiceNewNumber2
                    }

                    if(dbData.length === 0) {
                        console.log("Invoice not found in database", invoiceNumber);
                        badInvoices[invoiceNumber] = {
                            boolean: true,
                            date: invoiceData.date,
                            payerName: invoiceData.payerName,
                            amount: invoiceData.amount
                        }
                    } else {
                        console.log("Invoice found in database", usedInvoiceNumber);
                        let invoiceDbData = dbData[0].data()
                        if(invoiceData.amount == invoiceDbData.paidAmount) {
                            console.log("Invoice amount is the same", usedInvoiceNumber);
                            console.log(invoiceData);
                            // db.collection("Invoices").doc(dbData[0].id).update({ deposits: invoiceData.deposits })
                            updatedInvoices[usedInvoiceNumber] = true
                        } else {
                            console.log("Invoice amount is different", usedInvoiceNumber);
                            badInvoices[usedInvoiceNumber] = {
                                boolean: true,
                                date: invoiceData.date,
                                payerName: invoiceData.payerName,
                                amount: invoiceData.amount
                            }
                        }
                    }
                    console.log("");
                }
                let invoiceHeader = {
                    index: 'L.p.',
                    invoiceNumber: 'Numer faktury',
                    date: 'Data operacji',
                    payerName: 'Nazwa płatnika',
                    amount: 'Kwota'
                };

                const csvBadInvoices = [
                    invoiceHeader
                ];

                const csvGoodInvoices = [
                    invoiceHeader
                ];

                for(let i = 0; i < Object.keys(badInvoices).length; i++) {
                    console.log(badInvoices[Object.keys(badInvoices)[i]]);
                    let invoice = {
                        index: i+1,
                        invoiceNumber: Object.keys(badInvoices)[i],
                        date: badInvoices[Object.keys(badInvoices)[i]].date,
                        payerName: badInvoices[Object.keys(badInvoices)[i]].payerName,
                        amount: badInvoices[Object.keys(badInvoices)[i]].amount
                    };
                    csvBadInvoices.push(invoice)
                }

                for(let i = 0; i < Object.keys(updatedInvoices).length; i++) {
                    let invoice = {
                        index: i+1,
                        invoiceNumber: Object.keys(updatedInvoices)[i],
                    };
                    csvGoodInvoices.push(invoice)
                }
    
                const badCsvdata = this.csvmaker(csvBadInvoices);
                const goodCsvdata = this.csvmaker(csvGoodInvoices);
                this.download(badCsvdata, 'błędne_faktury');
                this.download(goodCsvdata, 'zauktualizowane_faktury');

                console.log(Object.keys(updatedInvoices).length, 'updated length');
                console.log(Object.keys(badInvoices).length, 'bad length');
            },
            csvmaker(data)
            {
                let csvRows = [];
                for(let i=0;i<data.length; i++)
                {
                    const values = Object.values(data[i]).join(',');
                    csvRows.push(values)
                }
                return csvRows.join('\n')
            },
            download(data, name)
            {
                const blob = new Blob([data], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.setAttribute('href', url)
                a.setAttribute('download', `Logi_${name}.csv`);
                a.click()
            },
            async createAmountOfInvoicesInMonth(){
                const usersSnapshot = await db.collection("UsersData").get();

                usersSnapshot.docs.forEach(async (doc) => {
                    await db.collection("UsersData").doc(doc.id).update({ amountOfInvoicesInMonth: 4 });
                });
            },
            async copyUserDiscounts(user) {
                console.log(user);
                let getUser = await db.collection("UsersData").where("id", "!=", user.id).where("groupOfCards", "==", user.groupOfCards).get();
                if(getUser.docs.length > 0) {
                    let cardOwner = getUser.docs[0].data()
                    db.collection("UsersData").doc(user.id).update({ discounts: cardOwner.discounts });
                }
            },
            testPeriods(){
                // Funckja działająca na wybranej ilości dat generowania - do weryfikacji
                let period = null
                let FromDate = null
                let ToDate = null
                let deliveryDate = ""
                let userGeneratingPeriods = 1 // Możesz zmienić tę wartość na 1, 2 lub 4
                let currentDay = 7

                if(userGeneratingPeriods === 1){
                    if(currentDay >= 1 && currentDay <= 7){
                        FromDate = moment().subtract(1, 'months').format("YYYYMM") + "01"
                        let lastDayOfPreviousMonth = moment().subtract(1, 'months').endOf('month').format("DD")
                        ToDate = moment().subtract(1, 'months').format("YYYYMM") + `${lastDayOfPreviousMonth}`
                    }else{
                        FromDate = null
                        ToDate = null
                    }
                } else if(userGeneratingPeriods === 2){
                    if(currentDay >= 1 && currentDay <= 7){
                        FromDate = moment().subtract(1, 'months').format("YYYYMM") + "17"
                        let lastDayOfPreviousMonth = moment().subtract(1, 'months').endOf('month').format("DD")
                        ToDate = moment().subtract(1, 'months').format("YYYYMM") + `${lastDayOfPreviousMonth}`
                    } else if(currentDay >= 17 && currentDay <= 23){
                        FromDate = moment().format("YYYYMM") + "01"
                        ToDate = moment().format("YYYYMM") + "16"
                    }else{
                        FromDate = null
                        ToDate = null
                    }
                } else if(userGeneratingPeriods === 4){
                    if(currentDay >= 1 && currentDay <= 7){
                        FromDate = moment().subtract(1, 'months').format("YYYYMM") + "24"
                        let lastDayOfPreviousMonth = moment().subtract(1, 'months').endOf('month').format("DD")
                        ToDate = moment().subtract(1, 'months').format("YYYYMM") + `${lastDayOfPreviousMonth}`
                    } else if(currentDay >= 8 && currentDay <= 16){
                        FromDate = moment().format("YYYYMM") + "01"
                        ToDate = moment().format("YYYYMM") + "07"
                    } else if(currentDay >= 17 && currentDay <= 23){
                        FromDate = moment().format("YYYYMM") + "08"
                        ToDate = moment().format("YYYYMM") + "16"
                    } else if(currentDay >= 24 && currentDay <= 31){
                        FromDate = moment().format("YYYYMM") + "17"
                        ToDate = moment().format("YYYYMM") + "23"
                    }else{
                        FromDate = null
                        ToDate = null
                    }
                }
                if(FromDate !== null && ToDate !== null){
                    period = moment(FromDate, 'YYYYMMDD').format("DD-MM-YYYY") + ' - ' +  moment(ToDate, 'YYYYMMDD').format("DD-MM-YYYY")
                    deliveryDate = moment(ToDate, 'YYYYMMDD').format("DD.MM.YYYY")
                }else{
                    period = null
                    deliveryDate = null
                }

                currentDay = 16
                if(userGeneratingPeriods === 1){
                    if(currentDay >= 1 && currentDay <= 7){
                        FromDate = moment().subtract(1, 'months').format("YYYYMM") + "01"
                        let lastDayOfPreviousMonth = moment().subtract(1, 'months').endOf('month').format("DD")
                        ToDate = moment().subtract(1, 'months').format("YYYYMM") + `${lastDayOfPreviousMonth}`
                    }else{
                        FromDate = null
                        ToDate = null
                    }
                } else if(userGeneratingPeriods === 2){
                    if(currentDay >= 1 && currentDay <= 7){
                        FromDate = moment().subtract(1, 'months').format("YYYYMM") + "17"
                        let lastDayOfPreviousMonth = moment().subtract(1, 'months').endOf('month').format("DD")
                        ToDate = moment().subtract(1, 'months').format("YYYYMM") + `${lastDayOfPreviousMonth}`
                    } else if(currentDay >= 17 && currentDay <= 23){
                        FromDate = moment().format("YYYYMM") + "01"
                        ToDate = moment().format("YYYYMM") + "16"
                    }else{
                        FromDate = null
                        ToDate = null
                    }
                } else if(userGeneratingPeriods === 4){
                    if(currentDay >= 1 && currentDay <= 7){
                        FromDate = moment().subtract(1, 'months').format("YYYYMM") + "24"
                        let lastDayOfPreviousMonth = moment().subtract(1, 'months').endOf('month').format("DD")
                        ToDate = moment().subtract(1, 'months').format("YYYYMM") + `${lastDayOfPreviousMonth}`
                    } else if(currentDay >= 8 && currentDay <= 16){
                        FromDate = moment().format("YYYYMM") + "01"
                        ToDate = moment().format("YYYYMM") + "07"
                    } else if(currentDay >= 17 && currentDay <= 23){
                        FromDate = moment().format("YYYYMM") + "08"
                        ToDate = moment().format("YYYYMM") + "16"
                    } else if(currentDay >= 24 && currentDay <= 31){
                        FromDate = moment().format("YYYYMM") + "17"
                        ToDate = moment().format("YYYYMM") + "23"
                    }else{
                        FromDate = null
                        ToDate = null
                    }
                }
                if(FromDate !== null && ToDate !== null){
                    period = moment(FromDate, 'YYYYMMDD').format("DD-MM-YYYY") + ' - ' +  moment(ToDate, 'YYYYMMDD').format("DD-MM-YYYY")
                    deliveryDate = moment(ToDate, 'YYYYMMDD').format("DD.MM.YYYY")
                }else{
                    period = null
                    deliveryDate = null
                }

                currentDay = 23
                if(userGeneratingPeriods === 1){
                    if(currentDay >= 1 && currentDay <= 7){
                        FromDate = moment().subtract(1, 'months').format("YYYYMM") + "01"
                        let lastDayOfPreviousMonth = moment().subtract(1, 'months').endOf('month').format("DD")
                        ToDate = moment().subtract(1, 'months').format("YYYYMM") + `${lastDayOfPreviousMonth}`
                    }else{
                        FromDate = null
                        ToDate = null
                    }
                } else if(userGeneratingPeriods === 2){
                    if(currentDay >= 1 && currentDay <= 7){
                        FromDate = moment().subtract(1, 'months').format("YYYYMM") + "17"
                        let lastDayOfPreviousMonth = moment().subtract(1, 'months').endOf('month').format("DD")
                        ToDate = moment().subtract(1, 'months').format("YYYYMM") + `${lastDayOfPreviousMonth}`
                    } else if(currentDay >= 17 && currentDay <= 23){
                        FromDate = moment().format("YYYYMM") + "01"
                        ToDate = moment().format("YYYYMM") + "16"
                    }else{
                        FromDate = null
                        ToDate = null
                    }
                } else if(userGeneratingPeriods === 4){
                    if(currentDay >= 1 && currentDay <= 7){
                        FromDate = moment().subtract(1, 'months').format("YYYYMM") + "24"
                        let lastDayOfPreviousMonth = moment().subtract(1, 'months').endOf('month').format("DD")
                        ToDate = moment().subtract(1, 'months').format("YYYYMM") + `${lastDayOfPreviousMonth}`
                    } else if(currentDay >= 8 && currentDay <= 16){
                        FromDate = moment().format("YYYYMM") + "01"
                        ToDate = moment().format("YYYYMM") + "07"
                    } else if(currentDay >= 17 && currentDay <= 23){
                        FromDate = moment().format("YYYYMM") + "08"
                        ToDate = moment().format("YYYYMM") + "16"
                    } else if(currentDay >= 24 && currentDay <= 31){
                        FromDate = moment().format("YYYYMM") + "17"
                        ToDate = moment().format("YYYYMM") + "23"
                    }else{
                        FromDate = null
                        ToDate = null
                    }
                }
                if(FromDate !== null && ToDate !== null){
                    period = moment(FromDate, 'YYYYMMDD').format("DD-MM-YYYY") + ' - ' +  moment(ToDate, 'YYYYMMDD').format("DD-MM-YYYY")
                    deliveryDate = moment(ToDate, 'YYYYMMDD').format("DD.MM.YYYY")
                }else{
                    period = null
                    deliveryDate = null
                }

                currentDay = 31
                if(userGeneratingPeriods === 1){
                    if(currentDay >= 1 && currentDay <= 7){
                        FromDate = moment().subtract(1, 'months').format("YYYYMM") + "01"
                        let lastDayOfPreviousMonth = moment().subtract(1, 'months').endOf('month').format("DD")
                        ToDate = moment().subtract(1, 'months').format("YYYYMM") + `${lastDayOfPreviousMonth}`
                    }else{
                        FromDate = null
                        ToDate = null
                    }
                } else if(userGeneratingPeriods === 2){
                    if(currentDay >= 1 && currentDay <= 7){
                        FromDate = moment().subtract(1, 'months').format("YYYYMM") + "17"
                        let lastDayOfPreviousMonth = moment().subtract(1, 'months').endOf('month').format("DD")
                        ToDate = moment().subtract(1, 'months').format("YYYYMM") + `${lastDayOfPreviousMonth}`
                    } else if(currentDay >= 17 && currentDay <= 23){
                        FromDate = moment().format("YYYYMM") + "01"
                        ToDate = moment().format("YYYYMM") + "16"
                    }else{
                        FromDate = null
                        ToDate = null
                    }
                } else if(userGeneratingPeriods === 4){
                    if(currentDay >= 1 && currentDay <= 7){
                        FromDate = moment().subtract(1, 'months').format("YYYYMM") + "24"
                        let lastDayOfPreviousMonth = moment().subtract(1, 'months').endOf('month').format("DD")
                        ToDate = moment().subtract(1, 'months').format("YYYYMM") + `${lastDayOfPreviousMonth}`
                    } else if(currentDay >= 8 && currentDay <= 16){
                        FromDate = moment().format("YYYYMM") + "01"
                        ToDate = moment().format("YYYYMM") + "07"
                    } else if(currentDay >= 17 && currentDay <= 23){
                        FromDate = moment().format("YYYYMM") + "08"
                        ToDate = moment().format("YYYYMM") + "16"
                    } else if(currentDay >= 24 && currentDay <= 31){
                        FromDate = moment().format("YYYYMM") + "17"
                        ToDate = moment().format("YYYYMM") + "23"
                    }else{
                        FromDate = null
                        ToDate = null
                    }
                }
                if(FromDate !== null && ToDate !== null){
                    period = moment(FromDate, 'YYYYMMDD').format("DD-MM-YYYY") + ' - ' +  moment(ToDate, 'YYYYMMDD').format("DD-MM-YYYY")
                    deliveryDate = moment(ToDate, 'YYYYMMDD').format("DD.MM.YYYY")
                }else{
                    period = null
                    deliveryDate = null
                }
            },
            async userLoginUrl(uid, user) {
                try {
                    user.automaticLoading = true
                    const res = await axios.post(`${this.$store.state.apiLink}/generateLoginToken`, {
                        uid: uid
                    })
                    if(res.data.success) {
                        window.open(`https://panel.lauron.eu/?value=${encodeURIComponent(res.data.token)}`, '_blank')
                        // window.open(`http://localhost:8081/?value=${encodeURIComponent(res.data.token)}`, '_blank')
                    } else {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Pojawił się błąd przy logowaniu użytkownika",
                            subheader: `Skontaktuj się z technikiem`,
                            success: false
                        }); 
                    }
                    user.automaticLoading = false
                } catch(error) {
                    user.automaticLoading = false
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Pojawił się błąd przy logowaniu użytkownika",
                        subheader: `Skontaktuj się z technikiem`,
                        success: false
                    }); 
                }
            },
            async singleInvoice(user){
                if(this.invoicesGenerating === false){
                    this.invoicesGenerating = true

                    let usersData = []
                    usersData.push(user)
                    let groupsOfUsers = []
                    const size = 2; 
                    for (let i=0; i<usersData.length; i+=size) {
                        groupsOfUsers.push(usersData.slice(i,i+size));
                    }
                    for (let y = 0; y < groupsOfUsers.length; y++) {
                        const group = groupsOfUsers[y];
                        const res = await axios.post(`${this.$store.state.apiLink}/generateInvoices`, {
                            users: group
                        })
                        if(res.data.success)
                        {
                            alert('Wygenerowalem fakture')
                        }else{
                            alert('Nie udało się wygenerować części faktur - sprawdź konsole')
                            console.log(group)
                        }
                    }

                    this.invoicesGenerating = false
                }
            },
            reloadUserInModal(userId){
                for (let i = 0; i < this.userList.length; i++) {
                    let crntUser = this.userList[i]
                    if(crntUser.id === userId){
                        this.pickedUsr = crntUser
                    }
                }
            },
            changeDateFormat(date){
                return moment.unix(date).format('DD.MM.YYYY')
            },
            copyToClipboard(id)
            {
                navigator.clipboard.writeText(id).then(()=>
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Skopiowano do schowka!",
                        subheader: ``,
                        success: true
                    }); 
                })

            },
            log(log){
                console.log(log)
            },
            async copyUsers(){
                let users = await db.collection("UsersData").get()
                let usersDocs = users.docs
                let usersToAdd = []
                for (let i = 0; i < usersDocs.length; i++) {
                    const usr = usersDocs[i].data();
                    usersToAdd.push(usr)
                }
                for (let x = 0; x < usersToAdd.length; x++) {
                    const usr = usersToAdd[x];
                    await db.collection("UsersDataBackup12day02monthx12h58m").doc(usr.id).set(usr)
                }
            },
            async allTestInvoice(){
                for (let i = 0; i < this.userList.length; i++) {
                    const user = this.userList[i];
                    await db.collection("UsersData").doc(user.id).update({
                        "activeInvoices": false,
                    })
                }
            },
            async allRealInvoice(){
                for (let i = 0; i < this.userList.length; i++) {
                    const user = this.userList[i];
                    if(user.id !== 'i2OBGIeP3VQRVZCjUrWN7OcuA2n2' && user.id !== 'EUTbF1TPTLWn5pdUhB7q6PndDBL2' && user.id !== '46G2zmxi4mSJrlD9XCgM8hsMdBg1'){
                        await db.collection("UsersData").doc(user.id).update({
                            "activeInvoices": true,
                        })
                    }
                }
            },
            async downloadUsers(){

                let query = ""
                if(this.clientList){
                    query = db.collection("UsersData")
                    if(this.sortTable.pickedColumn === null){
                        query = query.orderBy("meta.created.unix", this.sortTable.type)
                    }
                    if(this.sortTable.pickedColumn === 'name'){
                        query = query.orderBy("name", this.sortTable.type)
                    }
                    if(this.sortTable.pickedColumn === 'businessName'){
                        query = query.orderBy("company.name", this.sortTable.type)
                    }
                    if(this.sortTable.pickedColumn === 'cardGroup'){
                        query = query.orderBy("groupOfCards", this.sortTable.type)
                    }
                    if(this.sortTable.pickedColumn === 'dateOfAgreement'){
                        query = query.orderBy("agreement.pickedDateOfAgreementUnix", this.sortTable.type)
                    }
                    query = query.limit(this.queryLimit)
                }
                if(!this.clientList){
                    query = db.collection("WorkersData").orderBy("meta.created.unix",'desc').limit(this.queryLimit)
                }
                this.dbListener = query
                .onSnapshot((querySnapshot) => {
                    this.queryLoading = true;
                    this.userList = [];
                    if(querySnapshot.docs.length<this.queryLimit)
                    {
                        this.queryLimitReached = true;
                    }
                    else
                    {
                        this.queryLimitReached = false;
                    }
                    querySnapshot.forEach((doc) => {
                        this.userList.push(doc.data())
                        this.lastLoaded = doc.data().id;
                    });
                    this.queryLoading = false;
                });
            },
            changeList(type){
                if(type === 'clients'){
                    this.clientList = true
                    this.dbListener()
                    this.downloadUsers()
                }
                if(type === 'workers'){
                    this.clientList = false
                    this.dbListener()
                    this.downloadUsers()
                }
            },
            createPermissionsList(usr)
            {
                const permissions = Object.entries(usr.permissions);
                let translatedListOfPermissions = [];
                permissions.forEach(element => {
                    if(element[1] === true)
                    {
                        translatedListOfPermissions.push(element[0].toUpperCase());
                    }
                })

                return translatedListOfPermissions
            },
            closeClientModal()
            {
                this.showManageModal = false;

                // Allow transition to end
                setTimeout(()=>
                {
                    this.pickedUsr = null
                },300)
            },
            loadMoreUsers(amount)
            {
                this.dbListener();
                this.continueListening(amount);  
            },
            continueListening(amount)
            {
                this.queryLimit = this.queryLimit + amount;
                let query = ""
                if(this.clientList){
                    query = db.collection("UsersData")
                    if(this.sortTable.pickedColumn === null){
                        query = query.orderBy("meta.created.unix", this.sortTable.type)
                    }
                    if(this.sortTable.pickedColumn === 'name'){
                        query = query.orderBy("name", this.sortTable.type)
                    }
                    if(this.sortTable.pickedColumn === 'businessName'){
                        query = query.orderBy("company.name", this.sortTable.type)
                    }
                    if(this.sortTable.pickedColumn === 'cardGroup'){
                        query = query.orderBy("groupOfCards", this.sortTable.type)
                    }
                    query = query.limit(this.queryLimit)
                }
                if(!this.clientList){
                    query = db.collection("WorkersData").orderBy("meta.created.unix",'desc').limit(this.queryLimit)
                }
                this.dbListener = query
                .onSnapshot((querySnapshot) => {
                    this.queryLoading = true;
                    this.userList = [];
                    if(querySnapshot.docs.length<this.queryLimit)
                    {
                        this.queryLimitReached = true;
                    }
                    else
                    {
                        this.queryLimitReached = false;
                    }
                    querySnapshot.forEach((doc) => {
                        this.userList.push(doc.data());
                        this.lastLoaded = doc.data().id;
                    });
                    this.queryLoading = false;
                });
            }
        },
        beforeUnmount() {
            if(this.dbListener !== null)
            {
                this.dbListener();
            }  
        }
    }
</script>
<style lang="scss" scoped>
.spinner {
   width: 1.4rem;
   height: 1.4rem;
   border-radius: 50%;
   border: 5px solid #475569;
   animation: spinner-bulqg1 1.3s infinite linear alternate,
        spinner-oaa3wk 2.6s infinite linear;
}

@keyframes spinner-bulqg1 {
   0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
   }

   12.5% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
   }

   25% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
   }

   50% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   62.5% {
      clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   75% {
      clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
   }

   100% {
      clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
   }
}

@keyframes spinner-oaa3wk {
   0% {
      transform: scaleY(1) rotate(0deg);
   }

   49.99% {
      transform: scaleY(1) rotate(135deg);
   }

   50% {
      transform: scaleY(-1) rotate(0deg);
   }

   100% {
      transform: scaleY(-1) rotate(-135deg);
   }
}
</style>